import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { MAX_CANVAS_WIDTH } from '../signature-pad';

interface StyleConfig {
  hasSignatures: boolean;
}

export function getContainerStyle({ hasSignatures }: StyleConfig) {
  if (hasSignatures) {
    return css`
      padding: ${theme.spacing(0, 2, 1)};
    `;
  }

  return css`
    padding: ${theme.spacing(1, 2)};
  `;
}

export function getHeaderStyle({ hasSignatures }: StyleConfig) {
  if (hasSignatures) {
    return css`
      margin-top: ${theme.spacing(2)};
    `;
  }

  return null;
}

export const innerContainerStyle = css`
  max-width: ${MAX_CANVAS_WIDTH}px;
  margin: auto;
`;

export const canvasStyle = css`
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.colors.neutral20};
  cursor: url('/images/cursors/pen.svg') 0 20, auto;
`;
