import { css } from '@emotion/core';

interface InputFieldState {
  hasError: boolean;
  hasValue: boolean;
}

export function getInputFieldStyle({ hasError, hasValue }: InputFieldState) {
  if (!hasError && hasValue) {
    return css`
      border-color: seagreen !important;
      border-left-width: 5px !important;
    `;
  }

  return null;
}
