import { Form } from '@/types';
import {
  SearchField,
  Text,
  WeaveLogoIcon,
  useDebouncedValue,
  useFormField,
} from '@weave/design-system';
import {
  listSearchStyle,
  orderItemStyle,
  orderListStyle,
} from './kiosk-listing-page-content.styles';
import { FC } from 'react';

interface KioskFormsProps {
  forms: Form[];
  onSelect: (id: string) => void;
}

export const KioskForms: FC<KioskFormsProps> = ({ forms, onSelect }) => {
  const searchFieldProps = useFormField({
    type: 'text',
  });

  const searchValue = useDebouncedValue(searchFieldProps.value);
  return (
    <section>
      <SearchField
        css={listSearchStyle}
        {...searchFieldProps}
        name="search"
        autoComplete={'off'}
      />
      <ul css={orderListStyle}>
        {forms.map((item) => {
          if (!item.name.toLowerCase().includes(searchValue.toLowerCase())) return null;
          return (
            <li css={orderItemStyle} key={`${item.id}`} onClick={() => onSelect(item.id)}>
              {item.formPmsId && <WeaveLogoIcon className="weave-logo" />}
              <Text as="span" weight="bold">
                {item.name}
              </Text>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
