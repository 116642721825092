interface BrandInfo {
  label: string;
  icon?: string;
}

export enum CreditCardBrand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  JCB = 'JCB',
  MasterCard = 'MASTERCARD',
  UnionPay = 'UNIONPAY',
  Visa = 'VISA',
  CardBrandUnknown = 'CARD_BRAND_UNKNOWN',
}

export const cardBrandMap: Record<string, BrandInfo> = {
  VISA: { label: 'Visa', icon: '/images/payments/visa.png' },
  DISCOVER: { label: 'Discover', icon: '/images/payments/discover.png' },
  AMEX: { label: 'American Express', icon: '/images/payments/amex.png' },
  MASTERCARD: { label: 'Mastercard', icon: '/images/payments/mastercard.png' },
  DINERS: { label: 'Diners Club' },
  JCB: { label: 'JCB' },
  UNIONPAY: { label: 'UnionPay' },
  CARD_BRAND_UNKNOWN: { label: '' },
  AFFIRM: { label: 'Affirm', icon: '/images/payments/affirm.svg' },
  LINK: { label: 'Link', icon: '/images/payments/link.svg' },
  ACH: { label: 'ACH', icon: '/images/payments/ach.svg' },
};

export function getCardBrand(cardBrand: string): CreditCardBrand {
  switch (cardBrand) {
    case 'visa':
      return CreditCardBrand.Visa;
    case 'discover':
      return CreditCardBrand.Discover;
    case 'amex':
      return CreditCardBrand.Amex;
    case 'mastercard':
      return CreditCardBrand.MasterCard;
    default:
      return CreditCardBrand.Visa;
  }
}

export function getCardBrandIcon(brand: string) {
  const key = getCardBrand(brand.toLowerCase());
  return cardBrandMap[key]?.icon;
}

export function getCardBrandLabel(brand: string) {
  const key = getCardBrand(brand.toLowerCase());
  return cardBrandMap[key]?.label;
}
