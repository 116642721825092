import axios from 'axios';
import { environmentVariables } from '@forms-exp/env';

interface LocationData {
  name: string;
  slug: string;
  type: string;
  locationID: string;
  parent_id: string | null;
  phone_tenant_id: string | null;
  active: boolean;
}

interface FetchLocationDetailsResponse {
  data: LocationData[];
}

/**
 * Fetch the location details for the given location ID.
 */
export async function fetchLocationDetails(locationId: string) {
  try {
    const apiEndpoint = `${environmentVariables.baseApiUrl}/portal/locations/${locationId}`;
    const { data } = await axios.get<FetchLocationDetailsResponse>(apiEndpoint);
    if (data.data?.length > 0) {
      return data.data[0];
    }

    return null;
  } catch (e) {
    console.log('Error fetching the location name', e);
    return null;
  }
}
