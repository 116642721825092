import { FC, useEffect, useRef, useState } from 'react';
import { PDFDocumentProxy, RenderTask } from 'pdfjs-dist';
import { format } from 'date-fns';

import { drawPaths, getSignaturePathsForPDF, SIGNATURE_PADDING } from '@forms-exp/utils';

import { usePDFEditorContext } from '../context';

import { getContainerStyle } from './pdf-canvas.styles';

interface PDFCanvasProps {
  pdf: PDFDocumentProxy;
  pageNumber: number;
  desiredWidth: number;
  saveMode?: boolean;
}

const A4_WIDTH = 2480;

const PDFCanvas: FC<PDFCanvasProps> = ({ pdf, pageNumber, desiredWidth, saveMode }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasContextRef = useRef<CanvasRenderingContext2D | null | undefined>();
  const {
    signatures,
    updateCurrentPage,
    saveCanvasForPDF,
    pdfScaleLevel,
    updatePdfDimensions,
    pdfDimensions,
    totalPages,
  } = usePDFEditorContext();
  const renderTaskRef = useRef<RenderTask | null>(null);

  // For creating the live canvas for signing.
  useEffect(() => {
    if (saveMode) {
      return;
    }

    // const width = saveMode ? A4_WIDTH : desiredWidth;
    const width = A4_WIDTH * 0.25;

    pdf.getPage(pageNumber).then((page) => {
      const viewport = page.getViewport({ scale: pdfScaleLevel });
      const scale = width / viewport.width;
      const scaledViewport = page.getViewport({ scale: scale });

      // Prepare canvas using PDF page dimensions
      const canvas = canvasRef.current;

      if (!canvas) {
        return;
      }

      canvasContextRef.current = canvas.getContext('2d');
      const canvasContext = canvasContextRef.current;

      if (!canvasContext) {
        return;
      }

      canvas.height = scaledViewport.height;
      canvas.width = scaledViewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: canvasContext,
        viewport: scaledViewport,
      };

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }

      const renderTask = page.render(renderContext);
      renderTaskRef.current = renderTask;

      renderTask.promise.then(() => {
        setIsLoaded(true);
        updatePdfDimensions({
          width: scaledViewport.width,
          height: scaledViewport.height,
        });
        renderTaskRef.current = null;
      });
    });
  }, [pdf, pageNumber, desiredWidth, saveMode, pdfScaleLevel, updatePdfDimensions]);

  // For creating the signed canvas for PDF
  useEffect(() => {
    if (!saveMode) {
      return;
    }

    const width = A4_WIDTH;

    pdf.getPage(pageNumber).then((page) => {
      const viewport = page.getViewport({ scale: pdfScaleLevel });
      const scale = width / viewport.width;
      const scaledViewport = page.getViewport({ scale: scale });

      // Prepare canvas using PDF page dimensions
      const canvas = canvasRef.current;

      if (!canvas) {
        return;
      }

      canvasContextRef.current = canvas.getContext('2d');
      const canvasContext = canvasContextRef.current;

      if (!canvasContext) {
        return;
      }

      const printPageWidth = scaledViewport.width;
      const printPageHeight = scaledViewport.height;

      canvas.height = printPageHeight;
      canvas.width = printPageWidth;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: canvasContext,
        viewport: scaledViewport,
      };

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }

      const renderTask = page.render(renderContext);
      renderTaskRef.current = renderTask;

      renderTask.promise.then(() => {
        setIsLoaded(true);
        const date = format(new Date(), 'MM/dd/yyyy kk:mm:ss');

        const pdfCanvasPageWidth = pdfDimensions.width;
        const pdfCanvasPageHeight = pdfDimensions.height / totalPages;

        const pageWidthRatio = printPageWidth / pdfCanvasPageWidth;
        const pageHeightRatio = printPageHeight / pdfCanvasPageHeight;

        for (const signature of signatures) {
          const currentPdfPageHeightRange = {
            min: (pageNumber - 1) * pdfCanvasPageHeight,
            max: pageNumber * pdfCanvasPageHeight,
          };

          const { scaledPaths, position, signedBy } = signature;

          if (
            position.y < currentPdfPageHeightRange.min ||
            position.y > currentPdfPageHeightRange.max
          ) {
            continue;
          }

          const topOffset = (pageNumber - 1) * printPageHeight;

          const left = position.x * pageWidthRatio;
          const top = position.y * pageHeightRatio - topOffset;

          const { paths: signaturePaths, dimensions: signatureDimensions } =
            getSignaturePathsForPDF({
              paths: scaledPaths,
              position: {
                x: left,
                y: top,
              },
              scale: pageWidthRatio,
            });

          drawPaths(canvasContext, signaturePaths);
          const fontSize = 28;
          const signedByPositionY = top + fontSize + signatureDimensions.height;
          const datePositionY = signedByPositionY + 1.5 * fontSize;

          canvasContext.font = `${fontSize}px DM Sans`;
          const textLeft = left + 2 * SIGNATURE_PADDING;

          canvasContext.fillText(
            `Digitally signed by ${signedBy}`,
            textLeft,
            signedByPositionY
          );
          canvasContext.fillText(`Date ${date}`, textLeft, datePositionY);
        }

        const canvasData = canvas.toDataURL('image/jpeg');
        saveCanvasForPDF(pageNumber, canvasData);
        renderTaskRef.current = null;
      });
    });
  }, [
    pdf,
    pageNumber,
    desiredWidth,
    saveMode,
    signatures,
    saveCanvasForPDF,
    pdfScaleLevel,
    pdfDimensions.height,
    pdfDimensions.width,
    totalPages,
  ]);

  useEffect(() => {
    if (saveMode) {
      return;
    }

    const container = containerRef.current;

    if (!container || !isLoaded) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries, _) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            updateCurrentPage(pageNumber);
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, [pageNumber, isLoaded, updateCurrentPage, saveMode]);

  return (
    <div css={getContainerStyle({ hidden: saveMode })} ref={containerRef}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default PDFCanvas;
