import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import axios from 'axios';

import { LegalRecord, EnvironmentVariableProps } from '@forms-exp/types';
import { environmentVariables, getEnvProps } from '@forms-exp/env';

export interface LegalDataProps extends EnvironmentVariableProps {
  records: LegalRecord[];
}

export async function setLegalDataProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<LegalDataProps>> {
  const {
    query: { companyId },
  } = context;

  const response = await getLegalData(companyId as string);

  return {
    props: {
      records: response.records,
      ...getEnvProps(),
    },
  };
}

interface LegalDataResponse {
  success: boolean;
  records: LegalRecord[];
}

async function getLegalData(companyId: string) {
  try {
    const endpoint: string = `${environmentVariables.baseApiUrl}/forms-digital/v1/legal?company_id=${companyId}`;
    const { data } = await axios.get<LegalDataResponse>(endpoint);

    return {
      records: data.records || [],
    };
  } catch (error) {
    return {
      records: [],
    };
  }
}
