import { FC } from 'react';

import {
  IconButton,
  Modal,
  Tabs,
  Text,
  useModalControl,
  XIcon,
  TextButton,
  WarningBadgeIcon,
} from '@weave/design-system';

import { useMobile } from '@forms-exp/hooks';
import { ElectronicSignatureValue } from '@forms-exp/types';

import WetSignature from './wet-signature.component';
import UploadSignature from './upload-signature.component';
import TypedSignature from './typed-signature.component';
import { DisplaySignature } from './display-signature.component';

import {
  eSignatureWrapperStyle,
  modalStyle,
  noteStyle,
  signatureModalHeaderStyle,
  tabBarStyle,
  updateSignatureButtonWrapperStyle,
} from './e-signature-field.style';

interface ESignatureFieldProps {
  id: string;
  required: boolean;
  value: ElectronicSignatureValue;
  reviewMode?: boolean;
  error: string;
}

const ESignatureField: FC<ESignatureFieldProps> = ({
  id,
  value,
  reviewMode = false,
  error,
}) => {
  const { isMobileScreen } = useMobile();

  const { modalProps: eSignaturesModalProps, triggerProps: triggerESignaturesModal } =
    useModalControl({ disableReturnFocus: true });

  const onDone = () => {
    eSignaturesModalProps.onClose();
  };

  if (reviewMode) {
    return <DisplaySignature value={value} reviewMode={reviewMode} />;
  }

  return (
    <>
      {!value.data ? (
        <>
          <div css={eSignatureWrapperStyle} {...triggerESignaturesModal}>
            <Text color="light">
              {isMobileScreen ? 'Tap' : 'Click'} inside this box to sign
            </Text>
          </div>
          {error && (
            <Text color="error" size="small">
              {error}
            </Text>
          )}
        </>
      ) : (
        <>
          <DisplaySignature value={value} />
          <div css={updateSignatureButtonWrapperStyle}>
            <TextButton {...triggerESignaturesModal}>Update Signature</TextButton>
          </div>
        </>
      )}
      <Modal
        {...eSignaturesModalProps}
        css={modalStyle}
        disableCloseOnOverlayClick={true}
      >
        <Modal.Header css={signatureModalHeaderStyle}>
          Electronically sign document
          <IconButton
            className="close-btn"
            label=""
            onClick={() => eSignaturesModalProps.onClose()}
          >
            <XIcon size={20} />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Text className="esign-note">
            Select one of the methods below to electronically sign this document
          </Text>
          <div css={noteStyle}>
            <WarningBadgeIcon color="warn" />
            <Text size="small" className="esign-clause">
              By clicking <b>eSign</b>, you confirm and agree that you have thoroughly
              reviewed the contents of the documents you are about to sign electronically.
              Any method you choose here to eSign is legally binding and you are
              ultimately responsible for its implications.
            </Text>
          </div>
          <Tabs initialTab={'draw'}>
            <Tabs.Bar css={tabBarStyle}>
              <Tabs.Tab id="draw" controls="draw-sign">
                Draw
              </Tabs.Tab>
              <Tabs.Tab id="upload" controls="upload-sign">
                Upload
              </Tabs.Tab>
              <Tabs.Tab id="type" controls="type-sign">
                Type
              </Tabs.Tab>
            </Tabs.Bar>
            {/* Wet Signature */}
            <Tabs.Panel id="draw-sign" controller="draw">
              <WetSignature id={id} onDone={onDone} />
            </Tabs.Panel>

            {/* Upload Signature Image */}
            <Tabs.Panel id="upload-sign" controller="upload">
              <UploadSignature id={id} onDone={onDone} />
            </Tabs.Panel>

            {/* Typed Signature */}
            <Tabs.Panel id="type-sign" controller="type">
              <TypedSignature id={id} onDone={onDone} />
            </Tabs.Panel>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ESignatureField;
