import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const eSignatureWrapperStyle = css`
  display: flex;
  cursor: pointer;
  height: 150px;
  background-color: ${theme.colors.neutral10};
  align-items: center;
  justify-content: center;
`;

export const signatureModalHeaderStyle = css`
  display: flex;
  align-items: center;
  padding-right: ${theme.spacing(1.5)} !important;
  margin-bottom: 0;

  .close-btn {
    margin-left: auto;
    padding: 0;
  }
`;

export const wetSignaturesButtonsStyle = css`
  display: flex;
  justify-content: flex-end;
`;

export const modalStyle = css`
  width: 80% !important;
  max-width: 600px !important;

  .esign-note {
    margin: ${theme.spacing(1.5, 0)};
  }

  .form-signature-canvas {
    border: 1px dashed ${theme.colors.neutral20};
    margin: ${theme.spacing(2, 0)};
  }
`;

export const fontStyles = (font: string) => css`
  font-family: '${font}', cursive;
`;

export const footerButtonContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: ${theme.spacing(1.5)};
`;

export const typeSignatureOutputWrapper = css`
  margin-top: ${theme.spacing(2)};

  .typed-signature {
    font-size: ${theme.fontSize(24)};
  }

  .font1 {
    font-family: 'Kaushan Script', cursive;
    input {
      font-family: 'Kaushan Script', cursive;
    }
  }
  .font2 {
    font-family: 'Nothing You Could Do', cursive !important;
    input {
      font-family: 'Nothing You Could Do', cursive;
    }
  }
  .font3 {
    font-family: 'Caveat', cursive;
    input {
      font-family: 'Caveat', cursive;
    }
  }
  .font4 {
    font-family: 'Fuzzy Bubbles', cursive;
    input {
      font-family: 'Fuzzy Bubbles', cursive;
    }
  }
`;

export const imageUploadWrapperStyle = css`
  height: 200px;
  width: 100%;
  border: 1px dashed ${theme.colors.neutral20};
  margin: ${theme.spacing(2, 0)};
  background-color: ${theme.colors.neutral10};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .add-signature-image {
    color: ${theme.colors.primary50};
  }

  .file-uploader {
    visibility: hidden;
  }
`;

export const clearSignaturesButtonWrapper = css`
  display: flex;
  justify-content: center;

  .clear-wet-sign {
    color: ${theme.colors.primary50};
  }
`;

export const updateSignatureButtonWrapperStyle = css`
  display: flex;
  justify-content: center;
`;

export const tabBarStyle = css`
  #draw,
  #upload,
  #type {
    &[aria-selected='true'] {
      color: ${theme.colors.primary50};
    }
  }

  ::after {
    background-color: ${theme.colors.primary50};
  }
`;

export const noteStyle = css`
  display: flex;

  .esign-clause {
    margin: ${theme.spacing(0, 1.25)};
  }
`;
