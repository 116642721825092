import useStore from '@/store/useStore';

import {
  DropdownField,
  LocationIcon,
  PostalCodeLocale,
  useControlledField,
} from '@weave/design-system';

import { countries } from '@forms-exp/constants';
import {
  countryDropdownContainerStyle,
  countryDropdownStyle,
} from './country-field.style';

const CountryField = () => {
  const { postalCodeLocale, updatePostalCodeLocale } = useStore();

  const countryProps = useControlledField({
    type: 'dropdown',
    value: postalCodeLocale,
    onChange: (newLocale: PostalCodeLocale) => {
      updatePostalCodeLocale(newLocale);
    },
  });

  return (
    <div css={countryDropdownContainerStyle}>
      <DropdownField
        label="Country"
        name="country"
        icon={LocationIcon}
        {...countryProps}
        css={countryDropdownStyle}
      >
        {countries.map((country) => (
          <DropdownField.Option
            key={country.value}
            value={country.value}
            searchValue={country.value}
          >
            {country.name}
          </DropdownField.Option>
        ))}
      </DropdownField>
    </div>
  );
};

export default CountryField;
