import { FC, useState } from 'react';

import { PrimaryButton, SecondaryButton } from '@weave/design-system';

import { SignaturePosition, SaveSignatureResults } from '@forms-exp/types';
import { scaleSignaturePath } from '@forms-exp/utils';

import { useSignaturePadContext } from './context';
import { usePDFEditorContext } from '../context';
import SignatureCanvas from './signature-canvas/signature-canvas.component';
import SignatureCollection from './signature-collection/signature-collection.component';

import {
  containerStyle,
  getFooterStyle,
  buttonsContainerStyle,
  editSignatureButtonStyle,
  clearDrawingButtonStyle,
  cancelButtonStyle,
} from './signature-pad.styles';

/**
 * Component to render the signature pad.
 */
const SignaturePad: FC = () => {
  const [showSignatureCanvas, setShowSignatureCanvas] = useState(false);
  const { paths, clearDrawing, saveDrawing, signedByTextFieldProps, resetSignedBy } =
    useSignaturePadContext();
  const {
    addSignature,
    hasDraggableSignature,
    hasSignature,
    convertToPDF,
    finishSignaturePlacement,
    scrollPosition,
    offsetMargin,
  } = usePDFEditorContext();

  function addSignatureHandler() {
    setShowSignatureCanvas(true);
  }

  function hasDrawing() {
    return paths.length > 0 && paths[0].length > 0;
  }

  function disableSave() {
    return !hasDrawing() || signedByTextFieldProps.value.trim() === '';
  }

  function insertSignature({ dimensions, trimmedPaths, signedBy }: SaveSignatureResults) {
    let scale = 1;
    let pathsToSave = trimmedPaths;
    let pathDimensions = dimensions;

    // Scale down the signature if it's too big
    if (2 * dimensions.width > window.innerWidth) {
      scale = window.innerWidth / (2 * dimensions.width);
      const scaleResult = scaleSignaturePath({
        paths: trimmedPaths,
        scale,
      });

      pathsToSave = scaleResult.paths;
      pathDimensions = scaleResult.dimensions;
    }

    const screenCenter = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    const position: SignaturePosition = {
      x: screenCenter.x + scrollPosition.x - offsetMargin - pathDimensions.width / 2,
      y: screenCenter.y + scrollPosition.y - pathDimensions.height / 2,
    };

    addSignature({
      dimensions,
      scaledDimensions: pathDimensions,
      pageNumber: 1,
      paths: trimmedPaths,
      scaledPaths: pathsToSave,
      position,
      scale,
      signedBy,
    });
    setShowSignatureCanvas(false);
  }

  function saveDrawingHandler() {
    const saveDrawingResults = saveDrawing();
    insertSignature(saveDrawingResults);
  }

  function editSignatureHandler() {
    setShowSignatureCanvas(true);
  }

  function cancelSignatureHandler() {
    setShowSignatureCanvas(false);
    resetSignedBy();
    clearDrawing();
  }

  function showButtons() {
    if (showSignatureCanvas) {
      return (
        <>
          <PrimaryButton
            size="tiny"
            disabled={disableSave()}
            onClick={saveDrawingHandler}
          >
            Save
          </PrimaryButton>

          <SecondaryButton
            size="tiny"
            css={clearDrawingButtonStyle}
            disabled={!hasDrawing()}
            onClick={clearDrawing}
          >
            Clear drawing
          </SecondaryButton>

          <SecondaryButton
            size="tiny"
            css={cancelButtonStyle}
            onClick={cancelSignatureHandler}
          >
            Cancel
          </SecondaryButton>
        </>
      );
    }

    if (hasDraggableSignature()) {
      return (
        <>
          <PrimaryButton size="tiny" onClick={finishSignaturePlacement}>
            Finish & Sign
          </PrimaryButton>

          <SecondaryButton
            size="tiny"
            css={editSignatureButtonStyle}
            onClick={editSignatureHandler}
          >
            <img src="/images/cursors/pen.svg" /> Edit Signature
          </SecondaryButton>
        </>
      );
    }

    if (hasSignature()) {
      return (
        <>
          <PrimaryButton size="tiny" onClick={convertToPDF}>
            I&apos;m done
          </PrimaryButton>

          <SecondaryButton size="tiny" onClick={addSignatureHandler}>
            Add Signature
          </SecondaryButton>
        </>
      );
    }

    return (
      <PrimaryButton size="tiny" onClick={addSignatureHandler}>
        Add Signature
      </PrimaryButton>
    );
  }

  return (
    <div css={containerStyle}>
      {showSignatureCanvas && (
        <>
          <SignatureCanvas />
          <SignatureCollection onSelectSignature={insertSignature} />
        </>
      )}

      <footer css={getFooterStyle({ isCanvasShown: showSignatureCanvas })}>
        <div css={buttonsContainerStyle}>{showButtons()}</div>
      </footer>
    </div>
  );
};

export default SignaturePad;
