import { FC, useState } from 'react';
import { useElements, PaymentElement } from '@stripe/react-stripe-js';
import {
  Modal,
  useAlert,
  useFormField,
  TextField,
  ContentLoader,
} from '@weave/design-system';
import useStore from '@forms-exp/store';
import { PaymentsApi } from '@forms-exp/api';
import DummyPaymentCard from './dummy-card/dummy-card.component';
import { textFieldStyle } from './payment-card.style';

export const STRIPE_RESPONSE_DELAY = 2000;

interface PaymentCardProps {
  fieldId: string;
  onCardSaved: () => void;
  onCancel: () => void;
}

const PaymentCard: FC<PaymentCardProps> = ({ fieldId, onCardSaved, onCancel }) => {
  const nameProps = useFormField({ type: 'text', required: true });
  const [isSaving, setIsSaving] = useState(false);
  const elements = useElements();
  const {
    stripe,
    paymentSetupLinks,
    submissionToken,
    locationId,
    person,
    updateStripeCardDetails,
    previewMode,
  } = useStore();
  const alert = useAlert();

  async function saveCardOnFile() {
    if (previewMode) {
      onCardSaved();
      return;
    }

    if (nameProps.value.trim() === '') {
      alert.error('Cardholder name is required!');
      return;
    }

    if (!person || !person.id) {
      alert.error('Person not found!');
      return;
    }

    if (!stripe) {
      alert.error('Stripe not initialized!');
      return;
    }

    const paymentSetupLink = paymentSetupLinks[fieldId];
    if (!paymentSetupLink) {
      alert.error('Payment setup link not found!');
      return;
    }

    if (!elements) {
      alert.error('Stripe Elements not initialized!');
      return;
    }

    setIsSaving(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      alert.error(
        submitError.message ||
          'Error submitting details to Stripe. Please review information and try again.'
      );
      setIsSaving(false);
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      clientSecret: paymentSetupLink,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}/form?company_id=${locationId}&qwe=${submissionToken}`,
        payment_method_data: {
          billing_details: {
            name: nameProps.value,
          },
        },
      },
    });

    if (result.error) {
      switch (result.error.code) {
        case 'incorrect_number':
          alert.error(
            'The card number appears to be incorrect. Please check and try again.'
          );
          break;

        case 'expired_card':
          alert.error(
            'The card has expired. Please check the expiration date or use a different card.'
          );
          break;

        case 'incorrect_cvc':
          alert.error(
            "The card's security code is incorrect. Please check the card's security code or use a different card."
          );
          break;

        case 'incorrect_zip':
          alert.error(
            "The card's postal code is incorrect. Please check the card's postal code or use a different card."
          );
          break;

        case 'invalid_cvc':
          alert.error(
            "The card's security code is invalid. Please check the card's security code or use a different card."
          );
          break;

        case 'invalid_expiry_month':
          alert.error(
            "The card's expiration month is invalid. Please check the card's expiration month or use a different card."
          );
          break;

        case 'invalid_expiry_year':
          alert.error(
            "The card's expiration year is invalid. Please check the card's expiration year or use a different card."
          );
          break;

        default:
          alert.error(`Unable to save Card. Please try again. ${result.error.code}`);
          break;
      }

      setIsSaving(false);
      return;
    }

    const paymentMethodId = result.setupIntent.payment_method as string;
    setTimeout(async () => {
      const paymentMethods = await PaymentsApi.getPaymentMethods({
        locationId,
        personId: person.id || '',
        submissionId: submissionToken,
        paymentMethodIds: [paymentMethodId],
      });
      const paymentMethod = paymentMethods.find(
        (method) => method.paymentMethodId === paymentMethodId
      );

      if (!paymentMethod) {
        alert.error('Payment method not found!');
        setIsSaving(false);
        return;
      }

      const country = paymentMethod.cardInfo?.country || '';
      const expiryYear = paymentMethod.cardInfo?.expYear?.toString() || '';
      const expiryMonth = paymentMethod.cardInfo?.expMonth?.toString() || '';
      const lastFour = paymentMethod.cardInfo?.lastFour || '';
      const nameOnCard = nameProps.value;
      const brand = paymentMethod.cardInfo?.brand || '';

      if (!country || !expiryMonth || !expiryYear || !lastFour) {
        alert.error('Unable to get card details!');
        setIsSaving(false);
        return;
      }

      updateStripeCardDetails(fieldId, {
        country,
        expiryYear,
        expiryMonth,
        lastFour,
        nameOnCard,
        brand,
      });
      setIsSaving(false);
      onCardSaved();
    }, STRIPE_RESPONSE_DELAY);
  }

  return (
    <>
      <Modal.Body>
        {previewMode ? (
          <DummyPaymentCard />
        ) : (
          <>
            <div css={textFieldStyle}>
              <TextField
                name="cardholderName"
                label="Cardholder Name"
                hasNonFloatingLabel
                {...nameProps}
              />
            </div>
            <PaymentElement />
          </>
        )}
      </Modal.Body>
      <Modal.Actions
        primaryLabel="Save"
        onPrimaryClick={saveCardOnFile}
        disablePrimary={isSaving}
        secondaryLabel="Cancel"
        onSecondaryClick={onCancel}
        disableSecondary={isSaving}
      />
      <ContentLoader show={isSaving} message="Saving card..." />
    </>
  );
};

export default PaymentCard;
