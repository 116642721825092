import {
  SignaturePath,
  SignaturePaths,
  LineCoordinates,
  SignaturePosition,
} from '@forms-exp/types';

export const SIGNATURE_PADDING = 16;

function drawPath(ctx: CanvasRenderingContext2D, path: SignaturePath, color = 'black') {
  ctx.strokeStyle = color;
  ctx.lineWidth = 3;
  ctx.beginPath();
  ctx.moveTo(...path[0]);

  for (const point of path) {
    ctx.lineTo(...point);
  }

  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.stroke();
}

export function drawPaths(
  ctx: CanvasRenderingContext2D,
  paths: SignaturePaths,
  color = 'black'
) {
  for (const path of paths) {
    drawPath(ctx, path, color);
  }
}

export function getCopyOfPaths(paths: SignaturePaths): SignaturePaths {
  const copyOfPaths: SignaturePaths = [];

  for (const path of paths) {
    const copyOfPath: SignaturePath = [];

    for (const point of path) {
      const copyOfPoint: LineCoordinates = [...point];
      copyOfPath.push(copyOfPoint);
    }

    copyOfPaths.push(copyOfPath);
  }

  return copyOfPaths;
}

export function modifyPath(
  paths: SignaturePaths,
  modificationPoint: LineCoordinates
): SignaturePaths {
  const modifiedPaths = getCopyOfPaths(paths);

  for (const path of modifiedPaths) {
    for (const point of path) {
      point[0] += modificationPoint[0];
      point[1] += modificationPoint[1];
    }
  }

  return modifiedPaths;
}

export function getMouse(canvas: HTMLCanvasElement, event: MouseEvent): LineCoordinates {
  const rect = canvas.getBoundingClientRect();
  return [Math.round(event.clientX - rect.left), Math.round(event.clientY - rect.top)];
}

export function getTouch(canvas: HTMLCanvasElement, event: TouchEvent): LineCoordinates {
  const rect = canvas.getBoundingClientRect();
  const touch = event.touches[0];
  return [Math.round(touch.clientX - rect.left), Math.round(touch.clientY - rect.top)];
}

export function redraw(
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  paths: SignaturePaths
) {
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  drawPaths(ctx, paths);
}

export function trimSignature(paths: SignaturePaths) {
  let min = { x: 0, y: 0 };
  let max = { x: 0, y: 0 };
  let init = false;
  const copyOfPaths: SignaturePaths = [];

  for (const path of paths) {
    const copyOfPath: SignaturePath = [];

    for (const point of path) {
      if (!init) {
        min = { x: point[0], y: point[1] };
        init = true;
      }

      const [x, y] = point;

      if (x < min.x) {
        min.x = x;
      }

      if (y < min.y) {
        min.y = y;
      }

      if (x > max.x) {
        max.x = x;
      }

      if (y > max.y) {
        max.y = y;
      }

      const copyOfPoint: LineCoordinates = [...point];
      copyOfPath.push(copyOfPoint);
    }

    copyOfPaths.push(copyOfPath);
  }

  for (const path of copyOfPaths) {
    for (const point of path) {
      point[0] -= min.x - SIGNATURE_PADDING;
      point[1] -= min.y - SIGNATURE_PADDING;
    }
  }

  const width = max.x - min.x + SIGNATURE_PADDING * 2;
  const height = max.y - min.y + SIGNATURE_PADDING * 2;

  return {
    paths: copyOfPaths,
    dimensions: { width, height },
  };
}

interface GetSignaturePathsForPDFPayload {
  paths: SignaturePaths;
  position: SignaturePosition;
  scale: number;
}

export function getSignaturePathsForPDF({
  paths,
  position,
  scale,
}: GetSignaturePathsForPDFPayload) {
  const copyOfPaths = getCopyOfPaths(paths);
  let min = { x: 0, y: 0 };
  let max = { x: 0, y: 0 };
  let init = false;

  for (const path of copyOfPaths) {
    for (const point of path) {
      if (!init) {
        min = { x: point[0], y: point[1] };
        init = true;
      }

      point[0] = point[0] * scale;
      point[1] = point[1] * scale;

      const [x, y] = point;

      point[0] += position.x;
      point[1] += position.y;

      if (x < min.x) {
        min.x = x;
      }

      if (y < min.y) {
        min.y = y;
      }

      if (x > max.x) {
        max.x = x;
      }

      if (y > max.y) {
        max.y = y;
      }
    }
  }

  const width = max.x - min.x + SIGNATURE_PADDING * 2;
  const height = max.y - min.y + SIGNATURE_PADDING * 2;

  return {
    paths: copyOfPaths,
    dimensions: { width, height },
  };
}

interface ScaleSignaturePathPayload {
  paths: SignaturePaths;
  scale: number;
}

export function scaleSignaturePath({ paths, scale }: ScaleSignaturePathPayload) {
  const copyOfPaths = getCopyOfPaths(paths);
  let min = { x: 0, y: 0 };
  let max = { x: 0, y: 0 };
  let init = false;

  for (const path of copyOfPaths) {
    for (const point of path) {
      if (!init) {
        min = { x: point[0], y: point[1] };
        init = true;
      }

      point[0] *= scale;
      point[1] *= scale;

      const [x, y] = point;

      if (x < min.x) {
        min.x = x;
      }

      if (y < min.y) {
        min.y = y;
      }

      if (x > max.x) {
        max.x = x;
      }

      if (y > max.y) {
        max.y = y;
      }
    }
  }

  const width = max.x - min.x + SIGNATURE_PADDING * 2;
  const height = max.y - min.y + SIGNATURE_PADDING * 2;

  return {
    paths: copyOfPaths,
    dimensions: { width, height },
  };
}
