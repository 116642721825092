import { useModalControl, ModalControlResponse } from '@weave/design-system';
import PaymentCardModal from './payment-card-modal.component';

interface UsePaymentCardModalResults {
  PaymentCardModal: typeof PaymentCardModal;
  paymentCardModalControls: ModalControlResponse;
}

export const usePaymentCardModal = (): UsePaymentCardModalResults => {
  const modalControls = useModalControl();

  return {
    paymentCardModalControls: modalControls,
    PaymentCardModal,
  };
};
