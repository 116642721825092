import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { tabletScreenMaxWidth } from '@forms-exp/constants';

export const containerStyle = css`
  padding: ${theme.spacing(2, 0)};

  @media screen and (max-width: ${tabletScreenMaxWidth}) {
    padding: ${theme.spacing(1.5, 2)};
  }
`;

export const innerContainerStyle = css`
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: auto;

  @media screen and (max-width: ${tabletScreenMaxWidth}) {
    justify-content: initial;
  }
`;

export const primaryButtonStyle = css`
  @media screen and (max-width: ${tabletScreenMaxWidth}) {
    margin-left: auto;
  }
`;
