import { FC, ChangeEvent } from 'react';

import {
  getMaskConfigProps,
  FieldLayoutWithAction,
  digitsOnly,
} from '@weave/design-system';
import MaskedInput, { conformToMask } from '@weave/text-mask';

interface SocialSecurityNumberInputProps {
  [key: string]: any;
}

// Inspired by the design-system.
const SocialSecurityNumberInput: FC<SocialSecurityNumberInputProps> = ({
  onChange,
  value = '',
  ...rest
}) => {
  // always keep the value digits-only, keeping formatting only in the ui
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ name: rest.name, value: digitsOnly(event.target.value) });
  };

  // @ts-ignore
  const { maskConfigProps, nonConfigProps } = getMaskConfigProps(rest);
  const ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  return (
    <MaskedInput
      {...nonConfigProps}
      onChange={handleChange}
      type="text"
      pattern="[0-9]*"
      mask={ssnMask}
      value={
        value.trim()
          ? conformToMask(value, ssnMask, maskConfigProps).conformedValue
          : value
      }
    />
  );
};

const SocialSecurityNumberField = (props: any) => (
  // Inspired by the design-system.
  <FieldLayoutWithAction {...props} field={SocialSecurityNumberInput} />
);

export default SocialSecurityNumberField;
