export enum SubmissionMode {
  SUBMISSION_MODE_UNKNOWN, // Unknown mode
  SOLICITED_AUTOREMINDER, // Submissions received through auto reminders flow
  SOLICITED_DESKTOPCLIENT, // Submissions received through desktop client link generation flow
  SOLICITED_INCHAIRKIOSK, // Submissions received through in-chair Kiosk mode
  UNSOLICITED_PUBLICLINKS, // Submissions received through public links
  UNSOLICITED_PATIENTKIOSK, // Submissions received through Kiosk mode for patients
}

export enum SubmissionDocumentType {
  UNKNOWN,
  FORMS,
  PACKET,
}
