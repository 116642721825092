import { FC } from 'react';

import { TextLink, Text } from '@weave/design-system';

import { useMobile } from '@forms-exp/hooks';

import { footerStyle, poweredByStyle, linkGroupStyle } from './footer-links.styles';

interface FooterLinksProps {
  companyId: string;
}

export const FooterLinks: FC<FooterLinksProps> = ({ companyId }) => {
  const { isMobileScreen } = useMobile();

  const privacyPolicyLink = (
    <li>
      <TextLink target="_blank" href={`/legal/privacy-policy/${companyId}`}>
        Privacy Policy
      </TextLink>
    </li>
  );

  const termsAndConditionsLink = (
    <li>
      <TextLink target="_blank" href={`/legal/terms-conditions/${companyId}`}>
        Terms and Conditions
      </TextLink>
    </li>
  );

  const hipaaLink = (
    <li>
      <TextLink
        target="_blank"
        href="https://www.weavehelp.com/hc/en-us/articles/5017862597275"
      >
        HIPAA Compliance
      </TextLink>
    </li>
  );

  return (
    <div css={footerStyle}>
      <ul>
        {isMobileScreen ? (
          <>
            <div css={linkGroupStyle}>
              {privacyPolicyLink} {termsAndConditionsLink}
            </div>

            <div css={linkGroupStyle}>
              {hipaaLink}

              <li className="weave">
                <span>Powered by</span>{' '}
                <TextLink href="https://getweave.com" target="_blank">
                  Weave
                </TextLink>
              </li>
            </div>
          </>
        ) : (
          <>
            {privacyPolicyLink} {termsAndConditionsLink} {hipaaLink}
          </>
        )}
      </ul>

      {!isMobileScreen && (
        <Text css={poweredByStyle}>
          <span className="text">Powered by</span>{' '}
          <TextLink href="https://getweave.com" target="_blank">
            Weave
          </TextLink>
        </Text>
      )}
    </div>
  );
};
