import { useEffect, useState } from 'react';

import { useModalControl, ModalControlResponse } from '@weave/design-system';

import { getPDFObjectURL } from '@forms-exp/helpers';

import PDFViewer from './pdf-viewer/pdf-viewer.component';

declare global {
  interface Window {
    AdobeDC: any;
  }
}

interface UsePDFEditorProps {
  base64Data: string;
  onCloseModal?: () => void;
}

interface UsePDFEditorResults {
  PDFViewer: typeof PDFViewer;
  pdfViewerModalControls: ModalControlResponse;
  pdfUrl: string;
}

export const usePDFEditor = ({
  base64Data,
  onCloseModal,
}: UsePDFEditorProps): UsePDFEditorResults => {
  const [pdfUrl, setPdfUrl] = useState('');
  const modalControl = useModalControl({
    onClose: () => {
      if (onCloseModal) {
        onCloseModal();
      }
    },
  });

  useEffect(() => {
    const url = getPDFObjectURL(base64Data);
    setPdfUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [base64Data]);

  return {
    PDFViewer,
    pdfViewerModalControls: modalControl,
    pdfUrl,
  };
};
