import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const emptySectionStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  margin-top: ${theme.spacing(5)};

  .text {
    margin-top: ${theme.spacing(3)};
    text-align: center;
  }

  p {
    margin: 0;
  }
`;

export const kioskPageStyle = css`
  max-width: 600px;
  margin: 0px auto;
`;

export const tabBarStyle = css`
  width: fit-content !important;

  #packets,
  #forms {
    width: 150px;
    padding-left: ${theme.spacing(8)};
    padding-right: ${theme.spacing(8)};
  }

  @media (max-width: 420px) {
    width: 100%;

    #packets,
    #forms {
      width: 45vw;
      padding-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
    }
  }
`;

export const listSearchStyle = css`
  margin-top: ${theme.spacing(2)} !important;
  max-width: 300px;

  @media (max-width: 420px) {
    max-width: 100%;
  }
`;

export const orderListStyle = css`
  overflow-y: scroll;
  padding-left: 0;
  width: 100%;
  border-radius: ${theme.borderRadius.medium};
`;

export const orderItemStyle = css`
  display: flex;
  align-items: center;
  list-style: none;
  padding: ${theme.spacing(2, 2)};
  max-width: 600px;

  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.medium};

  &:hover {
    background-color: ${theme.colors.primary5};
    border: 2px solid ${theme.colors.primary50};
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: ${theme.spacing(2)};
  }

  .weave-logo {
    margin-right: ${theme.spacing(1)};
  }

  .forms-count {
    margin-left: auto;
    white-space: nowrap;
  }
`;
