import { DataKey, PersonalDataKey } from '@/types';

export interface FieldMaxLengthProps {
  fieldType: string;
  dataKey?: DataKey;
}

const MAX_TEXT_FIELD_LENGTH_64 = 64;
const MAX_TEXT_FIELD_LENGTH_128 = 128;
const MAX_TEXT_FIELD_LENGTH_256 = 256;
const MAX_TEXT_AREA_LENGTH = 1024;
const MAX_DEFAULT_LENGTH = 256;

const fieldsWithMax64Chars: PersonalDataKey[] = [
  'firstName',
  'lastName',
  'city',
  'email',
  'middleName',
];
const fieldsWithMax256Chars: PersonalDataKey[] = ['address1', 'address2'];

export function getFieldMaxLength(params: FieldMaxLengthProps): number {
  if (params.fieldType === 'text') {
    if (
      params.dataKey &&
      fieldsWithMax64Chars.includes(params.dataKey as PersonalDataKey)
    ) {
      return MAX_TEXT_FIELD_LENGTH_64;
    }
    if (
      params.dataKey &&
      fieldsWithMax256Chars.includes(params.dataKey as PersonalDataKey)
    ) {
      return MAX_TEXT_FIELD_LENGTH_256;
    }

    return MAX_TEXT_FIELD_LENGTH_128;
  }

  if (params.fieldType === 'textarea') {
    return MAX_TEXT_AREA_LENGTH;
  }

  return MAX_DEFAULT_LENGTH;
}
