import { css } from '@emotion/core';

export const containerStyle = css`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const canvasContainerStyle = css`
  overflow: auto;
  flex: 1;
  display: flex;
`;

interface CanvasStyleConfig {
  isTouchActive: boolean;
}

export function getCanvasContainerStyle({ isTouchActive }: CanvasStyleConfig) {
  if (isTouchActive) {
    return [
      canvasContainerStyle,
      css`
        overflow: hidden;
      `,
    ];
  }

  return canvasContainerStyle;
}

export const canvasInnerContainerStyle = css`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;
