import Compressor from 'compressorjs';
import * as Sentry from '@sentry/nextjs';
import { supportedFileTypes } from '@/constants';

// https://www.npmjs.com/package/compressorjs
export async function compressImage(image: File): Promise<File> {
  const promise = new Promise<File>((resolve) => {
    new Compressor(image, {
      // The quality of the output image. It must be a number between 0 and 1.
      // If this argument is anything else, the default values 0.92 and 0.80 are used for image/jpeg and image/webp respectively.
      // Other arguments are ignored. Be careful to use 1 as it may make the size of the output image become larger.
      quality: 0.4,

      // Files whose file type is included in the convertTypes list,
      // and whose file size exceeds this value will be converted to JPEGs.
      // To disable this, just set the value to Infinity. Default: 5000000 (5 MB)
      convertSize: 512 * 1024, // 512 KB
      convertTypes: supportedFileTypes,

      success(result) {
        const compressedImage = new File([result], image.name, {
          type: result.type,
        });
        resolve(compressedImage);
      },
      error(err) {
        Sentry.captureException(err);
        resolve(image);
      },
    });
  });

  return promise;
}
