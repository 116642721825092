import { useEffect } from 'react';
import Router from 'next/router';

import { useBeforeUnload } from 'react-use';

import useStore from '@forms-exp/store';

export const useLeavePageConfirm = (
  message = 'You have unsaved changes. Are you sure you want to leave this page?'
) => {
  const { hasUnsavedChanges } = useStore();

  useBeforeUnload(hasUnsavedChanges, message);

  useEffect(() => {
    const handler = () => {
      if (hasUnsavedChanges && !window.confirm(message)) {
        throw 'Route Canceled';
      }
    };

    Router.events.on('beforeHistoryChange', handler);

    return () => {
      Router.events.off('beforeHistoryChange', handler);
    };
  }, [hasUnsavedChanges, message]);
};
