import { FC } from 'react';

import { Text } from '@weave/design-system';

import useStore from '@forms-exp/store';
import { SaveSignatureResults } from '@forms-exp/types';

import SignaturePreview from './signature-preview/signature-preview.component';

import {
  containerStyle,
  signatureContainerStyle,
  textStyle,
} from './signature-collection.styles';

interface SignatureCollectionProps {
  onSelectSignature: (signatureResults: SaveSignatureResults) => void;
}

const SignatureCollection: FC<SignatureCollectionProps> = ({ onSelectSignature }) => {
  const { pdfSignatures, getPdfSignature } = useStore();

  function selectSignatureHandler(index: number) {
    const pdfSignature = getPdfSignature(index);

    if (!pdfSignature) {
      return;
    }

    onSelectSignature(pdfSignature);
  }

  if (pdfSignatures.length === 0) {
    return null;
  }

  return (
    <div css={containerStyle}>
      <Text size="small" textAlign="center" css={textStyle}>
        Select a previous e-signature to auto-fill
      </Text>

      <div css={signatureContainerStyle}>
        {pdfSignatures.map((pdfSignature, index) => (
          <SignaturePreview
            key={pdfSignature.id}
            signatureResults={pdfSignature}
            onSelectSignature={() => {
              selectSignatureHandler(index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SignatureCollection;
