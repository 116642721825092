import { FC, useRef } from 'react';
import { useRouter } from 'next/router';

import { Text, Heading, Tabs } from '@weave/design-system';

import { Packet, Form } from '@forms-exp/types';
import useStore from '@forms-exp/store';

import {
  emptySectionStyle,
  kioskPageStyle,
  tabBarStyle,
} from './kiosk-listing-page-content.styles';
import { KioskPackets } from './kiosk-packets.component';
import { KioskForms } from './kiosk-forms.component';
import Image from 'next/image';

export interface KioskListingProps {
  packets: Packet[];
  forms: Form[];
}

export const KioskListing: FC<KioskListingProps> = ({ forms = [], packets = [] }) => {
  const listingRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { clearPatronInfo } = useStore();

  function cardClickHandler(token: string, type: 'packetId' | 'formId') {
    const { patientType, companyId } = router.query;
    clearPatronInfo();

    if (patientType) {
      router.push(`/kiosk/${patientType}-patron/${companyId}?${type}=${token}`);
      return;
    }

    router.replace(
      `/form?${type}=${token}&companyId=${companyId}&kiosk=${companyId}&ni=yes`
    );
  }

  function showEmptySection(type: 'forms' | 'packets') {
    return (
      <div css={emptySectionStyle}>
        <Image
          src={`/images/no-${type}.png`}
          alt={`Empty ${type} section`}
          width="200"
          height="200"
        />

        <div className="text">
          <Text weight="bold">No {type === 'forms' ? 'Forms' : 'Packets'} listed</Text>
          <Text>There are no results at the time</Text>
        </div>
      </div>
    );
  }

  return (
    <section css={kioskPageStyle}>
      <Heading level={3}>Select Form to Fill</Heading>
      <Text color="light">
        Choose the forms below to begin entering information and submit
      </Text>
      <Tabs initialTab="forms">
        <Tabs.Bar css={tabBarStyle}>
          <Tabs.Tab id="packets" controls="packets-panel">
            Packets
          </Tabs.Tab>
          <Tabs.Tab id="forms" controls="forms-panel">
            Forms
          </Tabs.Tab>
        </Tabs.Bar>
        <div ref={listingRef}>
          <Tabs.Panel id="packets-panel" controller="packets">
            {packets.length === 0 ? (
              showEmptySection('packets')
            ) : (
              <KioskPackets
                packets={packets}
                onSelect={(id) => cardClickHandler(id, 'packetId')}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel id="forms-panel" controller="forms">
            {forms.length === 0 ? (
              showEmptySection('forms')
            ) : (
              <KioskForms
                forms={forms}
                onSelect={(id) => cardClickHandler(id, 'formId')}
              />
            )}
          </Tabs.Panel>
        </div>
      </Tabs>
    </section>
  );
};
