import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const wrapperStyle = css`
  padding: ${theme.spacing(1)};
  height: 100%;
`;

export const contentLoaderStyle = css`
  opacity: 1 !important;
`;

export const fillingModeStyleContainerStyles = css`
  overflow: auto;
  padding: ${theme.spacing(0, 2, 2)};
  max-width: 500px;
  width: 100%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};

  .top-note {
    margin-bottom: 0;
  }

  .mode-btn {
    width: 330px;
  }

  .bottom-note {
    width: 330px;
    display: flex;
    gap: ${theme.spacing(1)};
    p {
      margin: 0;
    }
  }
`;

export const resendCodeStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const otpScreenContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .resend-text {
    p {
      display: inline-block;
      margin-bottom: ${theme.spacing(1)};
    }

    .resend-link {
      margin-left: ${theme.spacing(0.5)};
    }
  }

  h3 {
    margin: 0;
  }

  .text-center {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .manual-fill-text {
    margin-bottom: 0;
    margin-top: ${theme.spacing(1)};
  }
`;

export const formRowStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const otpFieldStyle = (
  isDisabled: boolean = false,
  isInvalidOtp: boolean = false
) => css`
  width: 30px;
  border-width: 0 0 2px 0 !important;
  border-radius: 0 !important;
  border-color: ${theme.colors.primary50} !important;
  padding-left: ${theme.spacing(0)} !important;
  padding-right: ${theme.spacing(0)} !important;
  box-shadow: none !important;

  ${isInvalidOtp && `border-color: ${theme.colors.critical50} !important;`}

  ${isDisabled && `border-color: ${theme.colors.neutral20} !important;`}

  &:focus {
    border-width: 0 0 2px 0 !important;
    outline: none !important;
  }

  input {
    text-align: center !important;
    font-weight: ${theme.font.weight.bold};
  }
`;
