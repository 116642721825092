import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 430px;
  margin: 0 auto;
`;

export const imageStyle = css`
  width: 100%;
  max-width: 400px;
  padding: ${theme.spacing(0, 2)};
`;

export const textContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const titleStyle = css`
  line-height: 32px;
  color: ${theme.colors.text.subdued};
  text-align: center;
`;

export const descriptionStyle = css`
  font-weight: 400;
  font-size: ${theme.fontSize(20)};
  line-height: 24px;
  color: ${theme.colors.text.subdued};
  text-align: center;
  max-width: 310px;
  margin-top: 0;
`;
