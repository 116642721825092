import { GetState, SetState } from 'zustand';

import { State } from '../useStore';

export interface FormPacketSlice {
  currentFormIndex: number;
  goToNextForm: () => void;
  goToPreviousForm: () => void;
  clearFormPacket: () => void;
}

const createFormPacketSlice = (
  set: SetState<State>,
  get: GetState<State>
): FormPacketSlice => ({
  currentFormIndex: 0,
  goToNextForm: () => {
    set((state) => ({
      ...state,
      currentFormIndex: state.currentFormIndex + 1,
    }));
  },
  goToPreviousForm: () => {
    set((state) => {
      if (state.currentFormIndex > 0) {
        return {
          ...state,
          currentFormIndex: state.currentFormIndex - 1,
        };
      }

      return state;
    });
  },
  clearFormPacket: () => {
    set((state) => ({ ...state, currentFormIndex: 0 }));
  },
});

export default createFormPacketSlice;
