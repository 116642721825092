import { css } from '@emotion/core';
import {
  laptopScreenMinWidth,
  mobileScreenMaxWidth,
  tabletScreenMinWidth,
} from '@forms-exp/constants';
import { theme } from '@weave/theme';

export const legalContainerStyles = css`
  width: 40%;
  margin: 1rem auto;
  text-align: center !important;
  height: 100%;
  overflow: auto;
  padding-bottom: ${theme.spacing(6)};

  @media screen and (max-width: ${mobileScreenMaxWidth}) {
    width: 80%;
  }

  @media screen and (min-width: ${tabletScreenMinWidth}) {
    width: 80%;
  }

  @media screen and (min-width: ${laptopScreenMinWidth}) {
    width: 40%;
  }
`;

export const legalTextStyles = css`
  max-width: 100%;
`;
