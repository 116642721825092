import Head from 'next/head';

export const NoCompanySelected = () => (
  <>
    <Head>
      <title>No company selected!</title>
    </Head>
    <h2>No Company Selected!</h2>
  </>
);
