import {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  RefObject,
  MutableRefObject,
} from 'react';

import { useControlledField, BaseFieldProps } from '@weave/design-system';

import useStore from '@forms-exp/store';
import { SignaturePaths, SaveSignatureResults } from '@forms-exp/types';
import { trimSignature } from '@forms-exp/utils';

export interface UseSignaturePadResults {
  paths: SignaturePaths;
  setPaths: Dispatch<SetStateAction<SignaturePaths>>;
  clearDrawing: () => void;
  canvasRef: RefObject<HTMLCanvasElement>;
  canvasContextRef: MutableRefObject<CanvasRenderingContext2D | null | undefined>;
  getSignatureDimensions: () => { width: number; height: number };
  saveDrawing: () => SaveSignatureResults;
  signedByTextFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  resetSignedBy: () => void;
}

const useSignaturePad = (): UseSignaturePadResults => {
  const [paths, setPaths] = useState<SignaturePaths>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasContextRef = useRef<CanvasRenderingContext2D | null | undefined>();

  const [signedBy, setSignedBy] = useState('');
  const signedByTextFieldProps = useControlledField({
    type: 'text',
    value: signedBy,
    onChange: (newValue: string) => {
      setSignedBy(newValue);
    },
  });

  const { addToPdfSignatureCollection } = useStore();

  const clearDrawing = useCallback(() => {
    const canvas = canvasRef.current;
    const canvasContext = canvasContextRef.current;

    if (canvas && canvasContext) {
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    }

    setPaths([]);
  }, []);

  function resetSignedBy() {
    setSignedBy('');
  }

  function saveDrawing(): SaveSignatureResults {
    const canvas = canvasRef.current;
    const canvasContext = canvasContextRef.current;
    const signatureId = Date.now();
    const nameOfSignee = signedBy;

    resetSignedBy();

    if (canvas && canvasContext) {
      const { paths: trimmedPaths, dimensions } = trimSignature(paths);

      clearDrawing();
      addToPdfSignatureCollection({
        trimmedPaths,
        dimensions,
        id: signatureId,
        signedBy: nameOfSignee,
      });

      return {
        dimensions,
        trimmedPaths,
        id: signatureId,
        signedBy: nameOfSignee,
      };
    }

    return {
      dimensions: { width: 0, height: 0 },
      trimmedPaths: [],
      id: signatureId,
      signedBy: nameOfSignee,
    };
  }

  const getSignatureDimensions = useCallback(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const { width, height } = canvas.getBoundingClientRect();
      return { width, height };
    }

    return { width: 0, height: 0 };
  }, []);

  return {
    paths,
    setPaths,
    clearDrawing,
    canvasRef,
    canvasContextRef,
    getSignatureDimensions,
    saveDrawing,
    signedByTextFieldProps,
    resetSignedBy,
  };
};

export default useSignaturePad;
