import { css } from '@emotion/core';

export const editableContainerStyle = css`
  [name='Close modal'] {
    color: #fff;

    &:hover {
      color: initial;
    }
  }
`;

export const bodyStyle = css`
  padding: 0 !important;
`;
