import { css } from '@emotion/core';

interface ContainerStyleConfig {
  hidden?: boolean;
}

export function getContainerStyle({ hidden }: ContainerStyleConfig = { hidden: false }) {
  if (hidden) {
    return [
      css`
        display: none;
      `,
    ];
  }

  return css`
    display: flex;
  `;
}
