import { useRouter } from 'next/router';
import { FC, FormEvent, useCallback, useState } from 'react';

import i18n from 'i18next';
import { useTranslation, withTranslation } from 'react-i18next';

import {
  DropdownField,
  Heading,
  PrimaryButton,
  Text,
  useControlledField,
} from '@weave/design-system';

import { CompanyLogo, FooterLinks } from '@forms-exp/components';
import { analyticsTags, localeList } from '@forms-exp/constants';
import { useHasScroll, useIdleKioskRedirection, usePatronForm } from '@forms-exp/hooks';
import useStore from '@forms-exp/store';
import CountryField from '../form-elements/country-field/country-field.component';

import {
  buttonContainerStyle,
  formContentContainerStyle,
  formStyle,
  getFormFooterStyle,
  primaryButtonStyle,
  scrollContainerStyle,
  subTitleStyle,
  titleStyle,
} from './pre-admission-registration-form.style';

interface PreAdmissionRegistrationFormProps {
  logoSrc: string;
  companyId: string;
  officeName: string;
  isCountryDropdownEnabled: boolean;
}

const PreAdmissionRegistrationForm: FC<PreAdmissionRegistrationFormProps> = ({
  logoSrc,
  companyId,
  officeName,
  isCountryDropdownEnabled,
}) => {
  const { validatePatronInfoFields, setPatronInfoReady, setHasUnsavedChanges } =
    useStore();
  const { t } = useTranslation();

  const [selectedLanguage, setLanguage] = useState(i18n.language);
  const { elRef, hasScroll, ScrollCue, scrollCueProps } = useHasScroll<HTMLDivElement>();

  const router = useRouter();
  const { kiosk } = router.query as { kiosk?: string };

  const lanaguageFieldProps = useControlledField({
    required: false,
    type: 'dropdown',
    value: selectedLanguage,
    onChange: (value) => {
      i18n.changeLanguage(value);
      setLanguage(value);
    },
  });

  const { IdleKioskRedirectionModal, idleTimerProps, redirectToKiosk } =
    useIdleKioskRedirection({
      isInKioskMode: kiosk !== undefined,
      companyId,
    });

  const inputFocusAndChangeHandler = useCallback(() => {
    idleTimerProps.resetCountdown();
  }, [idleTimerProps]);

  const { showField } = usePatronForm({
    onFocusInput: inputFocusAndChangeHandler,
    onChangeInput: inputFocusAndChangeHandler,
  });

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    gtag('event', analyticsTags.preAdmissionRegistration);
    event.preventDefault();

    if (validatePatronInfoFields()) {
      setPatronInfoReady();
    } else {
      idleTimerProps.resetCountdown();
    }
  }

  return (
    <>
      <form
        css={formStyle}
        noValidate={true}
        onSubmit={handleSubmit}
        onChange={() => setHasUnsavedChanges(true)}
      >
        <div css={scrollContainerStyle} ref={elRef}>
          <div css={formContentContainerStyle}>
            <header>
              {isCountryDropdownEnabled && <CountryField />}
              {logoSrc ? (
                <CompanyLogo logoSrc={logoSrc} />
              ) : (
                <Heading level={1} textAlign="center">
                  {officeName}
                </Heading>
              )}
              <Heading level={2} textAlign="center" css={titleStyle}>
                {t('PreAdmissionTitle')}
              </Heading>
              <Text css={subTitleStyle}>{t('PreAdmissionSubTitle')}</Text>
            </header>

            <DropdownField
              label={t('LanguageSelect')}
              {...lanaguageFieldProps}
              name="language"
            >
              {localeList.map(({ name, value }) => (
                <DropdownField.Option key={value} value={value} searchValue={value}>
                  {name}
                </DropdownField.Option>
              ))}
            </DropdownField>

            {showField('firstName')}
            {showField('lastName')}
            {showField('birthdate')}
            {showField('address1')}
            {showField('address2')}
            {showField('city')}
            {showField('state')}
            {showField('postalCode')}
            {showField('email')}
            {showField('mobilePhone')}
          </div>

          <FooterLinks companyId={companyId} />
          <ScrollCue {...scrollCueProps} />
        </div>

        <footer css={getFormFooterStyle({ hasScroll })}>
          <div css={buttonContainerStyle}>
            <PrimaryButton
              data-testid="begin-form"
              type="submit"
              size="tiny"
              css={primaryButtonStyle}
            >
              {t('Begin')}
            </PrimaryButton>
          </div>
        </footer>
      </form>

      <IdleKioskRedirectionModal
        {...idleTimerProps}
        onCountDownElapse={redirectToKiosk}
      />
    </>
  );
};

export default withTranslation()(PreAdmissionRegistrationForm);
