import { GetState, SetState } from 'zustand';
import {
  PostalCodeLocale,
  ValidatorFieldState,
  getError,
  initializeFieldState,
  setValue,
  trimIfString,
  validateField,
} from '@weave/design-system';
import { downloadFile } from '@forms-exp/helpers';
import {
  CardCaptureFieldState,
  ConditionMapper,
  DataKey,
  ElectronicSignatureValue,
  FieldState,
  FormTemplateFieldData,
  PDFFieldState,
  PatronInfoKey,
  SaveSignatureResults,
  PaymentSetupLinks,
  Person,
  CardOnFileValue,
  CardOnFileFieldState,
} from '@forms-exp/types';
import { getCopyOfPaths, isPatronFormField } from '@forms-exp/utils';

import { State } from '../useStore';

interface InitFieldsPayload {
  fieldsToAdd: Record<string, FormTemplateFieldData>;
  initialSection: string;
  conditionMapper: ConditionMapper;
  previewMode?: boolean;
  paymentSetupLinks: PaymentSetupLinks | null;
  stripeCustomerId: string | null;
  stripeAccountId: string | null;
  person: Person | null;
  submissionToken: string;
  locationId: string;
}

export interface FormExperienceSlice {
  fields: Record<string, FieldState>;
  primaryFields: Partial<Record<DataKey, FieldState>>;
  conditionMapper: ConditionMapper;
  initialized: boolean;
  currentSection: string;
  reviewMode: boolean;
  reviewedOnce: boolean;
  hasUnsavedChanges: boolean;
  performedActions: Record<string, boolean>;
  eSignHistory: ElectronicSignatureValue[];
  pdfSignatures: SaveSignatureResults[];
  initFields: (initFields: InitFieldsPayload) => void;
  updateFieldValue: (payload: { name: string; value: any }) => void;
  updateEsignFieldValue: (payload: {
    name: string;
    value: any;
    isNewSignature?: boolean;
  }) => void;
  addToPdfSignatureCollection: (payload: SaveSignatureResults) => void;
  getPdfSignature: (index: number) => SaveSignatureResults | undefined;
  handleFieldBlur: (fieldName: string) => void;
  handleFieldFocus: (fieldName: string) => void;
  validateSection: (fieldNames: string[]) => boolean;
  updateCurrentSection: (sectionId: string) => void;
  toggleFormReview: () => void;
  resetFields: (fieldToReset: Record<string, any>) => void;
  clearFormExp: () => void;
  setHasUnsavedChanges: (newState: boolean) => void;
  setActionValues: (
    updatedStateFields: Record<string, FieldState>,
    conditionId: string,
    actionPerfomed: boolean
  ) => void;
  batchUpdate: (updatedStateFields: Record<string, FieldState>) => void;
  saveSignedPDF: (fieldId: string, base64Data: string) => void;
  updateStripeCardDetails: (fieldId: string, cardDetails: CardOnFileValue) => void;
}

const createFormExperienceSlice = (
  set: SetState<State>,
  get: GetState<State>
): FormExperienceSlice => ({
  fields: {},
  primaryFields: {},
  conditionMapper: {},
  initialized: false,
  currentSection: '',
  reviewMode: false,
  reviewedOnce: false,
  hasUnsavedChanges: false,
  performedActions: {},
  eSignHistory: [],
  pdfSignatures: [],
  initFields: async ({
    fieldsToAdd,
    initialSection,
    conditionMapper,
    previewMode = false,
    paymentSetupLinks = null,
    stripeCustomerId = null,
    stripeAccountId = null,
    person = null,
    locationId,
    submissionToken,
  }: InitFieldsPayload) => {
    const pdfPromises: Promise<string>[] = [];
    const pdfFieldIds: string[] = [];

    // Object mapping: PDF field ID -> base64 data
    const pdfBase64Data: Record<string, string> = {};

    for (const fieldId in fieldsToAdd) {
      const field = fieldsToAdd[fieldId];

      // Fetch the attachment data for the PDF field.
      if (field.meta.type === 'pdf') {
        const attachmentId = field.value;
        if (attachmentId) {
          pdfFieldIds.push(fieldId);
          pdfPromises.push(downloadFile(attachmentId));
        }
      }
    }

    // Wait for PDF file downloads to complete.
    if (pdfPromises.length > 0) {
      const pdfData = await Promise.all(pdfPromises);

      for (let i = 0; i < pdfData.length; i++) {
        pdfBase64Data[pdfFieldIds[i]] = pdfData[i];
      }
    }

    set((state) => {
      const locale = state.postalCodeLocale;
      const fieldStates: Record<string, FieldState> = {};
      const primaryFieldStates: Partial<Record<DataKey, FieldState>> = {};

      for (const fieldId in fieldsToAdd) {
        const field = fieldsToAdd[fieldId];
        const initialState = getInitialFieldState(field, previewMode, locale);
        const dataKey = field.meta.dataKey;
        fieldStates[fieldId] = initialState;

        if (dataKey) {
          primaryFieldStates[dataKey] = initialState;
          const patronField = state.patronInfoFields[dataKey as PatronInfoKey];

          if (patronField) {
            primaryFieldStates[dataKey]!.value = patronField.value;
            primaryFieldStates[dataKey]!.error = initialState.value
              ? ''
              : initialState.error;
          }
        }

        // Create an additional state to handle the checkbox that comes
        // with signature fields.
        if (field.meta.type === 'signature') {
          const copyOfField: FormTemplateFieldData = {
            ...field,
            meta: { ...field.meta, type: 'checkbox' },
          };

          fieldStates[`${fieldId}-checkbox`] = getInitialFieldState(
            copyOfField,
            previewMode
          );
        }

        // Set file data for PDF fields.
        if (field.meta.type === 'pdf') {
          const base64Data = pdfBase64Data[fieldId];

          if (base64Data) {
            fieldStates[fieldId].value = base64Data;
          }

          if (previewMode) {
            (fieldStates[fieldId] as PDFFieldState).requiresSignature = false;
          }
        }
      }

      return {
        ...state,
        fields: fieldStates,
        primaryFields: primaryFieldStates,
        initialized: true,
        currentSection: initialSection,
        conditionMapper,
        stripeCustomerId: !stripeCustomerId ? '' : stripeCustomerId,
        stripeAccountId: !stripeAccountId ? '' : stripeAccountId,
        paymentSetupLinks: !paymentSetupLinks ? {} : paymentSetupLinks,
        person,
        submissionToken,
        locationId,
        previewMode,
      };
    });
  },
  updateFieldValue: (payload: { name: string; value: any }) => {
    const { name, value } = payload;

    set((state) => {
      const field = state.fields[name];
      const isPrimaryField = field.isPrimaryField;
      const isPatronFormField = field.isPatronField;
      const dataKey = field.dataKey;
      let newState = { ...state };
      let newFieldState = { ...field };

      if (field.type === 'cardCapture') {
        newFieldState = {
          ...newFieldState,
          value: {
            ...(field as CardCaptureFieldState).value,
            ...value,
          },
        };

        // Incoming "value" structure -> {front?: <data>, back?: <data>}
        newState = {
          ...newState,
          fields: {
            ...state.fields,
            [name]: { ...newFieldState },
          },
        };

        if (dataKey) {
          if (isPrimaryField) {
            newState = {
              ...newState,
              primaryFields: {
                ...state.primaryFields,
                [dataKey]: { ...newFieldState },
              },
            };
          }

          if (isPatronFormField) {
            newState = {
              ...newState,
              patronInfoFields: {
                ...state.patronInfoFields,
                [dataKey]: { ...newFieldState },
              },
            };
          }
        }

        return newState;
      }

      if (field.type === 'searchable-listbox') {
        let newSelectedValues: string[] = [];
        // same as design system
        if (Array.isArray(field.value)) {
          // allow bulk selection, such as ctrl + a or meta keys + home/end
          if (Array.isArray(value)) {
            newSelectedValues = value;
          } else {
            if (field.value.includes(value)) {
              newSelectedValues = field.value.filter((v) => v !== value);
            } else {
              newSelectedValues = [...field.value, value];
            }
          }
        } else {
          newSelectedValues = value;
        }

        newFieldState = {
          ...newFieldState,
          type: 'searchable-listbox', // ts server gives error if we remove this.
          value: newSelectedValues,
        };

        newState = {
          ...newState,
          fields: {
            ...state.fields,
            [name]: { ...newFieldState },
          },
        };

        if (dataKey) {
          if (isPrimaryField) {
            newState = {
              ...newState,
              primaryFields: {
                ...state.primaryFields,
                [dataKey]: { ...newFieldState },
              },
            };
          }

          if (isPatronFormField) {
            newState = {
              ...newState,
              patronInfoFields: {
                ...state.patronInfoFields,
                [dataKey]: { ...newFieldState },
              },
            };
          }
        }

        return newState;
      }

      // Inspired by the design-system.
      // @ts-ignore
      const fieldState = setValue(field, value);
      newFieldState = {
        ...fieldState,
        ...(fieldState.touched
          ? validateField(fieldState)
          : { error: getError(fieldState) }),
      };

      newState = {
        ...newState,
        fields: {
          ...state.fields,
          [name]: { ...newFieldState },
        },
      };

      if (dataKey) {
        if (isPrimaryField) {
          newState = {
            ...newState,
            primaryFields: {
              ...state.primaryFields,
              [dataKey]: { ...newFieldState },
            },
          };
        }

        if (isPatronFormField) {
          const patronFieldState = setValue(
            state.patronInfoFields[dataKey as PatronInfoKey],
            value
          );

          newState = {
            ...newState,
            patronInfoFields: {
              ...state.patronInfoFields,
              [dataKey]: {
                ...patronFieldState,
                ...(patronFieldState.touched
                  ? validateField(patronFieldState)
                  : { error: getError(patronFieldState) }),
              },
            },
          };
        }
      }

      return newState;
    });
  },
  updateEsignFieldValue: (payload: {
    name: string;
    value: any;
    isNewSignature?: boolean;
  }) => {
    const { name, value, isNewSignature = false } = payload;

    set((state) => {
      const field = state.fields[name];
      let newState = { ...state };
      let newFieldState = { ...field };

      newFieldState = {
        ...newFieldState,
        value: {
          ...value,
        },
      };

      newState = {
        ...newState,
        fields: {
          ...state.fields,
          [name]: { ...newFieldState },
        },
      };

      if (isNewSignature) {
        newState = {
          ...newState,
          eSignHistory: [...newState.eSignHistory, value],
        };
      }

      return newState;
    });
  },
  addToPdfSignatureCollection: (signatureResults: SaveSignatureResults) => {
    set((state) => {
      const newState = {
        ...state,
        pdfSignatures: [...state.pdfSignatures, signatureResults],
      };

      return newState;
    });
  },
  getPdfSignature: (index: number): SaveSignatureResults | undefined => {
    const state = get();

    if (index < 0 || index >= state.pdfSignatures.length) {
      return undefined;
    }

    const signatureResult = state.pdfSignatures[index];

    return {
      ...signatureResult,
      trimmedPaths: getCopyOfPaths(signatureResult.trimmedPaths),
    };
  },
  handleFieldBlur: (fieldName: string) => {
    set((state) => {
      const field = state.fields[fieldName];
      const isPrimaryField = field.isPrimaryField;
      const isPatronField = field.isPatronField;
      const dataKey = field.dataKey;
      let newState = { ...state };
      let newFieldState = {
        ...field,
        active: false,
        touched: true,
      };

      if (field.type === 'cardCapture') {
        newState = {
          ...newState,
          fields: {
            ...state.fields,
            [fieldName]: { ...newFieldState },
          },
        };

        if (dataKey) {
          if (isPrimaryField) {
            newState = {
              ...newState,
              primaryFields: {
                ...state.primaryFields,
                [dataKey]: { ...newFieldState },
              },
            };
          }

          if (isPatronField) {
            newState = {
              ...newState,
              patronInfoFields: {
                ...state.patronInfoFields,
                [dataKey]: {
                  ...state.patronInfoFields[dataKey as PatronInfoKey],
                  active: false,
                  touched: true,
                },
              },
            };
          }
        }

        return newState;
      }

      // Inspired by the design-system.
      newFieldState = {
        ...newFieldState,
        value: trimIfString(field.value),
      };

      newFieldState = {
        ...newFieldState,
        // @ts-ignore
        ...validateField(newFieldState),
      };

      newState = {
        ...newState,
        fields: {
          ...state.fields,
          [fieldName]: { ...newFieldState },
        },
      };

      if (dataKey) {
        if (isPrimaryField) {
          newState = {
            ...newState,
            primaryFields: {
              ...state.primaryFields,
              [dataKey]: { ...newFieldState },
            },
          };
        }

        if (isPatronField) {
          const patronField = state.patronInfoFields[dataKey as PatronInfoKey];
          const newPatronFieldState = {
            ...patronField,
            active: false,
            touched: true,
            value: trimIfString(patronField.value),
          };

          newState = {
            ...newState,
            patronInfoFields: {
              ...state.patronInfoFields,
              ...validateField(newPatronFieldState),
            },
          };
        }
      }

      return newState;
    });
  },
  handleFieldFocus: (fieldName: string) => {
    if (!fieldName) {
      return;
    }

    set((state) => {
      const field = state.fields[fieldName];
      const isPrimaryField = field.isPrimaryField;
      const isPatronField = field.isPatronField;
      const dataKey = field.dataKey;

      let newState = {
        ...state,
      };

      if (dataKey) {
        if (isPrimaryField) {
          const newFieldState = {
            ...field,
            ...state.primaryFields[dataKey],
            active: true,
            required: field.required,
          };

          newState = {
            ...newState,
            fields: {
              ...newState.fields,
              [fieldName]: {
                ...newFieldState,
              },
            },
            primaryFields: {
              ...state.primaryFields,
              [dataKey]: { ...newFieldState },
            },
          };
        }

        if (isPatronField) {
          newState = {
            ...newState,
            patronInfoFields: {
              ...state.patronInfoFields,
              [dataKey]: {
                ...state.patronInfoFields[dataKey as PatronInfoKey],
                active: true,
              },
            },
          };
        }
      } else {
        newState = {
          ...state,
          fields: {
            ...state.fields,
            [fieldName]: {
              ...field,
              active: true,
            },
          },
        };
      }

      return newState;
    });
  },
  validateSection: (fieldIds: string[]): boolean => {
    if (fieldIds.length > 0) {
      let isSectionValid = true;

      set((state) => {
        let newFieldsState = { ...state.fields };
        for (const fieldId of fieldIds) {
          const field = state.fields[fieldId];
          const isPrimaryField = field.isPrimaryField;
          const dataKey = field.dataKey;

          if (field.hidden) {
            continue;
          }

          let newFieldState = { ...field, touched: true };

          if (dataKey) {
            if (isPrimaryField) {
              newFieldState = {
                ...newFieldState,
                ...state.primaryFields[dataKey],
              };
            }
          }

          if (newFieldState.type === 'currentDatePopulator') {
            continue;
          }

          // Inspired by the design-system.
          // Modified for use with the card-capture field type.
          if (newFieldState.type === 'cardCapture') {
            newFieldState.error = '';
            newFieldState['aria-invalid'] = false;

            if (
              newFieldState.required &&
              (newFieldState.value.front === null || newFieldState.value.back === null)
            ) {
              newFieldState.error = 'Please attach the necessary images!';
              newFieldState['aria-invalid'] = true;
            }

            newFieldsState = {
              ...newFieldsState,
              [fieldId]: {
                ...newFieldState,
              },
            };
          } else {
            // Inspired by the design-system.
            newFieldsState = {
              ...newFieldsState,
              [fieldId]: {
                ...newFieldState,
                // @ts-ignore
                ...validateField(newFieldState),
                touched: true,
                ['aria-invalid']: !!newFieldState.error,
              },
            };
          }

          if (newFieldState.type === 'eSign') {
            newFieldState.error = '';
            newFieldState['aria-invalid'] = false;
            if (
              newFieldState.required &&
              typeof newFieldState.value.data === 'string' &&
              newFieldState.value.data.trim() === ''
            ) {
              newFieldState.error = 'Signature is required';
              newFieldState['aria-invalid'] = true;
            }

            newFieldsState = {
              ...newFieldsState,
              [fieldId]: {
                ...newFieldState,
              },
            };
          }

          isSectionValid = isSectionValid && newFieldsState[fieldId].error === '';

          if (newFieldState.type === 'signature') {
            // Inspired by the design-system.
            const signatureCheckbox = {
              ...newFieldsState[`${fieldId}-checkbox`],
              touched: true,
            };

            newFieldsState = {
              ...newFieldsState,
              [`${fieldId}-checkbox`]: {
                ...signatureCheckbox,
                // @ts-ignore
                ...validateField(signatureCheckbox),
              },
            };

            isSectionValid =
              isSectionValid && newFieldsState[`${fieldId}-checkbox`].error === '';
          }

          if (newFieldState.type === 'pdf') {
            const pdfState = { ...newFieldsState[fieldId] } as PDFFieldState;
            pdfState.error = '';
            pdfState['aria-invalid'] = false;

            if (pdfState.requiresSignature && !pdfState.signedPDF) {
              pdfState.error = 'Please sign the PDF';
              pdfState['aria-invalid'] = true;
            }

            newFieldsState = {
              ...newFieldsState,
              [fieldId]: {
                ...pdfState,
              },
            };

            isSectionValid = isSectionValid && pdfState.error === '';
          }

          if (newFieldState.type === 'cardOnFile') {
            const cardOnFileField = {
              ...newFieldsState[fieldId],
            } as CardOnFileFieldState;
            cardOnFileField.error = '';
            cardOnFileField['aria-invalid'] = false;

            if (
              cardOnFileField.required &&
              (cardOnFileField.value.expiryMonth === '' ||
                cardOnFileField.value.expiryYear === '' ||
                cardOnFileField.value.lastFour === '' ||
                cardOnFileField.value.nameOnCard === '')
            ) {
              cardOnFileField.error = 'Please fill out all fields';
              cardOnFileField['aria-invalid'] = true;
            }

            newFieldsState = {
              ...newFieldsState,
              [fieldId]: {
                ...cardOnFileField,
              },
            };

            isSectionValid = isSectionValid && cardOnFileField.error === '';
          }
        }

        return {
          ...state,
          fields: { ...newFieldsState },
          isSectionValid,
        };
      });

      return isSectionValid;
    }

    return false;
  },
  setActionValues: (
    updatedStateFields: Record<string, FieldState>,
    conditionId: string,
    actionPerfomed: boolean
  ) => {
    set((state) => {
      let newState = {
        ...state,
        fields: {
          ...state.fields,
          ...updatedStateFields,
        },
        performedActions: {
          ...state.performedActions,
          [conditionId]: actionPerfomed,
        },
      };

      for (const fieldId in updatedStateFields) {
        const field = updatedStateFields[fieldId];
        const dataKey = field.dataKey;

        if (dataKey) {
          if (field.isPrimaryField) {
            newState = {
              ...newState,
              primaryFields: {
                ...newState.primaryFields,
                [dataKey]: { ...updatedStateFields[fieldId] },
              },
            };
          }

          if (field.isPatronField) {
            newState = {
              ...newState,
              patronInfoFields: {
                ...newState.patronInfoFields,
                [dataKey]: {
                  ...newState.patronInfoFields[dataKey as PatronInfoKey],
                  value: updatedStateFields[fieldId].value,
                },
              },
            };
          }
        }
      }

      return newState;
    });
  },
  batchUpdate: (updatedStateFields: Record<string, FieldState>) => {
    set((state) => ({
      ...state,
      fields: {
        ...state.fields,
        ...updatedStateFields,
      },
    }));
  },
  saveSignedPDF: (fieldId: string, base64Data: string) => {
    set((state) => {
      const newState = {
        ...state,
        fields: {
          ...state.fields,
          [fieldId]: {
            ...state.fields[fieldId],
            signedPDF: base64Data,
          },
        },
      };

      return newState;
    });
  },
  updateCurrentSection: (sectionId: string) => {
    set((state) => ({ ...state, currentSection: sectionId }));
  },
  toggleFormReview: () => {
    set((state) => ({
      ...state,
      reviewMode: !state.reviewMode,
      reviewedOnce: true,
    }));
  },
  resetFields: (fieldsToReset: Record<string, FieldState>) => {
    set((state) => {
      let newState = {
        ...state,
        fields: {
          ...state.fields,
          ...fieldsToReset,
        },
      };

      for (const fieldId in fieldsToReset) {
        const field = fieldsToReset[fieldId];
        const dataKey = field.dataKey;

        if (dataKey) {
          if (field.isPrimaryField) {
            newState = {
              ...newState,
              primaryFields: {
                ...newState.primaryFields,
                [dataKey]: { ...fieldsToReset[fieldId] },
              },
            };
          }

          if (field.isPatronField) {
            newState = {
              ...newState,
              patronInfoFields: {
                ...newState.patronInfoFields,
                [dataKey]: {
                  ...newState.patronInfoFields[dataKey as PatronInfoKey],
                  value: fieldsToReset[fieldId].value,
                },
              },
            };
          }
        }
      }

      return newState;
    });
  },
  clearFormExp: () => {
    set((state) => ({
      ...state,
      initialized: false,
      fields: {},
      primaryFields: {},
      currentSection: '',
      reviewMode: false,
      reviewedOnce: false,
    }));
  },
  setHasUnsavedChanges: (newState: boolean) => {
    set((state) => ({ ...state, hasUnsavedChanges: newState }));
  },

  updateStripeCardDetails: (fieldId: string, cardDetails: CardOnFileValue) => {
    set((state) => {
      const field = { ...state.fields[fieldId] } as CardOnFileFieldState;
      field.value = cardDetails;
      const newState = {
        ...state,
        fields: {
          ...state.fields,
          [fieldId]: field,
        },
      };

      return newState;
    });
  },
});

export default createFormExperienceSlice;

function getInitialFieldState(
  fieldData: FormTemplateFieldData,
  ignoreValidation = false,
  locale?: PostalCodeLocale
): FieldState {
  let normalisedFieldType;
  const dataKey = fieldData.meta.dataKey;
  const hidden = fieldData.hidden;

  switch (fieldData.meta.type) {
    case 'cardCapture':
      return {
        error: '',
        'aria-invalid': false,
        type: 'cardCapture',
        active: false,
        touched: false,
        value: { front: null, back: null },
        required: fieldData.required && !ignoreValidation,
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
      };

    case 'cardOnFile':
      return {
        error: '',
        'aria-invalid': false,
        type: 'cardOnFile',
        active: false,
        touched: false,
        value: {
          country: '',
          expiryYear: '',
          expiryMonth: '',
          lastFour: '',
          nameOnCard: '',
          brand: '',
        },
        required: fieldData.required && !ignoreValidation,
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
      };

    case 'date':
      normalisedFieldType = 'datePicker';
      break;

    case 'eSign':
      return {
        error: '',
        'aria-invalid': false,
        type: 'eSign',
        active: false,
        touched: false,
        value: {
          type: '',
          data: '',
          font_type: '',
          timestamp: '',
        },
        required: fieldData.required && !ignoreValidation,
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
      };

    case 'select':
      normalisedFieldType = 'dropdown';
      break;

    case 'signature':
      return {
        error: '',
        'aria-invalid': false,
        type: 'signature',
        active: false,
        touched: false,
        value: '',
        required: fieldData.required && !ignoreValidation,
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
      };

    case 'ssn':
      return {
        error: '',
        'aria-invalid': false,
        type: 'ssn',
        value: '',
        active: false,
        touched: false,
        required: fieldData.required && !ignoreValidation,
        validator: (field: ValidatorFieldState<'text'>) => {
          return field.value.length === 9 || ignoreValidation
            ? ''
            : 'Invalid social security number';
        },
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
      };

    case 'richText':
      return {
        type: 'richText',
        value: fieldData.value,
        error: '',
        'aria-invalid': false,
        active: false,
        touched: false,
        required: false,
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
      };

    case 'textarea':
      return {
        error: '',
        'aria-invalid': false,
        type: 'textarea',
        active: false,
        touched: false,
        value: '',
        required: fieldData.required && !ignoreValidation,
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
      };

    case 'multiselect-dropdown':
      normalisedFieldType = 'multiselect';
      break;

    case 'searchable-listbox':
      return {
        error: '',
        'aria-invalid': false,
        type: 'searchable-listbox',
        active: false,
        touched: false,
        value: [],
        required: fieldData.required && !ignoreValidation,
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
      };

    case 'pdf':
      return {
        error: '',
        'aria-invalid': false,
        type: 'pdf',
        active: false,
        touched: false,
        attachmendId: fieldData.value, // value is the attachment id
        value: '', // Need to fetch the attachment data
        required: fieldData.required && !ignoreValidation,
        hidden,
        isPrimaryField: false,
        dataKey,
        isPatronField: false,
        requiresSignature: fieldData.e_sign_required ?? false,
        signedPDF: undefined,
        label: fieldData.label,
      };

    case 'postalCode':
      return {
        error: '',
        'aria-invalid': false,
        type: 'postalCode',
        active: false,
        touched: false,
        value: '',
        required: fieldData.required && !ignoreValidation,
        hidden,
        isPrimaryField: dataKey ? true : false,
        dataKey,
        isPatronField: isPatronFormField(dataKey as string),
        label: fieldData.label,
        locale: locale,
      };

    default:
      normalisedFieldType = fieldData.meta.type;
  }

  // @ts-ignore
  return initializeFieldState({
    // @ts-ignore
    type: normalisedFieldType,
    required: fieldData.required && !ignoreValidation,
    conditionIds: fieldData.condition_ids || [],
    hidden,
    isPrimaryField: dataKey ? true : false,
    dataKey,
    isPatronField: isPatronFormField(dataKey as string),
  });
}
