import i18n from 'i18next';

import { PostalCodeLocale } from '@weave/design-system';

export const localeList = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Spanish',
    value: 'es',
  },
];

export const getZipCodeLabel = (locale: PostalCodeLocale): string => {
  if (i18n.language !== 'en') {
    return 'ZIP';
  }

  switch (locale) {
    case PostalCodeLocale.US:
      return 'ZIP Code';
    case PostalCodeLocale.CA:
      return 'Postal Code';
    default:
      return 'ZIP Code';
  }
};

export const getStateLabel = (locale: PostalCodeLocale): string => {
  if (i18n.language !== 'en') {
    return 'State';
  }

  switch (locale) {
    case PostalCodeLocale.US:
      return 'State';
    case PostalCodeLocale.CA:
      return 'Province';
    default:
      return 'State';
  }
};
