import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(0, 1, 1)};
  overflow: auto;
`;

export const textStyle = css`
  margin-left: auto;
  margin-right: auto;
`;

export const signatureContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  gap: ${theme.spacing(2)};
`;
