import axios from 'axios';

import { environmentVariables } from '@forms-exp/env';

export async function fetchCompanyLogo(companyId: string): Promise<string> {
  const companyLogo = sessionStorage.getItem(`${companyId}`);

  if (companyLogo) {
    return companyLogo;
  }

  if (companyId && !companyLogo) {
    try {
      const logoApi = `${environmentVariables.baseApiUrl}/forms-digital/v1/company/logo?companyId=${companyId}`;
      const { data } = await axios.get(logoApi);

      if (data.success && data.url) {
        // cache company logo in session storage
        sessionStorage.setItem(`${companyId}`, data.url);
        return data.url;
      }

      return '';
    } catch (error) {
      console.log('Error fetching the company logo:', error);
      return '';
    }
  }

  return '';
}
