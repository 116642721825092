import { FC } from 'react';

import {
  containerStyle,
  minutesContainerStyle,
  separationStyle,
  secondsContainerStyle,
  imageStyle,
} from './countdown-timer.style';

interface CountdownTimerProps {
  minutes: string;
  seconds: string;
}

const CountdownTimer: FC<CountdownTimerProps> = ({ minutes, seconds }) => {
  return (
    <div css={containerStyle}>
      <img src="/images/submission/timer.svg" alt="Timer icon" css={imageStyle} />

      <span css={minutesContainerStyle}>
        <span className="value">{minutes}</span>
        <span>Min</span>
      </span>

      <span css={separationStyle}>:</span>

      <span css={secondsContainerStyle}>
        <span className="value">{seconds}</span>
        <span>Sec</span>
      </span>
    </div>
  );
};

export default CountdownTimer;
