// iOS supported files "heic/heif" are not added in the list.
// When these types are uploaded from iPhone it converts internally
// them into "jpeg" and hence we don't need to maintain them sepearately
export const supportedFileTypes = [
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/webp',
  //'image/gif', TODO: Discuss with product
];
