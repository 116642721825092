import { format } from 'date-fns';

import useStore from '@forms-exp/store';
import { Person, Insurance, MedicalHistory, PrePopulateElements } from '@forms-exp/types';

const stringFieldTypes = ['text', 'textarea', 'email', 'phone', 'postalCode', 'radio'];

export const usePrePopulate = () => {
  const {
    prePopulatePatronInfoFields,
    prePopulateInsuranceFields,
    prePopulateElements,
    prePopulateMedicalHistoryFields,
  } = useStore();

  const prefillPrimaryData = (person: Person) => {
    const personDetails = { ...person };

    if (personDetails.birthdate) {
      const dob = new Date(personDetails.birthdate.replace(/-/g, '/'));
      personDetails.birthdate = format(dob, 'MM/dd/yyyy');
    }

    if (personDetails.gender) {
      personDetails.gender = personDetails.gender.toLowerCase();
    }

    prePopulatePatronInfoFields(personDetails);
  };

  const prefillPrimaryInsurance = ({ address, ...rest }: Insurance) => {
    const insuranceDetails = { ...address, ...rest };

    prePopulateInsuranceFields(insuranceDetails, 'primary');
  };

  const prefillSecondaryInsurance = ({ address, ...rest }: Insurance) => {
    const insuranceDetails = { ...address, ...rest };

    prePopulateInsuranceFields(insuranceDetails, 'secondary');
  };

  const prefillMedicalHistory = (medicalHistory: MedicalHistory) => {
    prePopulateMedicalHistoryFields(medicalHistory);
  };

  const prefillElements = (elements: PrePopulateElements) => {
    const fields: Record<string, string> = {};
    Object.keys(elements).forEach((key) => {
      const type = elements[key].type;
      const value = elements[key].value;
      if (type && value) {
        if (type === 'date') {
          const date = new Date(elements[key].value.replace(/-/g, '/'));
          fields[key] = format(date, 'MM/dd/yyyy');
        }

        if (stringFieldTypes.includes(type)) {
          fields[key] = elements[key].value;
        }

        if (type === 'checklist') {
          fields[key] = JSON.parse(elements[key].value);
        }
      }
    });
    prePopulateElements(fields);
  };

  return {
    prefillPrimaryData,
    prefillPrimaryInsurance,
    prefillSecondaryInsurance,
    prefillMedicalHistory,
    prefillElements,
  };
};
