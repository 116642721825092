import { createContext, useContext, FC } from 'react';

import useSignaturePad, { UseSignaturePadResults } from './useSignaturePad';

interface SignaturePadProviderProps {
  children: React.ReactNode;
}

interface SignaturePadProviderContext extends UseSignaturePadResults {}

const SignaturePadContext = createContext<SignaturePadProviderContext | undefined>(
  undefined
);

/**
 * Component to provide the signature pad context.
 */
export const SignaturePadProvider: FC<SignaturePadProviderProps> = ({ children }) => {
  const signaturePadProps = useSignaturePad();

  const contextValue: SignaturePadProviderContext = {
    ...signaturePadProps,
  };

  return (
    <SignaturePadContext.Provider value={contextValue}>
      {children}
    </SignaturePadContext.Provider>
  );
};

export const useSignaturePadContext = (): SignaturePadProviderContext => {
  const context = useContext(SignaturePadContext);

  if (context === undefined) {
    throw new Error(
      'useSignaturePadContext must be used within a <SignaturePadProvider />'
    );
  }

  return context;
};
