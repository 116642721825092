import axios from 'axios';

import { environmentVariables } from '@forms-exp/env';

type WritebackCapabilitySettingType =
  | 'writeback_update_person'
  | 'writeback_create_person'
  | 'writeback_upload_document'
  | 'medical_condition_primary_fields'
  | 'medical_condition_tab'
  | 'writeback_submission_tabs';

namespace WritebackCapabilitiesV3 {
  export interface WritebackSource {
    sourceId: string;
    capabilities: Array<{
      settingType: WritebackCapabilitySettingType;
      isCapable?: boolean;
    }>;
  }

  export interface Response {
    sourceCapabilities?: WritebackSource[];
  }
}

export default async function fetchWritebackCapabilities(
  locationId: string
): Promise<boolean> {
  try {
    const { data } = await axios.get<WritebackCapabilitiesV3.Response>(
      `${environmentVariables.baseApiUrl}/forms-digital/v3/writeback-capabilities?companyId=${locationId}`
    );

    return data.sourceCapabilities ? true : false;
  } catch {
    return false;
  }
}
