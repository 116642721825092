import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(1, 2)};
  margin-top: ${theme.spacing(1)};
`;

export const imageContainerStyle = css`
  max-width: 100%;
  display: flex;
  align-items: center;
  white-space: pre;
  flex-wrap: wrap;
`;

export const imageStyle = css`
  width: ${theme.spacing(5)};
`;

export const textStyle = css`
  margin: 0;
`;

export const brandTextStyle = [
  textStyle,
  css`
    text-transform: capitalize;
  `,
];

export const bannerStyles = css`
  margin-top: ${theme.spacing(2)};
`;
