import axios from 'axios';
import { environmentVariables } from '@forms-exp/env';
import { PaymentMethod } from './payments.types';

interface GetPaymentSetupLinkPayload {
  personId: string;
  locationId: string;
  submissionId: string;
  fieldId: string;
}

interface GetPaymentSetupLinkResponse {
  clientSecret: string;
  stripeCustomerId: string;
  stripeAccountId: string;
}

type ModifiedPaymentSetupLinkResponse =
  | ({ success: true; fieldId: string } & GetPaymentSetupLinkResponse)
  | { success: false; fieldId: string };

/**
 * Get the payment setup link for the user for a single card.
 */
export async function getPaymentSetupLink({
  fieldId,
  locationId,
  personId,
  submissionId,
}: GetPaymentSetupLinkPayload): Promise<ModifiedPaymentSetupLinkResponse> {
  const url = `${environmentVariables.baseApiUrl}/forms-digital/v1/payment-method`;

  try {
    const { data } = await axios.post<GetPaymentSetupLinkResponse>(url, {
      locationId,
      personId,
      submissionId,
    });
    return { success: true, fieldId, ...data };
  } catch (error) {
    console.error('Error getting the payment setup link: ', error);
    return { success: false, fieldId };
  }
}

interface GetPaymentMethodsPayload {
  personId: string;
  locationId: string;
  submissionId: string;
  paymentMethodIds: string[];
}

interface GetPaymentMethodsResponse {
  paymentMethods: PaymentMethod[];
}

/**
 * Returns the list of payment methods (saved card details) for the user.
 */
export async function getPaymentMethods(payload: GetPaymentMethodsPayload) {
  const url = `${environmentVariables.baseApiUrl}/forms-digital/v1/payment-methods`;

  try {
    const { data } = await axios.get<GetPaymentMethodsResponse>(url, { params: payload });
    return data.paymentMethods || [];
  } catch (error) {
    console.error('Error getting the payment methods: ', error);
    return [];
  }
}
