import { FC } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useCompanyLogo } from '@forms-exp/hooks';

import { containerStyle } from './kiosk-header.styles';
import { Heading } from '@weave/design-system';

export interface KioskHeaderProps {
  title: string;
  subTitle?: string;
}

const KioskHeader: FC<KioskHeaderProps> = ({ title, subTitle }) => {
  const router = useRouter();
  const { companyId } = router.query as { companyId: string };

  const logo = useCompanyLogo(companyId);

  function navigateToKioskHome() {
    router.replace(`/kiosk/${companyId}`);
  }

  return (
    <header css={containerStyle}>
      <div className="wrapper">
        <Head>
          <title>{title}</title>
        </Head>

        {logo && (
          <div className="logo">
            <img src={logo} alt="Company logo" onClick={navigateToKioskHome} />
          </div>
        )}

        <div className="text">
          <Heading level={2} className="title">
            {title}
          </Heading>
          {subTitle && <p className="sub-title">{subTitle}</p>}
        </div>
      </div>
    </header>
  );
};
export default KioskHeader;
