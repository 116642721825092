import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  text-align: center;
  margin-bottom: ${theme.spacing(2)};

  img {
    max-height: 85px;
    object-fit: contain;
    max-width: 100%;
  }
`;
