import create from 'zustand';

import createConfigSlice, { ConfigSlice } from './slices/configSlice';
import createFormExperienceSlice, {
  FormExperienceSlice,
} from './slices/formExperienceSlice';
import createFormPacketSlice, { FormPacketSlice } from './slices/formPacketSlice';
import createPatronInfoSlice, { PatronInfoSlice } from './slices/patronInfoSlice';

export type State = ConfigSlice & FormExperienceSlice & FormPacketSlice & PatronInfoSlice;

const useStore = create<State>((set, get) => ({
  ...createConfigSlice(set, get),
  ...createFormExperienceSlice(set, get),
  ...createFormPacketSlice(set, get),
  ...createPatronInfoSlice(set, get),
}));

export default useStore;
