import axios from 'axios';

import { environmentVariables } from '@forms-exp/env';
import { compressImage } from '@/utils/compression';

export enum UploadFileType {
  IMAGE = 'image',
  PDF = 'pdf',
}

interface CommonUploadPayload {
  fieldId: string;
  file: File;
  companyId: string;
  label: string;
}

interface UploadCardImagePayload extends CommonUploadPayload {
  fileType: UploadFileType.IMAGE;
  type?: 'front' | 'back';
}

interface UploadPDFPayload extends CommonUploadPayload {
  fileType: UploadFileType.PDF;
}

type UploadFilePayload = UploadCardImagePayload | UploadPDFPayload;

interface CommonUploadResponse {
  success: boolean;
  fieldId: string;
  fileId: string | null;
  fileType: UploadFileType;
  error?: any;
  message?: string;
  label: string;
}

interface ImageUploadResponse extends CommonUploadResponse {
  type?: 'front' | 'back';
}

export interface PDFUploadResponse extends CommonUploadResponse {}

export function uploadFile(filePayload: UploadPDFPayload): Promise<PDFUploadResponse>;
export function uploadFile(
  filePayload: UploadCardImagePayload
): Promise<ImageUploadResponse>;
export async function uploadFile(
  filePayload: UploadFilePayload
): Promise<ImageUploadResponse | PDFUploadResponse> {
  const url = `${environmentVariables.baseApiUrl}/forms-digital/v1/upload-media`;
  const { fieldId, file, companyId, fileType, label } = filePayload;

  const apiPayload = new FormData();

  // Compress the 'image' files before uploading
  let resultantFile = file;
  if (fileType !== UploadFileType.PDF) {
    resultantFile = await compressImage(file);
  }

  apiPayload.append('file', resultantFile);
  apiPayload.append('file_type', resultantFile.type);
  apiPayload.append('company_id', companyId);

  try {
    const { data } = await axios.post(url, apiPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    switch (fileType) {
      case UploadFileType.IMAGE:
        return {
          success: true,
          fieldId,
          fileId: data.data.data.id,
          type: filePayload.type,
          fileType,
          label,
        };

      case UploadFileType.PDF:
        return {
          success: true,
          fieldId,
          fileId: data.data.data.id,
          fileType,
          label,
        };
    }
  } catch (err) {
    return {
      success: false,
      fileId: null,
      fieldId,
      message: 'Failed to upload the file!',
      error: err,
      fileType,
      label,
    };
  }
}
