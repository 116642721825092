import { useRef, useEffect, useState, useCallback } from 'react';

import { useModalControl, ModalControlModalProps } from '@weave/design-system';

import IdleTimeModal from './idle-time-modal.component';

interface UseIdleTimerProps {
  countdownStart?: number; // in milliseconds
  showModalThresholdTime?: number; // in milliseconds
  ignoreTimer?: boolean;
  onCountdownElapse?: () => void;
}

export interface UseIdleTimerResults {
  modalProps: ModalControlModalProps;
  IdleTimeModal: typeof IdleTimeModal;
  thresholdCountdown: number;
  thresholdReached: boolean;
  resetCountdown: () => void;
  stopCountdown: () => void;
  openModal: () => void;
  closeModal: () => void;
}

const COUNTDOWN_START = 15 * 60 * 1000; // 15 minutes in milliseconds
const THRESHOLD_TIME = 60 * 1000; // 60 seconds
const COUNTDOWN_INTERVAL = 1000; // 1 second
const COUNTDOWN_RESET_DELAY = 500; // half a second

export function useIdleTimer({
  countdownStart = COUNTDOWN_START,
  showModalThresholdTime = THRESHOLD_TIME,
  ignoreTimer = false,
  onCountdownElapse,
}: UseIdleTimerProps = {}): UseIdleTimerResults {
  const autoStartDoneRef = useRef(false);
  const [thresholdCountdown, setThresholdCountdown] = useState(showModalThresholdTime);
  const [thresholdReached, setThresholdReached] = useState(false);
  const countdownRef = useRef(countdownStart);
  const setIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const resetCountdownTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { modalProps, openModal, closeModal } = useModalControl({
    disableReturnFocus: true,
  });

  const startCountdown = useCallback(() => {
    if (
      setIntervalRef.current !== null ||
      ignoreTimer ||
      countdownStart < showModalThresholdTime
    ) {
      return;
    }

    setIntervalRef.current = setInterval(() => {
      countdownRef.current -= 1000;

      if (countdownRef.current === 0 && onCountdownElapse) {
        onCountdownElapse();
      }

      if (countdownRef.current < 0) {
        clearCountdownInterval();
        return;
      }

      if (countdownRef.current <= showModalThresholdTime) {
        setThresholdCountdown(countdownRef.current);
        setThresholdReached(true);
      } else {
        setThresholdReached(false);
      }
    }, COUNTDOWN_INTERVAL);
  }, [showModalThresholdTime, ignoreTimer, countdownStart, onCountdownElapse]);

  const resetCountdown = useCallback(() => {
    if (resetCountdownTimeoutRef.current) {
      clearTimeout(resetCountdownTimeoutRef.current);
    }

    if (ignoreTimer) {
      return;
    }

    resetCountdownTimeoutRef.current = setTimeout(() => {
      if (modalProps.show) {
        closeModal();
      }

      clearCountdownInterval();
      setThresholdCountdown(showModalThresholdTime);
      countdownRef.current = countdownStart;
      startCountdown();
    }, COUNTDOWN_RESET_DELAY);
  }, [
    showModalThresholdTime,
    countdownStart,
    startCountdown,
    closeModal,
    modalProps.show,
    ignoreTimer,
  ]);

  useEffect(() => {
    if (!autoStartDoneRef.current) {
      startCountdown();
      autoStartDoneRef.current = true;
    }
  }, [startCountdown]);

  useEffect(() => {
    return () => {
      clearCountdownInterval();
    };
  }, []);

  function clearCountdownInterval() {
    if (setIntervalRef.current) {
      clearInterval(setIntervalRef.current);
      setIntervalRef.current = null;
    }
  }

  return {
    modalProps,
    IdleTimeModal,
    thresholdCountdown: thresholdCountdown / 1000,
    thresholdReached,
    resetCountdown,
    openModal,
    closeModal,
    stopCountdown: clearCountdownInterval,
  };
}
