import { FC } from 'react';

import { css } from '@emotion/core';

import { PrimaryButton, SecondaryButton } from '@weave/design-system';
import { theme } from '@weave/theme';

import { ButtonAlignment, ButtonStyle } from '@forms-exp/types';

interface ButtonFieldProps {
  id: string;
  buttonStyle: ButtonStyle;
  label: string;
  onClick: () => void;
}

function getStyles(alignment: ButtonAlignment) {
  return css`
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    width: max-content;

    ${alignment === 'center' && `margin: 0px auto;`}
    ${alignment === 'right' && `margin-left: auto;`}
  `;
}

export const ButtonField: FC<ButtonFieldProps> = ({
  id,
  buttonStyle,
  label,
  onClick,
}) => {
  const type = buttonStyle.type || 'primary';
  const buttonStyles = getStyles(buttonStyle.align);

  return (
    <div
      data-field-id={id}
      css={css`
        width: 100%;
        display: flex;
      `}
    >
      {type === 'primary' && (
        <PrimaryButton type="button" css={buttonStyles} onClick={onClick}>
          {label}
        </PrimaryButton>
      )}
      {type === 'secondary' && (
        <SecondaryButton type="button" css={buttonStyles} onClick={onClick}>
          {label}
        </SecondaryButton>
      )}
    </div>
  );
};
