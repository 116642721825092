import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import {
  ContentLoader,
  FormRow,
  Heading,
  PrimaryButton,
  Text,
  TextField,
  TextLink,
  useAlert,
  useForm,
} from '@weave/design-system';

import { Person, Insurance, MedicalHistory, PrePopulateElements } from '@forms-exp/types';
import { FooterLinks, CompanyLogo } from '@forms-exp/components';
import { useCountdownTimer } from '@forms-exp/hooks';

import { useOTP } from './use-otp';

import {
  contentLoaderStyle,
  fillingModeStyleContainerStyles,
  formRowStyle,
  otpFieldStyle,
  otpScreenContainerStyle,
  resendCodeStyle,
  wrapperStyle,
} from './otp-screen.style';
import { analyticsTags } from '@/constants/analytics-tag';

const otpFieldProps = {
  type: 'number',
  required: true,
  allowDecimal: false,
  allowNegative: false,
  maxLength: 1,
  minLength: 1,
  min: 0,
  max: 9,
};

interface DataToBePrefilled {
  person: Person | undefined;
  medicalHistory: MedicalHistory | undefined;
  prePopulateElements: PrePopulateElements | undefined;
  primaryInsurance: Insurance | undefined;
  secondaryInsurance: Insurance | undefined;
}

interface OTPScreenProps {
  logoSrc: string;
  officeName: string;
  companyId: string;
  onSuccess: (args: DataToBePrefilled) => void;
  onFillManually: () => void;
}

type OTPFieldsName = 'first' | 'second' | 'third' | 'fourth' | 'fifth' | 'sixth';

enum OtpFieldsIndexMap {
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
}

export const OTPScreen: FC<OTPScreenProps> = ({
  logoSrc,
  officeName,
  companyId,
  onSuccess,
  onFillManually,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const router = useRouter();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);

  const {
    CountdownTimer,
    isCountdownComplete: isOtpTimerExpired,
    minutesCountdown,
    secondsCountdown,
    restartCountdown,
  } = useCountdownTimer({
    timeToElapse: 180,
    initiate: !isLoading,
  });

  const { secret_code } = router.query as {
    secret_code: string;
  };

  const { userDetails, allowResend, isVerifyingOtp, verifyOtp, requestOtp } = useOTP();

  const initiateOtp = async () => {
    const r = await requestOtp();
    if (r.noContactDetails) {
      onFillManually();
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }
  };

  // verify secret code if present in the query params
  const verifySecretCode = async () => {
    const v = await verifyOtp(secret_code);

    if (v.isVerified && v.person) {
      onSuccess({
        person: v.person,
        medicalHistory: v.medicalHistory,
        prePopulateElements: v.prePopulateElements,
        primaryInsurance: v.primaryInsurance,
        secondaryInsurance: v.secondaryInsurance,
      });
      return;
    }

    if (v.isServerError || v.isInvalidOtp) {
      // don't show error message if invalid otp or server error  and redirect to fill form manually
      onFillManually();
    }
  };

  useEffect(() => {
    if (router.isReady) {
      // if secret code is present in the query params, verify it; otherwise initiate otp
      if (secret_code) {
        verifySecretCode();
      } else if (isLoading) {
        initiateOtp();
      }
    }
  }, [router.isReady, isLoading]);

  const { formProps, values, getFieldProps, seedValues } = useForm({
    fields: {
      first: {
        ...(otpFieldProps as any),
      },
      second: {
        ...(otpFieldProps as any),
      },
      third: {
        ...(otpFieldProps as any),
      },
      fourth: {
        ...(otpFieldProps as any),
      },
      fifth: {
        ...(otpFieldProps as any),
      },
      sixth: {
        ...(otpFieldProps as any),
      },
    },
  });

  const onCharacterInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as OTPFieldsName;
    const value = e.target.value;
    getFieldProps(name).onChange(e);
    const nan = isNaN(Number(value.trim() || 'a')); // 'a' is fallback value for space or empty string

    if (formRef.current && value.length === 1 && !nan && name !== 'sixth') {
      // focus on next field
      formRef.current.getElementsByTagName('input')[OtpFieldsIndexMap[name] + 1].focus();
    }

    if (isInvalidOtp) {
      setIsInvalidOtp(false);
    }
  };

  const onVerifyOtp = async () => {
    const otp = Object.values(values).join('');
    const v = await verifyOtp(otp);
    gtag('event', analyticsTags.verifyOTP);
    if (v.isServerError) {
      alert.error(
        'Failed to verify code. Please fill out form manually if problem persists.'
      );
    }

    if (v.isInvalidOtp) {
      setIsInvalidOtp(true);
    }

    if (v.isVerified && v.person) {
      alert.success('Code verified successfully');
      onSuccess({
        person: v.person,
        medicalHistory: v.medicalHistory,
        prePopulateElements: v.prePopulateElements,
        primaryInsurance: v.primaryInsurance,
        secondaryInsurance: v.secondaryInsurance,
      });
    }
  };

  // detects backspace and changes focus to previous field
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const name = e.currentTarget.name as OTPFieldsName;

      if (formRef.current && values[name] === '' && name !== 'first') {
        // focus on previous field
        formRef.current
          .getElementsByTagName('input')
          [OtpFieldsIndexMap[name] - 1].focus();
      }
    }
  };

  const onResendOtp = () => {
    gtag('event', analyticsTags.resentOTP);
    requestOtp(true);
    restartCountdown();
  };

  const onPasteCode = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    try {
      const pastedText = e.clipboardData.getData('text');
      const isNumber = /^-?\d+$/.test(pastedText);

      // only update values if pasted text is a number
      if (isNumber) {
        const fieldsMapping: Record<number, OTPFieldsName> = {
          0: 'first',
          1: 'second',
          2: 'third',
          3: 'fourth',
          4: 'fifth',
          5: 'sixth',
        };
        const valuesTobeUpdates = {
          ...values,
        };

        const splittedText = pastedText.split('');
        for (let i = 0; i < 6; i++) {
          const name = fieldsMapping[i];
          const char = splittedText[i] || valuesTobeUpdates[name];
          valuesTobeUpdates[name] = char;
        }

        seedValues(valuesTobeUpdates);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return (
      <div css={wrapperStyle}>
        <div css={fillingModeStyleContainerStyles}>
          <div css={otpScreenContainerStyle}>
            {logoSrc ? (
              <CompanyLogo logoSrc={logoSrc} />
            ) : (
              <Heading level={1} textAlign="center">
                {officeName}
              </Heading>
            )}
            <ContentLoader css={contentLoaderStyle} show message="Loading . . ." />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div css={wrapperStyle}>
      <div css={fillingModeStyleContainerStyles}>
        <div css={otpScreenContainerStyle}>
          {logoSrc ? (
            <CompanyLogo logoSrc={logoSrc} />
          ) : (
            <Heading level={1} textAlign="center">
              {officeName}
            </Heading>
          )}

          <Heading level={3}>Verify & Get Started</Heading>

          <Text textAlign="center">
            To auto-fill your form, we need to validate your identity through a
            verification code.
          </Text>

          <Text textAlign="center">
            We&rsquo;ve sent a 6 digit code to
            {userDetails?.email ? ` to ${userDetails?.email}` : ''}
            {!!userDetails.email && !!userDetails.phoneNumber ? ' and' : ''}
            {userDetails?.phoneNumber ? ` at ${userDetails?.phoneNumber}` : ''}.
          </Text>
          <form {...formProps} ref={formRef}>
            <FormRow css={formRowStyle}>
              <TextField
                css={otpFieldStyle(isOtpTimerExpired, isInvalidOtp)}
                {...getFieldProps('first')}
                disabled={isOtpTimerExpired}
                autoComplete="false"
                error=""
                onChange={onCharacterInput}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                onPaste={onPasteCode}
              ></TextField>
              <TextField
                css={otpFieldStyle(isOtpTimerExpired, isInvalidOtp)}
                {...getFieldProps('second')}
                disabled={isOtpTimerExpired}
                autoComplete="false"
                error=""
                onChange={onCharacterInput}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                onPaste={onPasteCode}
              ></TextField>
              <TextField
                css={otpFieldStyle(isOtpTimerExpired, isInvalidOtp)}
                {...getFieldProps('third')}
                disabled={isOtpTimerExpired}
                autoComplete="false"
                error=""
                onChange={onCharacterInput}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                onPaste={onPasteCode}
              ></TextField>
              <TextField
                css={otpFieldStyle(isOtpTimerExpired, isInvalidOtp)}
                {...getFieldProps('fourth')}
                disabled={isOtpTimerExpired}
                autoComplete="false"
                error=""
                onChange={onCharacterInput}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                onPaste={onPasteCode}
              ></TextField>
              <TextField
                css={otpFieldStyle(isOtpTimerExpired, isInvalidOtp)}
                {...getFieldProps('fifth')}
                disabled={isOtpTimerExpired}
                autoComplete="false"
                error=""
                onChange={onCharacterInput}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                onPaste={onPasteCode}
              ></TextField>
              <TextField
                css={otpFieldStyle(isOtpTimerExpired, isInvalidOtp)}
                {...getFieldProps('sixth')}
                disabled={isOtpTimerExpired}
                autoComplete="false"
                error=""
                onChange={onCharacterInput}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                onPaste={onPasteCode}
              ></TextField>
            </FormRow>
            {isInvalidOtp && (
              <Text size="small" color="error">
                Please enter a valid code
              </Text>
            )}
          </form>

          <CountdownTimer minutes={minutesCountdown} seconds={secondsCountdown} />
          {!isOtpTimerExpired ? (
            <PrimaryButton
              disabled={
                !values.first ||
                !values.second ||
                !values.third ||
                !values.fourth ||
                !values.fifth ||
                !values.sixth
              }
              size="tiny"
              onClick={onVerifyOtp}
            >
              Verify &amp; Proceed
            </PrimaryButton>
          ) : (
            <PrimaryButton size="tiny" onClick={onResendOtp} css={resendCodeStyle}>
              Resend Code
            </PrimaryButton>
          )}
          {!isOtpTimerExpired && (
            <div className="resend-text">
              <Text>Didn&#39;t receive a Code?</Text>
              <TextLink
                disabled={!allowResend}
                className="resend-link"
                onClick={onResendOtp}
              >
                Resend
              </TextLink>
            </div>
          )}
          <Text className="text-center">OR</Text>
          <Text className="manual-fill-text">
            Don&#39;t have access to your phone or email?
          </Text>
          <TextLink onClick={onFillManually}>Fill out manually</TextLink>
        </div>
      </div>
      <FooterLinks companyId={companyId} />
      <ContentLoader
        css={contentLoaderStyle}
        show={isVerifyingOtp}
        message="Verifying . . ."
      />
    </div>
  );
};
