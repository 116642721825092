import { FC } from 'react';

import { format } from 'date-fns';

import {
  PrimaryButton,
  RadioField,
  Text,
  TextField,
  useFormField,
} from '@weave/design-system';

import useStore from '@forms-exp/store';

import { SignatureHistoryCarouselComponent } from './signature-history-carousel.component';
import {
  typeSignatureOutputWrapper,
  wetSignaturesButtonsStyle,
} from './e-signature-field.style';

const fontClassToNameMapping: Record<string, string> = {
  font1: 'Kaushan Script',
  font2: 'Nothing You Could Do',
  font3: 'Caveat',
  font4: 'Fuzzy Bubbles',
};

interface TypedSignatureProps {
  id: string;
  onDone: () => void;
}

const TypedSignature: FC<TypedSignatureProps> = ({ id, onDone }) => {
  const typeSignatureProps = useFormField({ type: 'text' });
  const typedSignatureFont = useFormField({ type: 'radio', value: 'font1' });

  const { updateEsignFieldValue } = useStore();

  const onTypedSignatureDone = () => {
    updateEsignFieldValue({
      name: id,
      value: {
        type: 'text', // 'image' | 'text' | ''
        data: typeSignatureProps.value,
        font_type: fontClassToNameMapping[typedSignatureFont.value],
        timestamp: format(new Date(), 'MMMM d, yyyy hh:mm:ss'),
      },
      isNewSignature: true,
    });
    onDone();
  };
  return (
    <>
      <div css={typeSignatureOutputWrapper}>
        <TextField
          className={typedSignatureFont.value}
          {...typeSignatureProps}
          label=""
          name="signature-type"
          placeholder="Signature"
          autoComplete="off"
        />
        <Text>Type Your Signature Above</Text>
        <RadioField {...typedSignatureFont} name="typeSignatureRadio" label="">
          <RadioField.Radio value="font1">
            <div className="typed-signature font1">
              {typeSignatureProps.value || 'Signature'}
            </div>
          </RadioField.Radio>
          <RadioField.Radio value="font2">
            <div className="typed-signature font2">
              {typeSignatureProps.value || 'Signature'}
            </div>
          </RadioField.Radio>
          <RadioField.Radio value="font3">
            <div className="typed-signature font3">
              {typeSignatureProps.value || 'Signature'}
            </div>
          </RadioField.Radio>
          <RadioField.Radio value="font4">
            <div className="typed-signature font4">
              {typeSignatureProps.value || 'Signature'}
            </div>
          </RadioField.Radio>
        </RadioField>
      </div>
      <SignatureHistoryCarouselComponent id={id} onDone={onDone} />
      <div css={wetSignaturesButtonsStyle}>
        <PrimaryButton onClick={onTypedSignatureDone} size="tiny">
          eSign
        </PrimaryButton>
      </div>
    </>
  );
};
export default TypedSignature;
