import {
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { jsPDF } from 'jspdf';

import { ModalControlResponse, useDebouncedValue } from '@weave/design-system';

import { SignaturePosition } from '@forms-exp/types';
import useStore from '@forms-exp/store';
import { scaleSignaturePath } from '@forms-exp/utils';

import { SignaturePayload, Signature } from '../pdf-editor.types';

interface UpdateSignaturePositionPayload {
  id: number;
  position: SignaturePosition;
}

interface CanvasPageToSave {
  pageNumber: number;
  data: string;
}

interface CreatePDFPayload {
  pages: CanvasPageToSave[];
}

interface UsePDFEditorProps {
  fieldId: string;
  fullscreenModalControlProps: ModalControlResponse;
}

export const PDF_SCALE_LEVEL = {
  MIN: 0.4,
  MAX: 1.0,
};

export interface UsePDFResults {
  pdfDocProxy: PDFDocumentProxy | undefined;
  pdfScaleLevel: number;
  initialisePDFProxy: Dispatch<SetStateAction<PDFDocumentProxy | undefined>>;
  addSignature: (signature: SignaturePayload) => void;
  updateSignaturePosition: (payload: UpdateSignaturePositionPayload) => void;
  removeSignature: (signatureId: number) => void;
  totalPages: number;
  currentPage: number;
  updateTotalPages: Dispatch<SetStateAction<number>>;
  updateCurrentPage: Dispatch<SetStateAction<number>>;
  signatures: Signature[];
  startSignaturePlacement: (signatureId: number) => void;
  hasDraggableSignature: () => boolean;
  finishSignaturePlacement: () => void;
  hasSignature: () => boolean;
  isReadyForPDF: boolean;
  convertToPDF: () => void;
  saveCanvasForPDF: (pageNumber: number, data: string) => void;
  closeFullscreenModal: () => void;
  zoomOut: () => void;
  zoomIn: () => void;
  getZoomLevel: () => number;
  increaseSignatureSize: (signatureId: number) => void;
  decreaseSignatureSize: (signatureId: number) => void;
  scrollPosition: { x: number; y: number };
  updateScrollPosition: Dispatch<SetStateAction<{ x: number; y: number }>>;
  isTouchActive: boolean;
  setIsTouchActive: Dispatch<SetStateAction<boolean>>;
  offsetMargin: number;
  setOffsetMargin: Dispatch<SetStateAction<number>>;
  pdfDimensions: { width: number; height: number };
  updatePdfDimensions: (payload: { width: number; height: number }) => void;
}

const usePDF = ({
  fieldId,
  fullscreenModalControlProps,
}: UsePDFEditorProps): UsePDFResults => {
  const [pdfDocProxy, setPDFDocProxy] = useState<PDFDocumentProxy | undefined>();
  const [signatures, setSignatures] = useState<Signature[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isReadyForPDF, setIsReadyForPDF] = useState(false);
  const [canvasPagesToSave, setCanvasPagesToSave] = useState<CanvasPageToSave[]>([]);
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const [isTouchActive, setIsTouchActive] = useState(false);
  const [offsetMargin, setOffsetMargin] = useState(0);

  const arePdfPageDimensionsSet = useRef(false);
  const [pdfDimensions, setPdfDimentions] = useState({ width: 0, height: 0 });

  const [scaleLevel, setScaleLevel] = useState(1.0);
  const debouncedScaleLevel = useDebouncedValue(scaleLevel);

  const { saveSignedPDF } = useStore();

  const closeFullscreenModal = useCallback(() => {
    fullscreenModalControlProps.closeModal();
  }, [fullscreenModalControlProps]);

  const updatePdfDimensions = useCallback(
    (payload: { width: number; height: number }) => {
      if (arePdfPageDimensionsSet.current || totalPages === 0) {
        return;
      }

      setPdfDimentions({ width: payload.width, height: payload.height * totalPages });
      arePdfPageDimensionsSet.current = true;
    },
    [totalPages]
  );

  const addSignature = useCallback((signature: SignaturePayload) => {
    setSignatures((prevSignatures) => [
      ...prevSignatures,
      { ...signature, id: Date.now(), isDraggable: true },
    ]);
  }, []);

  const updateSignaturePosition = useCallback(
    ({ id, position }: UpdateSignaturePositionPayload) => {
      setSignatures((prevSignatures) => {
        const signatureIndex = prevSignatures.findIndex(
          (signature) => signature.id === id
        );
        const updatedSignature = {
          ...prevSignatures[signatureIndex],
          position,
          pageNumber: currentPage,
        };
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[signatureIndex] = updatedSignature;
        return updatedSignatures;
      });
    },
    [currentPage]
  );

  const removeSignature = useCallback((signatureId: number) => {
    setSignatures((prevSignatures) =>
      prevSignatures.filter((signature) => signature.id !== signatureId)
    );
  }, []);

  const saveCanvasForPDF = useCallback((pageNumber: number, data: string) => {
    setCanvasPagesToSave((prevCanvasPagesToSave) => {
      const updatedCanvasPagesToSave = [...prevCanvasPagesToSave];

      const canvasPageToSaveIndex = updatedCanvasPagesToSave.findIndex(
        (page) => page.pageNumber === pageNumber
      );

      if (canvasPageToSaveIndex !== -1) {
        updatedCanvasPagesToSave[canvasPageToSaveIndex] = { pageNumber, data };
        return updatedCanvasPagesToSave;
      }

      updatedCanvasPagesToSave.push({ pageNumber, data });
      return updatedCanvasPagesToSave;
    });
  }, []);

  const createPDF = useCallback(
    ({ pages }: CreatePDFPayload) => {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      pages.forEach((page) => {
        doc.addImage(page.data, 'PNG', 0, 0, 210, 297);
        if (page.pageNumber !== pages.length) {
          doc.addPage();
        }
      });

      const base64Data = doc.output('datauristring').split(',')[1];
      saveSignedPDF(fieldId, base64Data);
      closeFullscreenModal();
    },
    [saveSignedPDF, fieldId, closeFullscreenModal]
  );

  useEffect(() => {
    if (canvasPagesToSave.length !== totalPages || !isReadyForPDF) {
      return;
    }

    const canvasPagesToSaveSorted = [...canvasPagesToSave].sort(
      (a, b) => a.pageNumber - b.pageNumber
    );

    createPDF({ pages: canvasPagesToSaveSorted });
    setIsReadyForPDF(false);
  }, [canvasPagesToSave, totalPages, isReadyForPDF, createPDF]);

  function startSignaturePlacement(signatureId: number) {
    setSignatures((prevSignatures) =>
      prevSignatures.map((signature) => ({
        ...signature,
        isDraggable: signature.id === signatureId,
      }))
    );
  }

  function hasDraggableSignature() {
    return signatures.some((signature) => signature.isDraggable);
  }

  function finishSignaturePlacement() {
    setSignatures((prevSignatures) =>
      prevSignatures.map((signature) => ({
        ...signature,
        isDraggable: false,
      }))
    );
  }

  function hasSignature() {
    return signatures.length > 0;
  }

  function convertToPDF() {
    setIsReadyForPDF(true);
  }

  function zoomOut() {
    setScaleLevel((prevScaleLevel) => {
      /**
       * The scale level is 1.0 or greater, so we can't zoom out any further.
       * The zoom level 1.0 is the default zoom level for PDFjs.
       * It is equivalent to 100%.
       */
      if (prevScaleLevel >= PDF_SCALE_LEVEL.MAX) {
        return 1.0;
      }

      return prevScaleLevel + 0.1;
    });
  }

  function zoomIn() {
    setScaleLevel((prevScaleLevel) => {
      /**
       * The scale level is less that 0.4, so we can't zoom in any further.
       * The zoom level 0.3 is the maximum zoom level for PDFjs without distortions.
       * It is equivalent to 170%.
       */
      if (prevScaleLevel > PDF_SCALE_LEVEL.MIN) {
        return prevScaleLevel - 0.1;
      }

      return prevScaleLevel;
    });
  }

  function getZoomLevel() {
    return 100 + (1 - debouncedScaleLevel) * 100;
  }

  function increaseSignatureSize(signatureId: number) {
    setSignatures((prevSignatures) =>
      prevSignatures.map((signature) => {
        if (signature.id === signatureId) {
          const newScale = signature.scale + 0.1;
          const scaleResult = scaleSignaturePath({
            paths: signature.paths,
            scale: newScale,
          });

          return {
            ...signature,
            scaledDimensions: scaleResult.dimensions,
            scaledPaths: scaleResult.paths,
            scale: newScale,
          };
        }

        return signature;
      })
    );
  }

  function decreaseSignatureSize(signatureId: number) {
    setSignatures((prevSignatures) =>
      prevSignatures.map((signature) => {
        if (signature.id === signatureId) {
          if (signature.scale <= 0.5) {
            return signature;
          }

          const newScale = signature.scale - 0.1;
          const scaleResult = scaleSignaturePath({
            paths: signature.paths,
            scale: newScale,
          });

          return {
            ...signature,
            scaledDimensions: scaleResult.dimensions,
            scaledPaths: scaleResult.paths,
            scale: newScale,
          };
        }

        return signature;
      })
    );
  }

  return {
    pdfDocProxy,
    pdfScaleLevel: debouncedScaleLevel,
    initialisePDFProxy: setPDFDocProxy,
    addSignature,
    updateSignaturePosition,
    removeSignature,
    totalPages,
    currentPage,
    updateTotalPages: setTotalPages,
    updateCurrentPage: setCurrentPage,
    signatures,
    startSignaturePlacement,
    hasDraggableSignature,
    finishSignaturePlacement,
    hasSignature,
    isReadyForPDF,
    convertToPDF,
    saveCanvasForPDF,
    closeFullscreenModal,
    zoomIn,
    zoomOut,
    getZoomLevel,
    increaseSignatureSize,
    decreaseSignatureSize,
    scrollPosition,
    updateScrollPosition: setScrollPosition,
    isTouchActive,
    setIsTouchActive,
    offsetMargin,
    setOffsetMargin,
    pdfDimensions,
    updatePdfDimensions,
  };
};

export default usePDF;
