import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const wrapperStyle = css`
  height: 100%;
  position: relative;
  overflow: auto;
`;

export const contentStyle = css`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: ${theme.spacing(2)};

  .note-wrapper {
    display: flex;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};

    .note {
      margin: 0;
    }
  }

  .auto-fill {
    margin-bottom: ${theme.spacing(2)};
  }
`;
