import { css } from '@emotion/core';
import { theme } from '@weave/theme';

interface CanvasStyleConfig {
  isDraggable: boolean;
}

export function getCanvasContainerStyle({ isDraggable }: CanvasStyleConfig) {
  return css`
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    cursor: ${isDraggable ? 'grab' : 'default'};
    border-width: 2px;
    border-style: solid;
    border-color: ${isDraggable ? `${theme.colors.primary50}` : 'transparent'};
    background-color: transparent;
    box-shadow: ${isDraggable ? `${theme.shadows.floating}` : 'none'};
  `;
}

export const helperTextStyle = css`
  color: ${theme.colors.neutral50} !important;
  font-size: ${theme.fontSize(12)} !important;
  position: absolute;
  top: -50px;
`;

export const buttonsContainerStyle = css`
  position: absolute;
  top: -40px;
  right: -50px;
`;
