import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const tabContainerStyle = css`
  display: flex;

  ::after {
    content: '';
    border-bottom: 2px solid ${theme.colors.neutral20};
    flex-grow: 1;
  }

  .tab {
    padding: ${theme.spacing(1, 3)};
    border-bottom: 2px solid ${theme.colors.neutral20};
    cursor: pointer;

    p {
      margin: 0;
    }

    :hover,
    &.active {
      color: ${theme.colors.secondary.seaweed40};
      border-width: 2px;
      border-color: ${theme.colors.secondary.seaweed60};
      font-weight: ${theme.font.weight.bold};
    }
  }
`;

export const sectionContainerStyle = css`
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2)};

  :not(:last-of-type) {
    margin-bottom: ${theme.spacing(3)};
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.neutral10};
    padding-bottom: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};

    h3 {
      margin: 0;
    }

    .edit-section-button {
      cursor: pointer;
    }
  }

  .edit-section-buttons {
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing(2)};

    & > button:not(:last-of-type) {
      margin-right: ${theme.spacing(2)};
    }
  }
`;
