import { useState, useEffect } from 'react';

import { fetchLocationDetails } from '@forms-exp/helpers';

export const useCompanyName = (companyId: string, enabled: boolean = true) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (enabled) {
      fetchLocationDetails(companyId).then((locationData) => {
        setName(locationData?.name || '');
      });
    }
  }, [companyId, enabled]);

  return name;
};
