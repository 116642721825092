import { FC } from 'react';

import { usePDFEditor } from '@forms-exp/hooks';

interface PDFFieldProps {
  fieldId: string;
  base64Data: string;
  isEditable?: boolean;
  onCloseModal?: () => void;
}

const PDFField: FC<PDFFieldProps> = ({
  fieldId,
  base64Data,
  isEditable = false,
  onCloseModal,
}) => {
  const { PDFViewer, pdfViewerModalControls, pdfUrl } = usePDFEditor({
    base64Data,
    onCloseModal,
  });

  return (
    <PDFViewer
      fieldId={fieldId}
      modalControl={pdfViewerModalControls}
      pdfUrl={pdfUrl}
      isEditable={isEditable}
    />
  );
};

export default PDFField;
