import { PersonalDataKey } from '@forms-exp/types';

const PATRON_FIELDS: PersonalDataKey[] = [
  'firstName',
  'lastName',
  'birthdate',
  'city',
  'address1',
  'address2',
  'state',
  'postalCode',
  'email',
  'mobilePhone',
];

export function isPatronFormField(fieldName: string) {
  return PATRON_FIELDS.includes(fieldName as PersonalDataKey);
}
