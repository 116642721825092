import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const wrapperStyle = css`
  height: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const contentStyle = css`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: ${theme.spacing(2)};
  flex: 1;

  .greeting {
    margin: 0;
  }
`;

export const securityNoteStyle = css`
  margin-top: ${theme.spacing(2)};
  svg {
    align-self: flex-start;
  }
`;

export const submitButtonStyle = css`
  margin: ${theme.spacing(4)} auto 0;
`;
