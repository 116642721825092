import { FC } from 'react';
import { Text, PlusIcon, Heading } from '@weave/design-system';
import { containerStyle } from './add-payment-card-placeholder.styles';

interface AddPaymentCardPlaceholderProps {
  label: string;
  onClick: () => void;
}

const AddPaymentCardPlaceholder: FC<AddPaymentCardPlaceholderProps> = ({
  label,
  onClick,
}) => {
  return (
    <>
      <Heading level={3}>{label}</Heading>
      <div css={containerStyle} onClick={onClick}>
        <PlusIcon />
        <Text color="primary" weight="bold">
          Add Card on File
        </Text>
      </div>
    </>
  );
};

export default AddPaymentCardPlaceholder;
