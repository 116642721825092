import { useRef, useState, useCallback, useEffect, RefObject, FC } from 'react';

import ScrollCue, { ScrollCueProps } from './scroll-cue.component';

interface UseHasScrollParams<T extends HTMLElement> {
  externalElRef?: RefObject<T>;
  key?: any;
}

interface UseHasScrollResults<T extends HTMLElement> {
  elRef: RefObject<T>;
  hasScroll: boolean;
  ScrollCue: typeof ScrollCue;
  scrollCueProps: ScrollCueProps;
}

const APPEARANCE_DELAY = 3000;

export const useHasScroll = <T extends HTMLElement = HTMLElement>({
  externalElRef,
  key,
}: UseHasScrollParams<T> = {}): UseHasScrollResults<T> => {
  const [hasScroll, setHasScroll] = useState(false);
  const [hasMoreContent, setHasMoreContent] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const isScrollingByClick = useRef(false);

  const internalElRef = useRef<T>(null);
  const elRef = externalElRef ? externalElRef : internalElRef;

  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isReadyTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const elHeightRef = useRef(0);
  const resizeInProgressRef = useRef(false);
  const heightChangeInProgressRef = useRef(false);

  const scrollEventHandler = useCallback(() => {
    if (resizeInProgressRef.current || heightChangeInProgressRef.current) {
      return;
    }

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    if (!isScrollingByClick.current) {
      /**
       * If the user is scrolling by not clicking the scroll icon,
       * don't show the cue. Once the user stops scrolling, show the cue after
       * a delay.
       */
      clearCueTimeout();
      setIsScrolling(true);
      setIsReady(false);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      if (!elRef.current) {
        return;
      }

      const scrollHeight = elRef.current.scrollHeight ?? 0;
      const elHeight = elRef.current.clientHeight ?? 0;
      const currentScrollPos = elRef.current.scrollTop ?? 0;
      let actualScrollPos = currentScrollPos + elHeight;

      if (actualScrollPos > scrollHeight) {
        actualScrollPos = Math.floor(actualScrollPos);
      } else {
        actualScrollPos = Math.round(actualScrollPos);
      }

      if (scrollHeight > elHeight && actualScrollPos !== scrollHeight) {
        setHasMoreContent(true);
      } else {
        setHasMoreContent(false);
      }

      if (!isScrollingByClick.current) {
        setIsScrolling(false);
        showCueAfterDelay();
      }
    }, 100);
  }, [elRef]);

  const resizeEventHandler = useCallback(() => {
    if (resizeTimeoutRef.current) {
      clearTimeout(resizeTimeoutRef.current);
    }

    resizeInProgressRef.current = true;
    resizeTimeoutRef.current = setTimeout(() => {
      const scrollHeight = elRef.current?.scrollHeight ?? 0;
      const elHeight = elRef.current?.clientHeight ?? 0;

      if (scrollHeight > elHeight) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }

      resizeInProgressRef.current = false;
      scrollEventHandler();
    }, 100);
  }, [elRef, scrollEventHandler]);

  const scrollIconClickHandler = useCallback(() => {
    const el = elRef.current;

    if (!el) {
      return;
    }

    let scrollDistance = el.clientHeight / 4;

    /**
     * If the phone is in landscape mode, scroll a bit more.
     */
    if (scrollDistance < 200) {
      scrollDistance = el.clientHeight / 2;
    }

    isScrollingByClick.current = true;
    el.scrollBy({ behavior: 'smooth', top: scrollDistance });

    setTimeout(() => {
      isScrollingByClick.current = false;
    }, 500);
  }, [elRef]);

  useEffect(() => {
    resizeEventHandler();
  }, [resizeEventHandler, key]);

  /**
   * Adds resize event listener
   */
  useEffect(() => {
    window.addEventListener('resize', resizeEventHandler);

    return () => {
      window.removeEventListener('resize', resizeEventHandler);
    };
  }, [resizeEventHandler]);

  /**
   * Detects height change
   */
  useEffect(() => {
    const el = elRef.current;
    const boundingRect = el?.getBoundingClientRect();

    if (boundingRect) {
      const { height } = boundingRect;

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (height !== elHeightRef.current) {
        heightChangeInProgressRef.current = true;
        timeoutRef.current = setTimeout(() => {
          elHeightRef.current = height;
          heightChangeInProgressRef.current = false;
          resizeEventHandler();
        }, 100);
      }
    }
  });

  /**
   * Adds scroll event listener
   */
  useEffect(() => {
    const el = elRef.current;
    el?.addEventListener('scroll', scrollEventHandler);

    return () => {
      el?.removeEventListener('scroll', scrollEventHandler);
    };
  }, [elRef, scrollEventHandler]);

  /**
   * Readies the scroll cue to show up (if there is more content) after 3s
   */
  useEffect(() => {
    clearCueTimeout();
    showCueAfterDelay();

    return () => {
      clearCueTimeout();
    };
  }, []);

  function showCueAfterDelay() {
    isReadyTimeoutRef.current = setTimeout(() => {
      setIsReady(true);
    }, APPEARANCE_DELAY);
  }

  function clearCueTimeout() {
    if (isReadyTimeoutRef.current) {
      clearTimeout(isReadyTimeoutRef.current);
    }
  }

  return {
    elRef: internalElRef,
    hasScroll,
    ScrollCue,
    scrollCueProps: {
      isReady,
      hasMoreContent,
      onClickScrollIcon: scrollIconClickHandler,
      isScrolling,
    },
  };
};
