import { css } from '@emotion/core';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const mainContentContainerStyle = css`
  margin: 0;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
