import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const inputContainerStyle = css`
  display: flex;
  margin-top: ${theme.spacing(2)};

  & > *:not(:last-of-type) {
    margin-right: ${theme.spacing(3)};
  }
`;
