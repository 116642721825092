import Link from 'next/link';
import { useRouter } from 'next/router';

import { SecondaryButton } from '@weave/design-system';

import { containerStyle } from './welcome-page-content.styles';

export const WelcomePageContent = () => {
  const {
    query: { companyId },
  } = useRouter();

  return (
    <div>
      <div css={containerStyle}>
        <img src="/images/forms-kiosk.svg" alt="Kiosk Image" />
        <div className="intro-message">
          <p>
            Please select <b>New Patient</b> or <b>Existing Patient</b> to begin.
          </p>
        </div>

        <div className="buttons">
          <SecondaryButton size="tiny">
            <Link
              href={{
                pathname: `/kiosk/forms/${companyId}`,
                query: { patientType: 'new' },
              }}
            >
              New Patient
            </Link>
          </SecondaryButton>

          <SecondaryButton size="tiny">
            <Link
              href={{
                pathname: `/kiosk/forms/${companyId}`,
                query: { patientType: 'existing' },
              }}
            >
              Existing Patient
            </Link>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};
