import { LocaleStrings } from './strings.types';

export const FrenchTranslation: LocaleStrings = {
  FirstName: 'Prénom',
  LastName: 'Nom de famille',
  DoB: 'Date de naissance',
  City: 'Ville',
  Street1: 'Adresse 1',
  Street2: 'Adresse municipale 2',
  State: 'État',
  ZIP: 'Zip / code postal',
  Email: 'E-mail',
  Mobile: 'Téléphone portable',
  PreAdmissionTitle: `Formulaire d'inscription pré-admission`,
  PreAdmissionSubTitle: 'Remplissez les champs suivants (requis par HIPAA)',
  Begin: 'Commencer',
  PhoneHelperText: 'Indiquez le même numéro où vous recevez nos rappels',
  VerifyIdentity: `Vérifier l'identité`,
  VerifyIdentityNote:
    'Remplissez les champs suivants (requis par HIPAA) pour vérifier votre identité en toute sécurité',
  LanguageSelect: 'Choisir la langue',
  NewPatient: 'Nouveau patient',
  NewPatientNote: 'Remplissez les champs suivants (requis par HIPAA)',
};
