import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { tabletScreenMaxWidth } from '@forms-exp/constants';

export const formStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow: hidden;

  .dependent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dependent-name {
    margin: ${theme.spacing(0, 1)};
  }

  .form-title {
    margin: 0;
  }

  .section-title {
    margin-bottom: ${theme.spacing(3.75)};
  }
`;

export const headerDependentContainerStyle = (isSinglePageform: boolean) => css`
  display: flex;
  justify-content: space-between;

  ${isSinglePageform &&
  `
  padding-bottom: ${theme.spacing(2)};
  border-bottom: 2px solid ${theme.colors.neutral20};`}

  & > * {
    margin: 0;
  }
  .containter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
    gap: 3px;
  }

  .badge > span {
    display: flex;
    justify-content: space-between;
    min-width: 95px;
  }
`;

export const scrollContainerStyle = css`
  overflow: auto;
  padding: ${theme.spacing(0, 2, 2)};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const formContentContainerStyle = css`
  padding-top: ${theme.spacing(3)};
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h2 {
    padding-top: ${theme.spacing(1)};
  }
`;


const formFooterStyle = css`
  padding: ${theme.spacing(0, 2, 2)};

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: ${theme.spacing(0, 2)};
    max-width: 600px;

    & > button:not(:last-of-type) {
      margin-right: ${theme.spacing(2)};
    }

    @media screen and (max-width: ${tabletScreenMaxWidth}) {
      max-width: 100%;
      padding: 0;
      justify-content: space-between;
    }
  }
`;

interface IFormFooterStyleConfig {
  hasScroll: boolean;
}

export function getFormFooterStyle({ hasScroll }: IFormFooterStyleConfig) {
  const styles = [formFooterStyle];

  if (hasScroll) {
    styles.push(css`
      box-shadow: 0 -1px 10px 0 rgb(32 35 40 / 12%);
    `);
  }

  return styles;
}

export const primaryButtonStyle = css`
  margin-left: auto;
`;
