import axios from 'axios';

import { environmentVariables } from '@forms-exp/env';
import { PatronInfoFields } from '@forms-exp/types';

import { getISODate } from '../';

export interface PersonPMSDetails {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  birthdate: string;
  patientPmsId?: string;
  sourceTenantId?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}
interface SearchAPIResponse {
  success?: boolean;
  personDetails?: PersonPMSDetails;
  code?: number;
  message?: string;
}

interface SearchAPIResult {
  success: boolean;
  hasMultipleResults: boolean;
  hasSyncAppError: boolean;
  personPmsDetails: PersonPMSDetails | undefined;
}

export async function searchPatientRecord(
  {
    firstName,
    lastName,
    email,
    mobilePhone,
    birthdate,
    address1,
    address2,
    city,
    state,
    postalCode,
  }: PatronInfoFields,
  company_id: string
): Promise<SearchAPIResult> {
  try {
    const { data } = await axios.post<SearchAPIResponse>(
      `${environmentVariables.baseApiUrl}/forms-digital/v1/patient-search`,
      {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: mobilePhone,
        birthdate: getISODate(birthdate),
        company_id,
        city,
        state,
        street_address_1: address1,
        street_address_2: address2,
        zip_code: postalCode,
      }
    );
    const patientPmsId = data?.personDetails?.patientPmsId;
    const sourceTenantId = data?.personDetails?.sourceTenantId;
    var patronSearchDetails: PersonPMSDetails = {
      patientPmsId,
      sourceTenantId,
      firstName,
      lastName,
      email,
      mobilePhone,
      birthdate,
      address1,
      address2,
      city,
      state,
      postalCode,
    };
    return {
      success: !!data.success,
      personPmsDetails: patronSearchDetails,
      hasMultipleResults: false,
      hasSyncAppError: false,
    };
  } catch (e: any) {
    console.log('Error searching the patients: ', e.response);
    const errorResponse = e.response.data as SearchAPIResponse;

    return {
      success: false,
      personPmsDetails: undefined,
      hasMultipleResults:
        errorResponse.message?.includes('MULTIPLE_ENTITIES_FOUND') || false,
      hasSyncAppError: errorResponse.message?.includes('DISCONNECTED_SYNC_APP') || false,
    };
  }
}
