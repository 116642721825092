import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import {
  mobileScreenMaxWidth,
  tabletScreenMaxWidth,
  wideScreenMinWidth,
} from '@forms-exp/constants';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const countrycontainerStyle = css`
  justify-content: center;
  padding-top: ${theme.spacing(3)};
  & > div {
    margin: auto;
    max-width: 600px;
  }
  @media screen and (max-width: ${tabletScreenMaxWidth}) {
    padding-right: ${theme.spacing(2)};
  }
`;

export const scrollContainerStyle = css`
  flex-grow: 1;
  overflow: auto;
  padding: ${theme.spacing(0, 4, 3)};
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${mobileScreenMaxWidth}) {
    padding: ${theme.spacing(0, 2, 3)};
  }

  @media screen and (min-width: ${wideScreenMinWidth}) {
    padding: ${theme.spacing(0, 2, 3)};
  }
`;

export const contentContainerStyle = css`
  max-width: 100%;
  flex: 1;
`;

export const footerStyleOverride = css`
  box-shadow: 0 -1px 10px 0 rgb(32 35 40 / 12%);
`;
