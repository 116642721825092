import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  padding: ${theme.spacing(0, 2)};
`;

export const centerStyle = css`
  margin-left: auto;
  margin-right: auto;
`;

export const successImageStyle = css`
  width: 150px;
`;

export const pdfExpiredImageStyle = css`
  width: 190px;
`;

export const downloadPDFTextStyle = css`
  max-width: 260px;
`;

export const PDFSecurityTextStyle = css`
  max-width: 274px;
`;

export const expiredTextStyle = css`
  max-width: 355px;
`;
