import { FC, useState, useRef } from 'react';

import { EditIcon, PrimaryButton, SecondaryButton, Text } from '@weave/design-system';

import useStore from '@forms-exp/store';
import { FormTemplateData, FieldState } from '@forms-exp/types';

import FormElements from '../form-elements/form-elements.component';
import { tabContainerStyle, sectionContainerStyle } from './form-review.styles';

interface FormReviewProps {
  forms: FormTemplateData[];
}

const FormReview: FC<FormReviewProps> = ({ forms }) => {
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [editableSections, setEditableSections] = useState<string[]>([]);
  // Hash map to hold the backup state when enabling editing on a section.
  const stateBackup = useRef<Record<string, Record<string, FieldState>>>({});
  const { fields: fieldState, resetFields, validateSection } = useStore();

  function showFormsTab() {
    if (forms.length === 1) {
      return null;
    }

    return (
      <div css={tabContainerStyle}>
        {forms.map(({ form: { id, name } }, index) => (
          <Text
            key={id}
            className={`tab ${currentFormIndex === index && 'active'}`}
            as="span"
            onClick={() => setCurrentFormIndex(index)}
          >
            {name}
          </Text>
        ))}
      </div>
    );
  }

  /**
   * Method to toggle the edit state for a section.
   */
  function toggleEditableSection(sectionId: string) {
    const index = editableSections.indexOf(sectionId);

    if (index === -1) {
      setEditableSections((state) => [...state, sectionId]);
    } else {
      setEditableSections((state) => {
        const newState = [...state];
        newState.splice(index, 1);
        return newState;
      });
    }
  }

  /**
   * Method to start editing a section.
   */
  function handleEditSection(sectionId: string, fieldsInSection: string[]) {
    const currentSectionState: Record<string, FieldState> = {};

    for (const field of fieldsInSection) {
      currentSectionState[field] = { ...fieldState[field] };
    }

    // Backup the state for this section.
    stateBackup.current = {
      ...stateBackup.current,
      [sectionId]: currentSectionState,
    };

    toggleEditableSection(sectionId);
  }

  /**
   * Method to cancel the editing of a section.
   */
  function handleCancelEdit(sectionId: string) {
    const backup = stateBackup.current[sectionId];
    if (backup) {
      // Restore the section state if any backup is found.
      resetFields(backup);
    }

    toggleEditableSection(sectionId);
  }

  /**
   * Method to save the edits made in a section.
   */
  function handlerSaveEdit(sectionId: string, fieldsInSection: string[]) {
    if (validateSection(fieldsInSection)) {
      toggleEditableSection(sectionId);
    }
  }

  const currentForm = forms[currentFormIndex];
  const sectionsToDisplay = currentForm.form.sections;

  return (
    <>
      {showFormsTab()}

      {sectionsToDisplay.map((sectionId) => {
        const section = currentForm.sections[sectionId];
        const editable = editableSections.indexOf(sectionId) !== -1;

        return (
          <section key={sectionId} css={sectionContainerStyle}>
            <header>
              <h3>{section.title}</h3>
              {!editable && (
                <EditIcon
                  className="edit-section-button"
                  onClick={() => handleEditSection(sectionId, section.fields)}
                />
              )}
            </header>

            <FormElements
              form={currentForm}
              fieldsToShow={section.fields}
              reviewMode={!editable}
            />

            {editable && (
              <div className="edit-section-buttons">
                <SecondaryButton onClick={() => handleCancelEdit(sectionId)} size="tiny">
                  Cancel
                </SecondaryButton>

                <PrimaryButton
                  onClick={() => handlerSaveEdit(sectionId, section.fields)}
                  size="tiny"
                >
                  Save
                </PrimaryButton>
              </div>
            )}
          </section>
        );
      })}
    </>
  );
};

export default FormReview;
