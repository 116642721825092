import { FC } from 'react';

import { SerializedStyles } from '@emotion/core';

import { PrimaryButton, ButtonProps } from '@weave/design-system';

import {
  containerStyle,
  innerContainerStyle,
  primaryButtonStyle,
} from './kiosk-footer.styles';

type PrimaryButtonProps = Omit<ButtonProps, 'children'> & {
  type: 'button' | 'submit';
  size: 'tiny';
  css: SerializedStyles;
};

export interface KioskFooterProps {
  onContinue?: () => void;
  buttonType?: 'button' | 'submit';
}

const KioskFooter: FC<KioskFooterProps> = ({
  onContinue,
  buttonType = 'button',
  ...otherProps
}) => {
  function shouldHideFooter() {
    return !onContinue && buttonType !== 'submit';
  }

  function showContinueButton() {
    let buttonProps: PrimaryButtonProps = {
      type: buttonType,
      size: 'tiny',
      css: primaryButtonStyle,
    };

    if (buttonType !== 'submit') {
      buttonProps = {
        ...buttonProps,
        onClick: onContinue,
      };
    }

    return <PrimaryButton {...buttonProps}>Continue</PrimaryButton>;
  }

  return (
    <>
      {!shouldHideFooter() && (
        <footer css={containerStyle} {...otherProps}>
          <div css={innerContainerStyle}>{showContinueButton()}</div>
        </footer>
      )}
    </>
  );
};

export default KioskFooter;
