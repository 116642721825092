import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { mobileScreenMaxWidth, tabletScreenMaxWidth } from '@forms-exp/constants';

export const formStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const scrollContainerStyle = css`
  flex: 1;
  overflow: auto;
  padding: ${theme.spacing(0, 2)};
  padding-bottom: ${theme.spacing(2)};
`;

export const formContentContainerStyle = css`
  max-width: 600px;
  width: 100%;
  margin: ${theme.spacing(4)} auto;

  & > *:not(:last-of-type) {
    margin-bottom: ${theme.spacing(5)};
  }

  @media (max-width: ${mobileScreenMaxWidth}) {
    & > *:not(:last-of-type) {
      margin-bottom: ${theme.spacing(4)};
    }
  }
`;

const formFooterStyle = css`
  padding: ${theme.spacing(0, 2, 2)};
`;

interface FormFooterStyleConfig {
  hasScroll: boolean;
}

export function getFormFooterStyle({ hasScroll }: FormFooterStyleConfig) {
  if (hasScroll) {
    return [
      formFooterStyle,
      css`
        box-shadow: 0 -1px 10px 0 rgb(32 35 40 / 12%);
      `,
    ];
  }

  return formFooterStyle;
}

export const titleStyle = css`
  margin: 0;
`;

export const subTitleStyle = css`
  text-align: center;
  margin: ${theme.spacing(1, 0, 3)};
`;

export const buttonContainerStyle = css`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing(2)};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: ${theme.spacing(0, 2)};
  max-width: 600px;

  @media screen and (max-width: ${tabletScreenMaxWidth}) {
    max-width: 100%;
    padding: 0;
    justify-content: space-between;
  }
`;

export const primaryButtonStyle = css`
  @media screen and (max-width: ${tabletScreenMaxWidth}) {
    margin-left: auto;
  }
`;
