import { PostalCodeLocale } from '@weave/design-system';

export const countries = [
  {
    name: 'US',
    value: PostalCodeLocale.US,
  },
  {
    name: 'Canada',
    value: PostalCodeLocale.CA,
  },
];
