import { useState, useEffect, useRef } from 'react';
import { FormTemplateData } from '@forms-exp/types';
import useStore from '@forms-exp/store';
import { useStatesList } from '@forms-exp/hooks';

interface UseModifiedFormsProps {
  forms: FormTemplateData[];
}

interface UseModifiedFormsResults {
  modifiedForms: FormTemplateData[];
}

/**
 * Hook to modify the forms based on the postalCodeLocale.
 *
 * 1. Updates the options for the "select" field type with dataKey "state".
 */
export const useModifiedForms = ({
  forms,
}: UseModifiedFormsProps): UseModifiedFormsResults => {
  const [modifiedForms, setModifiedForms] = useState(forms);
  const postalCodeLocaleRef = useRef('');

  const { postalCodeLocale } = useStore();
  const { states, isFetched, isLoading } = useStatesList(postalCodeLocale);

  useEffect(() => {
    if (!isFetched || isLoading || postalCodeLocaleRef.current === postalCodeLocale) {
      return;
    }

    setModifiedForms((prevForms) => {
      /**
       * Update the options for the select field with dataKey 'state'
       * with the latest states list when the postalCodeLocale changes.
       */
      const updatedForms = prevForms.map((form) => {
        const fields = form.fields;

        if (fields) {
          for (const fieldId in fields) {
            const field = fields[fieldId];
            if (
              field.meta.type === 'select' &&
              (field.meta?.dataKey === 'state' || field.meta?.optionSet === 'states')
            ) {
              field.options = states.map((state) => ({
                ...state,
              }));
            }
          }
        }

        return form;
      });

      return updatedForms;
    });

    postalCodeLocaleRef.current = postalCodeLocale;
  }, [isFetched, isLoading, states, postalCodeLocale]);

  return {
    modifiedForms,
  };
};
