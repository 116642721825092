import axios from 'axios';

import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import { EnvironmentVariableProps } from '@forms-exp/types';
import { environmentVariables, getEnvProps } from '@forms-exp/env';

export interface CountryDropdownFlagProps extends EnvironmentVariableProps {
  isCountryDropdownEnabled: boolean;
}

/**
 * This function is to be used in the server side only.
 */
export async function setCountryDropdownFlagProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<CountryDropdownFlagProps>> {
  const {
    query: { companyId },
  } = context;

  const response = await getCountryDropdownFlag(companyId as string);

  return {
    props: {
      isCountryDropdownEnabled: response,
      ...getEnvProps(),
    },
  };
}

export async function getCountryDropdownFlag(companyId: string): Promise<boolean> {
  const url = `${environmentVariables.baseApiUrl}/forms-digital/v1/feature-flag`;

  try {
    const res = await axios.get<boolean>(url, {
      params: {
        company_id: companyId,
        feature_flag: 'forms-country-dropdown',
      },
    });
    return res.data;
  } catch (error) {
    console.error('Error while fetching country dropdown flag: ', error);
    return false;
  }
}
