import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  width: 100%;
  color: ${theme.colors.text.subdued};
  font-size: 0.875rem;
  line-height: 1.375;
  margin: 0 0 40px;
  text-align: center;

  .progress-bar,
  .progress-value {
    width: 100%;
    height: 4px;
    border-radius: 20px;
    display: block;
  }

  .progress-bar {
    background: ${theme.colors.neutral10};
    margin-top: 8px;
    position: relative;
  }

  .progress-value {
    background: ${theme.colors.secondary.seaweed60};
  }
`;
