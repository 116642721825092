import { useContext, createContext, FC } from 'react';

import { ModalControlResponse } from '@weave/design-system';

import usePDF, { UsePDFResults } from './usePDF';

interface PDFEditorProviderProps {
  fieldId: string;
  fullscreenModalControlProps: ModalControlResponse;
  children: React.ReactNode;
}

interface PDFEditorProviderContext extends UsePDFResults {}

const PDFEditorContext = createContext<PDFEditorProviderContext | undefined>(undefined);

/**
 * Component to provide the PDF editor context.
 */
export const PDFEditorProvider: FC<PDFEditorProviderProps> = ({
  fieldId,
  fullscreenModalControlProps,
  children,
}) => {
  const pdfProps = usePDF({ fieldId, fullscreenModalControlProps });

  const contextValue: PDFEditorProviderContext = {
    ...pdfProps,
  };

  return (
    <PDFEditorContext.Provider value={contextValue}>{children}</PDFEditorContext.Provider>
  );
};

export const usePDFEditorContext = (): PDFEditorProviderContext => {
  const context = useContext(PDFEditorContext);

  if (context === undefined) {
    throw new Error('usePDFEditorContext must be used within a <PDFEditorProvider />');
  }

  return context;
};
