import { FC, useRef, useState, useCallback, useEffect } from 'react';

import { Heading, TextField, Text } from '@weave/design-system';

import { LineCoordinates } from '@forms-exp/types';
import { getMouse, getTouch, redraw } from '@forms-exp/utils';
import useStore from '@forms-exp/store';

import { useSignaturePadContext } from '../context';

import { MAX_CANVAS_HEIGHT, MAX_CANVAS_WIDTH } from '../signature-pad';
import {
  getContainerStyle,
  getHeaderStyle,
  innerContainerStyle,
  canvasStyle,
} from './signature-canvas.styles';

/**
 * Component to render the signature canvas.
 */
const SignatureCanvas: FC = () => {
  const [canvasWidth, setCanvasWidth] = useState(MAX_CANVAS_WIDTH);
  const { canvasRef, canvasContextRef, setPaths, signedByTextFieldProps } =
    useSignaturePadContext();
  const [isDrawing, setIsDrawing] = useState(false);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasSizeChecked = useRef(false);

  const { pdfSignatures } = useStore();
  const hasSignatures = pdfSignatures.length > 0;

  const canvasContainerResizeHandler = useCallback(() => {
    const canvasContainer = canvasContainerRef.current!;
    const canvasContainerWidth = canvasContainer.getBoundingClientRect().width;

    if (canvasContainerWidth < MAX_CANVAS_WIDTH) {
      setCanvasWidth(canvasContainerWidth);
    }
  }, []);

  const startDrawingHandler = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const canvas = canvasRef.current!;
      setIsDrawing(true);

      if (event instanceof MouseEvent) {
        const mouse = getMouse(canvas, event);
        setPaths((prevPaths) => {
          return [...prevPaths, [mouse]];
        });
        return;
      }

      if (event instanceof TouchEvent) {
        const touch = getTouch(canvas, event);
        setPaths((prevPaths) => {
          return [...prevPaths, [touch]];
        });
        return;
      }
    },
    [canvasRef, setPaths]
  );

  const updatePath = useCallback(
    (point: LineCoordinates) => {
      const canvas = canvasRef.current!;
      const canvasContext = canvasContextRef.current!;

      setPaths((prevPaths) => {
        const lastPath = [...prevPaths[prevPaths.length - 1]];
        lastPath.push(point);
        const newPaths = [...prevPaths.slice(0, -1), lastPath];
        redraw(canvasContext, canvas, newPaths);
        return newPaths;
      });
    },
    [canvasRef, canvasContextRef, setPaths]
  );

  const drawHandler = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!isDrawing) {
        return;
      }

      const canvas = canvasRef.current;

      if (!canvas) {
        return;
      }

      if (event instanceof MouseEvent) {
        const mouse = getMouse(canvas, event);
        updatePath(mouse);
        return;
      }

      if (event instanceof TouchEvent) {
        const touch = getTouch(canvas, event);
        updatePath(touch);
        return;
      }
    },
    [isDrawing, updatePath, canvasRef]
  );

  const finishDrawingHandler = useCallback(() => {
    setIsDrawing(false);
  }, []);

  const removeEventListeners = useCallback(() => {
    const canvas = canvasRef.current;

    window.removeEventListener('resize', canvasContainerResizeHandler);

    if (!canvas) {
      return;
    }

    canvas.removeEventListener('mousedown', startDrawingHandler);
    canvas.removeEventListener('mousemove', drawHandler);
    canvas.removeEventListener('mouseup', finishDrawingHandler);

    canvas.removeEventListener('touchstart', startDrawingHandler);
    canvas.removeEventListener('touchmove', drawHandler);
    canvas.removeEventListener('touchend', finishDrawingHandler);
  }, [
    startDrawingHandler,
    drawHandler,
    finishDrawingHandler,
    canvasContainerResizeHandler,
    canvasRef,
  ]);

  const addEventListeners = useCallback(() => {
    const canvas = canvasRef.current!;

    window.addEventListener('resize', canvasContainerResizeHandler);

    // Track draw paths from mouse
    canvas.addEventListener('mousedown', startDrawingHandler);
    canvas.addEventListener('mousemove', drawHandler);
    canvas.addEventListener('mouseup', finishDrawingHandler);

    // Track draw paths from touch
    canvas.addEventListener('touchstart', startDrawingHandler);
    canvas.addEventListener('touchmove', drawHandler);
    canvas.addEventListener('touchend', finishDrawingHandler);

    return () => {
      removeEventListeners();
    };
  }, [
    startDrawingHandler,
    drawHandler,
    finishDrawingHandler,
    removeEventListeners,
    canvasContainerResizeHandler,
    canvasRef,
  ]);

  useEffect(() => {
    if (canvasSizeChecked.current) {
      return;
    }

    canvasContainerResizeHandler();
    canvasSizeChecked.current = true;
  }, [canvasContainerResizeHandler]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvasContextRef.current = canvas.getContext('2d');
    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, [addEventListeners, removeEventListeners, canvasRef, canvasContextRef]);

  return (
    <div css={getContainerStyle({ hasSignatures })}>
      <div css={innerContainerStyle} ref={canvasContainerRef}>
        <Heading level={3} css={getHeaderStyle({ hasSignatures })}>
          Create your Signature
        </Heading>

        <TextField
          {...signedByTextFieldProps}
          label="Enter your Full Name"
          name="signed-by"
        />

        <Text size="medium">Draw your signature here</Text>

        <canvas
          ref={canvasRef}
          css={canvasStyle}
          width={canvasWidth}
          height={MAX_CANVAS_HEIGHT}
        ></canvas>
      </div>
    </div>
  );
};

export default SignatureCanvas;
