import { FC } from 'react';

import { Text, Heading } from '@weave/design-system';

import {
  containerStyle,
  imageStyle,
  textContainerStyle,
  titleStyle,
  descriptionStyle,
} from './form-not-found.styles';
import Image from 'next/image';

interface FormNotFoundProps {
  notFound?: boolean;
  isSubmitted?: boolean;
  isExpired?: boolean;
  isEmptyForm?: boolean;
  officeName?: string;
}

export const FormNotFound: FC<FormNotFoundProps> = ({
  notFound = false,
  isSubmitted = false,
  isEmptyForm = false,
  isExpired,
  officeName = 'us',
}) => {
  if (isSubmitted) {
    return (
      <div css={containerStyle}>
        <Image
          src="/images/form-received.svg"
          alt="form received image"
          width={300}
          height={300}
          css={imageStyle}
        />

        <div css={textContainerStyle}>
          <Heading level={2} css={titleStyle}>
            Your form&apos;s trip is complete.
          </Heading>

          <Text css={descriptionStyle}>Thank you.</Text>
        </div>
      </div>
    );
  }

  if (notFound) {
    return (
      <div css={containerStyle}>
        <Image
          src="/images/form-does-not-exist.svg"
          alt="form does not exist image"
          width={300}
          height={300}
          css={imageStyle}
        />

        <div css={textContainerStyle}>
          <Heading level={2} css={titleStyle}>
            This form no longer exists.
          </Heading>

          <Text css={descriptionStyle}>
            If you need assistance, please reach out to {officeName}.
          </Text>
        </div>
      </div>
    );
  }

  if (isExpired) {
    return (
      <div css={containerStyle}>
        <Image
          src="/images/form-expired.svg"
          alt="form expired image"
          css={imageStyle}
          width={300}
          height={300}
        />

        <div css={textContainerStyle}>
          <Heading level={2} css={titleStyle}>
            This form is no longer available.
          </Heading>

          <Text css={descriptionStyle}>
            This form link is not valid anymore. If you believe this is an error, please
            get in touch with our office.
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div css={containerStyle}>
      <Image
        src="/images/form-unable-to-fetch.svg"
        alt="unable to fetch form image"
        css={imageStyle}
        width={300}
        height={300}
      />

      <div css={textContainerStyle}>
        <Heading level={2} css={titleStyle}>
          {isEmptyForm ? 'Unable to Access form' : "We can't fetch your form."}
        </Heading>

        <Text css={descriptionStyle}>
          {isEmptyForm
            ? `This form includes a payment section and cannot be accessed via public links. Please contact ${officeName} to request a unique link.`
            : 'We are unable to fetch them right now.'}
        </Text>
      </div>
    </div>
  );
};

export default FormNotFound;
