import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const countryDropdownContainerStyle = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${theme.spacing(2)};
`;

export const countryDropdownStyle = css`
  width: 120px;

  & > label,
  & > p {
    font-size: ${theme.fontSize(14)};
  }

  & > p {
    padding: 0 0 0 ${theme.spacing(2)};
  }

  & > svg {
    height: 45%;
    left: ${theme.spacing(1)};
  }
`;
