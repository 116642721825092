import { FC, useRef } from 'react';

import { containerStyle } from './scroll-cue.styles';

export interface ScrollCueProps {
  isReady: boolean;
  hasMoreContent: boolean;
  onClickScrollIcon: () => void;
  isScrolling: boolean;
}

const ScrollCue: FC<ScrollCueProps> = ({
  hasMoreContent,
  isReady,
  onClickScrollIcon,
  isScrolling,
}) => {
  const scrollCueElRef = useRef<HTMLDivElement>(null);

  return (
    <div id="scroll-cue" ref={scrollCueElRef}>
      <div
        css={containerStyle}
        style={{
          visibility: isReady && hasMoreContent && !isScrolling ? 'visible' : 'hidden',
        }}
        onClick={onClickScrollIcon}
      >
        <img src="/images/scroll-down-icon.gif" alt="Scroll down" />
      </div>
    </div>
  );
};

export default ScrollCue;
