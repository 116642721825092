import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing(2)};
  background-color: ${theme.colors.neutral5};
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
`;
