import axios from 'axios';

import { environmentVariables } from '@forms-exp/env';

export async function downloadFile(attachmentId: string) {
  const url = `${environmentVariables.baseApiUrl}/forms-digital/v1/media/${attachmentId}/download`;

  try {
    const { data } = await axios.get<{ data: string }>(url);

    return data.data ?? '';
  } catch (error) {
    console.error('Error downloading the attachment file: ', error);
    return '';
  }
}
