import { FC } from 'react';

import { IconButton, PlusIconSmall, MinusIconSmall, Text } from '@weave/design-system';

import { usePDFEditorContext } from '../context';

import { containerStyle, zoomContainerStyle, iconButtonStyle } from './pdf-header.styles';

const PDFHeader: FC = () => {
  const { totalPages, currentPage, pdfScaleLevel, zoomIn, zoomOut, getZoomLevel } =
    usePDFEditorContext();

  function shouldDisableZoomIn() {
    return pdfScaleLevel < 0.4;
  }

  function shouldDisableZoomOut() {
    return pdfScaleLevel >= 1.0;
  }

  return (
    <header css={containerStyle}>
      {totalPages > 0 && (
        <span>
          {currentPage} / {totalPages}
        </span>
      )}

      {/* <span css={zoomContainerStyle}>
        <IconButton
          label=""
          css={iconButtonStyle}
          disabled={shouldDisableZoomOut()}
          onClick={zoomOut}
        >
          <MinusIconSmall />
        </IconButton>

        <Text as="span" color="white">
          {getZoomLevel()}%
        </Text>

        <IconButton
          label=""
          css={iconButtonStyle}
          disabled={shouldDisableZoomIn()}
          onClick={zoomIn}
        >
          <PlusIconSmall />
        </IconButton>
      </span> */}
    </header>
  );
};

export default PDFHeader;
