import fileTypeChecker from 'file-type-checker';
import { supportedFileTypes } from '@/constants';

const MaxFileSize = 7 * 1024 * 1024; // 7MB

export interface AllowedFileTypeResponse {
  success: boolean;
  errorMessage?: string;
}

// Function to check the real file type from the metadata.
// This is to prevent users from uploading a file with a different extension than the actual file type.
export function isAllowedFileType(file: File): Promise<AllowedFileTypeResponse> {
  const promise = new Promise<AllowedFileTypeResponse>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const result = event.target?.result;
      const value = fileTypeChecker.detectFile(result as ArrayBuffer);

      if (!value) {
        resolve({
          success: false,
          errorMessage: 'Expected file type is image. Please try again.',
        });
        return;
      }

      if (!supportedFileTypes.includes(value?.mimeType)) {
        resolve({
          success: false,
          errorMessage: `You have uploaded invalid file. Allowed image types are ${supportedFileTypes.join(
            ', '
          )}`,
        });
        return;
      }
      resolve({ success: true });
    };

    reader.readAsArrayBuffer(file);
  });

  return promise;
}

export interface CheckFileSizeRepsonse {
  success: boolean;
  errorMessage?: string;
}

// Utility function to check the file size.
// This is to prevent users from uploading a file that is too large.
export function checkFileSize(file: File): CheckFileSizeRepsonse {
  if (file.size > MaxFileSize) {
    return {
      success: false,
      errorMessage: `Please upload an image less than ${MaxFileSize / 1024 / 1024}MB`,
    };
  }
  return { success: true };
}
