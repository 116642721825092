import { FC } from 'react';

import { SpinningLoader, SpinningLoaderProps } from '@weave/design-system';

import { containerStyle } from './centered-spinning-loader.style';

export const CenteredSpinningLoader: FC<SpinningLoaderProps> = (props) => (
  <div css={containerStyle}>
    <SpinningLoader {...props} />
  </div>
);
