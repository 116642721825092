import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { tabletScreenMaxWidth } from '@forms-exp/constants';

export const containerStyle = css`
  position: fixed;
  bottom: calc(72px + ${theme.spacing(4)}); // 72px for height of the footer
  right: 0;
  display: inline-flex;
  cursor: pointer;
  box-shadow: ${theme.shadows.floating};
  padding: ${theme.spacing(0.5, 1.5)};
  background-color: ${theme.colors.white};
  border-top-left-radius: ${theme.borderRadius.small};
  border-bottom-left-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.colors.neutral10};
  border-right: transparent;

  @media screen and (max-width: ${tabletScreenMaxWidth}) {
    img {
      width: 25px;
    }
  }
`;
