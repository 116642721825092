import { FC, useEffect } from 'react';

import { useEnvironmentVariablesContext } from '@forms-exp/env';
import { FORMS_ENV_PROP_KEY } from '@forms-exp/types';

interface EnvironmentVariablesInjectorProps {
  children: React.ReactNode;
  pageProps: any; // The props injected by Next.js
}

export const EnvironmentVariablesInjector: FC<EnvironmentVariablesInjectorProps> = ({
  children,
  pageProps,
}) => {
  const { updateEnvironmentVariables } = useEnvironmentVariablesContext();

  useEffect(() => {
    const formsEnv = pageProps[FORMS_ENV_PROP_KEY];

    if (formsEnv) {
      updateEnvironmentVariables(formsEnv);
    }
  }, [pageProps, updateEnvironmentVariables]);

  return <>{children}</>;
};
