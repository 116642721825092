import { useState, useEffect } from 'react';

import { fetchCompanyLogo } from '@forms-exp/helpers';
import { useEnvironmentVariablesContext } from '@forms-exp/env';

export const useCompanyLogo = (companyId: string, enabled: boolean = true) => {
  const [logoURL, setLogoURL] = useState('');
  const { isClientSideEnvReady } = useEnvironmentVariablesContext();

  useEffect(() => {
    if (!isClientSideEnvReady) {
      return;
    }

    if (enabled) {
      fetchCompanyLogo(companyId).then((url) => {
        setLogoURL(url);
      });
    }
  }, [companyId, enabled, isClientSideEnvReady]);

  return logoURL;
};
