import { format } from 'date-fns';
import { GetState, SetState } from 'zustand';

import {
  getError,
  initializeFieldState,
  PostalCodeLocale,
  setValue,
  trimIfString,
  validateField,
} from '@weave/design-system';

import {
  DataKey,
  MedicalHistory,
  MedicalHistoryDataKey,
  PatronInfoFields,
  PatronInfoFieldStates,
  Person,
  PrimaryInsuranceDataKey,
  SecondaryInsuranceDataKey,
} from '@forms-exp/types';

import { PersonPMSDetails } from '@forms-exp/helpers';
import { State } from '../useStore';

interface PatronInfoFieldConfigPayload {
  name: keyof PatronInfoFieldStates;
  config: {
    required?: boolean;
  };
}

interface PatronInfoFieldPayload {
  name: keyof PatronInfoFieldStates;
  value: any;
  disabled?: boolean;
}

export interface IPrePopulatedFields {
  firstName: boolean;
  lastName: boolean;
  birthdate: boolean;
  phoneNumber: boolean;
  city: boolean;
  email: boolean;
  gender: boolean;
  homePhone: boolean;
  preferredName: boolean;
  state: boolean;
  streetAddress1: boolean;
  workPhone: boolean;
  zipCode: boolean;
}

const primaryFieldKeys: Record<keyof Omit<Person, 'id'>, string> = {
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  phoneNumber: 'mobilePhone',
  birthdate: 'birthdate',
  city: 'city',
  driverLicense: 'driversLicenseNumber',
  email: 'email',
  gender: 'gender',
  maritalStatus: 'maritalStatus',
  homePhone: 'homePhone',
  preferredName: 'preferredName',
  state: 'state',
  streetAddress1: 'address1',
  workPhone: 'workPhone',
  zipCode: 'postalCode',
};

// we don't pre-populate SSN b'coz of security reasons
const insuranceKeys: Record<string, string> = {
  id: 'InsuranceId',
  groupNumber: 'GroupNumber',
  companyName: 'InsuranceName',
  state: 'InsuranceState',
  city: 'InsuranceCity',
  zipCode: 'InsurancePostalCode',
  streetAddress1: 'InsuranceAddress1',
  streetAddress2: 'InsuranceAddress2',
  spouseName: 'InsuranceSpouseName',
  patientRelationship: 'InsuranceRelationship',
  rxNumber: 'RxNumber',
  policyHolderName: 'PolicyHolderName',
};

export interface PatronInfoSlice {
  patronInfoFields: PatronInfoFieldStates;
  patronInfoReady: boolean;
  patronSearchDetails: PersonPMSDetails | undefined;
  prePopulatedFields: IPrePopulatedFields;
  prePopulateInsuranceFields: (
    elements: Record<string, string>,
    type: 'primary' | 'secondary'
  ) => void;
  prePopulateMedicalHistoryFields: (medicalHistory: MedicalHistory) => void;
  prePopulateElements: (elements: Record<string, string>) => void;
  updatePrePopulatedFieldNames: (keys: (keyof IPrePopulatedFields)[]) => void;
  updatePatronInfoField: (payload: PatronInfoFieldPayload) => void;
  updatePatronInfoFieldConfig: (payload: PatronInfoFieldConfigPayload) => void;
  updatePatronInfoFieldsConfig: (payload: PatronInfoFieldConfigPayload[]) => void;
  handlePatronFieldBlur: (fieldName: keyof PatronInfoFieldStates) => void;
  handlePatronFieldFocus: (fieldName: keyof PatronInfoFieldStates) => void;
  validatePatronInfoFields: (fields?: Array<keyof PatronInfoFieldStates>) => boolean;
  setPatronInfoReady: () => void;
  clearPatronInfo: () => void;
  getPatronInfoFieldValues: () => PatronInfoFields;
  prePopulatePatronInfoFields: (person: Person) => void;
  updatePatronSearchDetails: (patronSearchInfo: PersonPMSDetails) => void;
}

const prePopulatedFieldsInitialState: IPrePopulatedFields = {
  birthdate: false,
  firstName: false,
  lastName: false,
  phoneNumber: false,
  city: false,
  email: false,
  gender: false,
  homePhone: false,
  preferredName: false,
  state: false,
  streetAddress1: false,
  workPhone: false,
  zipCode: false,
};

const createPatronInfoSlice = (
  set: SetState<State>,
  get: GetState<State>
): PatronInfoSlice => ({
  patronInfoFields: initFieldStates(),
  patronSearchDetails: undefined,
  patronInfoReady: false,
  prePopulatedFields: prePopulatedFieldsInitialState,

  updatePatronSearchDetails: (patronSearchInfo: PersonPMSDetails) => {
    set((state) => ({
      ...state,
      patronSearchDetails: patronSearchInfo,
    }));
  },
  updatePrePopulatedFieldNames: (fields: (keyof IPrePopulatedFields)[]) => {
    set((state) => {
      const newPrePopulatedFields = { ...state.prePopulatedFields };
      fields.forEach((key) => {
        newPrePopulatedFields[key] = true;
      });
      return {
        ...state,
        prePopulatedFields: newPrePopulatedFields,
      };
    });
  },
  prePopulatePatronInfoFields: (person: Person) => {
    set((state) => {
      const newState = { ...state };

      Object.keys(person).forEach((key) => {
        const value = person[key as keyof Person];
        const fieldName = primaryFieldKeys[
          key as keyof Omit<Person, 'id'>
        ] as keyof PatronInfoFieldStates;

        if (value && fieldName) {
          //  set patron info fields
          newState.patronInfoFields[fieldName].value = value;
          newState.patronInfoFields[fieldName].touched = true;
          newState.patronInfoFields[fieldName].error = '';
          newState.patronInfoFields[fieldName].active = false;

          // set prepopulated fields
          newState.prePopulatedFields[key as keyof IPrePopulatedFields] = true;

          const primaryField = newState.primaryFields[fieldName];
          // set primary fields
          if (primaryField) {
            primaryField.value = value;
            primaryField.touched = true;
            primaryField.error = '';
            primaryField.active = false;

            newState.primaryFields[fieldName] = primaryField;
          }
        }
      });

      return newState;
    });
  },
  prePopulateMedicalHistoryFields: (medicalHistory: MedicalHistory) => {
    set((state) => {
      const newState = { ...state };

      Object.keys(medicalHistory).forEach((key) => {
        const field = newState.primaryFields[key as MedicalHistoryDataKey];
        const value = medicalHistory[key as MedicalHistoryDataKey];

        if (field) {
          field.value = value;
          field.touched = true;
          field.error = '';
          field.active = false;

          newState.primaryFields[key as MedicalHistoryDataKey] = field;
        }
      });

      return newState;
    });
  },
  prePopulateInsuranceFields: (
    elements: Record<string, string>,
    type: 'primary' | 'secondary'
  ) => {
    set((state) => {
      const newState = { ...state };

      Object.keys(elements).forEach((key) => {
        const insuranceKey = `${type}${insuranceKeys[key]}` as
          | PrimaryInsuranceDataKey
          | SecondaryInsuranceDataKey;
        const field = newState.primaryFields[insuranceKey];

        const value = elements[key];
        if (field) {
          field.value = value;
          field.touched = true;
          field.error = '';
          field.active = false;
        }
      });
      return newState;
    });
  },
  prePopulateElements: (elements: Record<string, string>) => {
    set((state) => {
      const newState = { ...state };

      Object.keys(elements).forEach((key) => {
        const dataKey = key as DataKey;
        const field = newState.fields[dataKey];

        const value = elements[key];

        if (field) {
          field.value = value;
          field.touched = true;
          field.error = '';
          field.active = false;
        }
      });
      return newState;
    });
  },
  updatePatronInfoField: ({ name, value }: PatronInfoFieldPayload) => {
    set((state) => {
      const fieldState = setValue(state.patronInfoFields[name], value);
      let newState = {
        ...state,
        patronInfoFields: {
          ...state.patronInfoFields,
          [name]: {
            ...fieldState,
            ...(fieldState.touched
              ? validateField(fieldState)
              : { error: getError(fieldState) }),
          },
        },
      };

      if (newState.primaryFields[name]) {
        // @ts-ignore
        const primaryFieldState = setValue(newState.primaryFields[name], value);
        newState = {
          ...newState,
          primaryFields: {
            ...newState.primaryFields,
            [name]: {
              ...primaryFieldState,
              ...(primaryFieldState.touched
                ? validateField(primaryFieldState)
                : { error: getError(primaryFieldState) }),
            },
          },
        };
      }

      return newState;
    });
  },
  updatePatronInfoFieldConfig: ({ name, config }: PatronInfoFieldConfigPayload) => {
    set((state) => {
      return {
        ...state,
        patronInfoFields: {
          ...state.patronInfoFields,
          [name]: {
            ...state.patronInfoFields[name],
            ...config,
          },
        },
      };
    });
  },
  updatePatronInfoFieldsConfig: (payload: PatronInfoFieldConfigPayload[]) => {
    set((state) => {
      let newPatronInfoFields = { ...state.patronInfoFields };

      for (const { name, config } of payload) {
        newPatronInfoFields = {
          ...newPatronInfoFields,
          [name]: {
            ...newPatronInfoFields[name],
            ...config,
          },
        };
      }

      return {
        ...state,
        patronInfoFields: newPatronInfoFields,
      };
    });
  },
  handlePatronFieldBlur: (fieldName: keyof PatronInfoFieldStates) => {
    set((state) => {
      const newFieldState = {
        ...state.patronInfoFields[fieldName],
        active: false,
        touched: true,
        value: trimIfString(state.patronInfoFields[fieldName].value),
      };

      let newState = {
        ...state,
        patronInfoFields: {
          ...state.patronInfoFields,
          [fieldName]: {
            ...newFieldState,
            ...validateField(newFieldState),
          },
        },
      };

      if (newState.primaryFields[fieldName]) {
        const newPrimaryFieldState = {
          ...newState.primaryFields[fieldName],
          active: false,
          touched: true,
          value: trimIfString(state.patronInfoFields[fieldName].value),
        };

        newState = {
          ...newState,
          [fieldName]: {
            ...newPrimaryFieldState,
            // @ts-ignore
            ...validateField(newPrimaryFieldState),
          },
        };
      }

      return newState;
    });
  },
  handlePatronFieldFocus: (fieldName: keyof PatronInfoFieldStates) => {
    set((state) => {
      let newState = {
        ...state,
        patronInfoFields: {
          ...state.patronInfoFields,
          [fieldName]: {
            ...state.patronInfoFields[fieldName],
            active: true,
          },
        },
      };

      if (newState.primaryFields[fieldName]) {
        newState = {
          ...newState,
          primaryFields: {
            ...newState.primaryFields,
            [fieldName]: {
              ...newState.primaryFields[fieldName],
              active: true,
            },
          },
        };
      }

      return newState;
    });
  },
  validatePatronInfoFields: (
    fieldNames?: Array<keyof PatronInfoFieldStates>
  ): boolean => {
    let isSectionValid = true;

    // fields not present in pre-registration form
    const nonPreRegFormField = [
      'gender',
      'homePhone',
      'preferredName',
      'workPhone',
      'middleName',
      'driversLicenseNumber',
      'maritalStatus',
    ];

    set((state) => {
      if (!fieldNames) {
        // Validate all fields if no field names are passed as arg.
        fieldNames = Object.keys(state.patronInfoFields) as Array<
          keyof PatronInfoFieldStates
        >;
      }

      let newState = {
        ...state,
      };

      for (const fieldName of fieldNames) {
        // don't process fields that are not in the pre-registration form
        if (!nonPreRegFormField.includes(fieldName)) {
          const field = newState.patronInfoFields[fieldName];
          const newFieldState = {
            ...field,
            touched: true,
          };

          newState = {
            ...newState,
            patronInfoFields: {
              ...newState.patronInfoFields,
              [fieldName]: {
                ...newFieldState,
                ...validateField(newFieldState),
              },
            },
          };

          if (newState.primaryFields[fieldName]) {
            const newPrimaryFieldState = {
              ...newState.primaryFields[fieldName],
              touched: true,
            };

            newState = {
              ...newState,
              primaryFields: {
                ...newState.primaryFields,
                [fieldName]: {
                  ...newPrimaryFieldState,
                  // @ts-ignore
                  ...validateField(newPrimaryFieldState),
                },
              },
            };
          }

          isSectionValid =
            isSectionValid && newState.patronInfoFields[fieldName].error === '';
        }
      }

      return newState;
    });

    return isSectionValid;
  },
  setPatronInfoReady: () => {
    set((state) => ({ ...state, patronInfoReady: true }));
  },
  clearPatronInfo: () => {
    set((state) => {
      return {
        ...state,
        patronInfoFields: initFieldStates({
          postalCodeLocale: state.postalCodeLocale,
        }),
        patronInfoReady: false,
      };
    });
  },
  getPatronInfoFieldValues: (): PatronInfoFields => {
    const patronInfoFields = get().patronInfoFields;
    const fieldAndValues: any = {};

    for (const fieldName in patronInfoFields) {
      fieldAndValues[fieldName] =
        patronInfoFields[fieldName as keyof PatronInfoFieldStates].value;
    }

    return fieldAndValues;
  },
});

export default createPatronInfoSlice;

interface FieldStateOptionalConfig {
  postalCodeLocale?: PostalCodeLocale;
}

function initFieldStates({
  postalCodeLocale,
}: FieldStateOptionalConfig = {}): PatronInfoFieldStates {
  const maxDate = format(new Date(), 'M/d/yyyy');

  return {
    firstName: initializeFieldState({ type: 'text', required: true }),
    middleName: initializeFieldState({ type: 'text', required: true }),
    lastName: initializeFieldState({ type: 'text', required: true }),
    birthdate: initializeFieldState({
      type: 'datePicker',
      required: true,
      minDate: '01/01/1900',
      maxDate,
    }),
    address1: initializeFieldState({ type: 'text', required: true }),
    address2: initializeFieldState({ type: 'text' }),
    city: initializeFieldState({ type: 'text', required: true }),
    state: initializeFieldState({ type: 'text', required: true }),
    maritalStatus: initializeFieldState({ type: 'radio', required: true }),
    postalCode: initializeFieldState({
      type: 'postalCode',
      required: true,
      locale: postalCodeLocale,
    }),
    email: initializeFieldState({ type: 'email', required: false }),
    mobilePhone: initializeFieldState({ type: 'phone', required: true }),
    gender: initializeFieldState({ type: 'radio', required: true }),
    homePhone: initializeFieldState({ type: 'phone', required: true }),
    preferredName: initializeFieldState({ type: 'text', required: true }),
    driversLicenseNumber: initializeFieldState({ type: 'text', required: true }),
    workPhone: initializeFieldState({ type: 'phone', required: true }),
  };
}
