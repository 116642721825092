import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { tabletScreenMaxWidth } from '@forms-exp/constants';

export const containerStyle = css`
  background-color: #fff;
  box-shadow: ${theme.shadows.light};
  position: sticky;
  bottom: 0;
`;

interface FooterStyleConfig {
  isCanvasShown: boolean;
}

const footerStyle = css`
  padding: ${theme.spacing(1)};
`;

export function getFooterStyle({ isCanvasShown }: FooterStyleConfig) {
  if (isCanvasShown) {
    return [footerStyle, css``];
  }

  return [
    footerStyle,
    css`
      background-color: ${theme.colors.neutral5};
    `,
  ];
}

export const buttonsContainerStyle = css`
  display: flex;
  flex-direction: row-reverse;
  gap: ${theme.spacing(1)};
  max-width: 350px;
  margin: auto;

  @media (min-width: ${tabletScreenMaxWidth}) {
    justify-content: center;
  }
`;

const secondaryButtonStyle = css`
  border: none !important;
`;

export const editSignatureButtonStyle = [
  secondaryButtonStyle,
  css`
    img {
      margin-right: ${theme.spacing(1)};
    }

    font-weight: 700;
    font-size: ${theme.fontSize(16)};
  `,
];

export const clearDrawingButtonStyle = [
  secondaryButtonStyle,
  css`
    color: ${theme.colors.primary50};

    &:disabled {
      color: ${theme.colors.neutral40};
    }
  `,
];

export const cancelButtonStyle = [
  secondaryButtonStyle,
  css`
    margin-right: -${theme.spacing(1)};
  `,
];
