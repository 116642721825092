export const analyticsTags = {
  acceptConditions: 'accept_conditions',
  autoFill: 'auto_fill',
  downloadPdf: 'download_pdf',
  manualFill: 'manual_fill',
  preAdmissionRegistration: 'preadmission_reg',
  submitForm: 'submit_form',
  verifyOTP: 'verify_otp',
  resentOTP: 'resent_otp',
};
