import { LocaleStrings } from './strings.types';

export const SpanishTranslation: LocaleStrings = {
  FirstName: 'Primer Nombre',
  LastName: 'Apellido',
  DoB: 'Fecha de nacimiento',
  City: 'Ciudad',
  Street1: 'Dirección Calle 1',
  Street2: 'Dirección de calle 2',
  State: 'Estado',
  ZIP: 'Código postal',
  Email: 'Correo electrónico',
  Mobile: 'Teléfono móvil',
  PreAdmissionTitle: 'Formulario de registro de preadmisión',
  PreAdmissionSubTitle: 'Complete los siguientes campos (requeridos por HIPAA)',
  Begin: 'Empezar',
  PhoneHelperText: 'Proporcione el mismo número donde recibe nuestros recordatorios',
  VerifyIdentity: 'Verificar identidad',
  VerifyIdentityNote:
    'Complete los siguientes campos (requeridos por HIPAA) para verificar su identidad de manera segura',
  LanguageSelect: 'Seleccione el idioma',
  NewPatient: 'Nueva paciente',
  NewPatientNote: 'Complete los siguientes campos (requeridos por HIPAA)',
};
