import { FC } from 'react';

import { containerStyle } from './company-logo.styles';

interface CompanyLogoProps {
  logoSrc: string;
}

export const CompanyLogo: FC<CompanyLogoProps> = ({ logoSrc }) => (
  <div css={containerStyle}>
    <img src={logoSrc} alt="Company Logo" />
  </div>
);
