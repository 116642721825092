import { FC } from 'react';

import { FullscreenModal, ModalControlResponse } from '@weave/design-system';

import { PDFEditorProvider } from './pdf-editor/context';
import PDFStatic from './pdf-static/pdf-static.component';
import PDFEditor from './pdf-editor/pdf-editor.component';

import { editableContainerStyle, bodyStyle } from './pdf-fullscreen.styles';

interface PDFFullscreenProps {
  fieldId: string;
  pdfUrl: string;
  isEditable?: boolean;
  modalControlProps: ModalControlResponse;
}

/**
 * Component to render the fullscreen PDF viewer modal.
 */
const PDFFullscreen: FC<PDFFullscreenProps> = ({
  fieldId,
  pdfUrl,
  isEditable = false,
  modalControlProps,
}) => {
  const { show } = modalControlProps.modalProps;

  return (
    <FullscreenModal
      {...modalControlProps.modalProps}
      css={isEditable && editableContainerStyle}
    >
      <FullscreenModal.Body css={bodyStyle}>
        {isEditable ? (
          <PDFEditorProvider
            fieldId={fieldId}
            fullscreenModalControlProps={modalControlProps}
          >
            <PDFEditor pdfUrl={pdfUrl} />
          </PDFEditorProvider>
        ) : (
          <PDFStatic pdfUrl={pdfUrl} show={show} fieldId={fieldId} />
        )}
      </FullscreenModal.Body>
    </FullscreenModal>
  );
};

export default PDFFullscreen;
