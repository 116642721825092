import { createContext, FC, useContext } from 'react';

import {
  UseEnvironmentVariablesResult,
  useEnvironmentVariables,
} from './useEnvironmentVariables';

interface EnvironmentVariablesProviderProps {
  children: React.ReactNode;
}

interface EnvironmentVariablesContext extends UseEnvironmentVariablesResult {}

const EnvironmentVariablesContext = createContext<
  EnvironmentVariablesContext | undefined
>(undefined);

export const EnvironmentVariablesProvider: FC<EnvironmentVariablesProviderProps> = ({
  children,
}) => {
  const useEnvironmentVariablesProps = useEnvironmentVariables();

  const contextValue: EnvironmentVariablesContext = {
    ...useEnvironmentVariablesProps,
  };

  return (
    <EnvironmentVariablesContext.Provider value={contextValue}>
      {children}
    </EnvironmentVariablesContext.Provider>
  );
};

export const useEnvironmentVariablesContext = (): EnvironmentVariablesContext => {
  const context = useContext(EnvironmentVariablesContext);

  if (context === undefined) {
    throw new Error(
      'useEnvironmentVariables must be used within a <EnvironmentVariablesProvider />.'
    );
  }

  return context;
};
