import { FC } from 'react';

import { css } from '@emotion/core';

import {
  Modal,
  ModalControlModalProps,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@weave/design-system';
import { theme } from '@weave/theme';

type AlertType = 'phone' | 'first_name' | 'last_name' | 'dob';

interface InfoChangeConfirmationProps {
  modalProps: ModalControlModalProps;
  type: AlertType;
  onConfirm: (type: AlertType) => void;
}

export const modalStyles = css`
  max-width: 350px;
  padding-bottom: ${theme.spacing(2)};
  padding-top: ${theme.spacing(2)};

  .actions {
    padding: ${theme.spacing(2, 3, 0)};
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: flex-end;

    button {
      padding: ${theme.spacing(1, 1)};
      width: fit-content;
    }
  }
`;

const valueChangeModalAlertContent = {
  phone: (
    <Text css={{ margin: 0 }} size="medium">
      Changing your phone number will update your contact information in our records.{' '}
      <strong>You will no longer receive our messages on this number.</strong>
      <br /> Are you sure you want to continue?
    </Text>
  ),
  first_name: (
    <Text css={{ margin: 0 }} size="medium">
      Are you sure you want to change your first name in our records?
    </Text>
  ),
  last_name: (
    <Text css={{ margin: 0 }} size="medium">
      Are you sure you want to change your last name in our records?
    </Text>
  ),
  dob: (
    <Text css={{ margin: 0 }} size="medium">
      Are you sure you want to change your date of birth in our records?
    </Text>
  ),
};

export const InfoChangeConfirmation: FC<InfoChangeConfirmationProps> = ({
  modalProps,
  type,
  onConfirm,
}) => {
  return (
    <Modal {...modalProps} css={modalStyles} disableCloseOnOverlayClick>
      <Modal.Body>{valueChangeModalAlertContent[type]}</Modal.Body>
      <div className="actions">
        <SecondaryButton
          type="button"
          onClick={() => {
            modalProps.onClose();
            onConfirm(type);
          }}
          size="tiny"
        >
          Yes, Change it
        </SecondaryButton>
        <PrimaryButton
          type="button"
          size="tiny"
          onClick={() => {
            modalProps.onClose();
          }}
        >
          Keep it the same
        </PrimaryButton>
      </div>
    </Modal>
  );
};
