import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import Script from 'next/script';

import { install } from 'resize-observer';
import { Global } from '@emotion/core';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ThemeProvider, AlertsProvider } from '@weave/design-system';

import { globalStyles } from '@forms-exp/styles';
import {
  EnglishTranslation,
  FrenchTranslation,
  SpanishTranslation,
} from '@forms-exp/i18n';
import { EnvironmentVariablesProvider } from '@forms-exp/env';
import { EnvironmentVariablesInjector } from '@forms-exp/components';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15 minutes
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: {
    en: {
      translation: { ...EnglishTranslation },
    },
    es: {
      translation: { ...SpanishTranslation },
    },
    fr: {
      translation: { ...FrenchTranslation },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default function App({ Component, pageProps }: AppProps) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // polyfill for ResizeObserver
    if (!window.ResizeObserver) {
      install();
    }

    setIsMounted(true);
  }, []);

  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-58X4WTM8FQ"
        async
      ></Script>

      <Script src="https://acrobatservices.adobe.com/view-sdk/viewer.js"></Script>

      <Script
        strategy="afterInteractive"
        id="googleTagInit"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-58X4WTM8FQ');
              `,
        }}
      ></Script>

      <Global styles={globalStyles} />

      <QueryClientProvider client={queryClient}>
        {isMounted && (
          <ThemeProvider includeEmotionTheme>
            {/* @ts-ignore */}
            <AlertsProvider>
              <EnvironmentVariablesProvider>
                <EnvironmentVariablesInjector pageProps={pageProps}>
                  <Component {...pageProps} />
                </EnvironmentVariablesInjector>
              </EnvironmentVariablesProvider>
            </AlertsProvider>
          </ThemeProvider>
        )}

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
