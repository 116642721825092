import { FC, FormEvent, useState, useCallback } from 'react';
import { useRouter } from 'next/router';

import { useTranslation, withTranslation } from 'react-i18next';
import i18n from 'i18next';

import { DropdownField, useControlledField, Text } from '@weave/design-system';

import useStore from '@forms-exp/store';
import { KioskLayout, WelcomeScreen, FooterLinks } from '@forms-exp/components';

import {
  usePatronForm,
  useLeavePageConfirm,
  useIdleKioskRedirection,
} from '@forms-exp/hooks';
import { localeList } from '@forms-exp/constants';
import { PatronInfoFieldStates } from '@forms-exp/types';

import {
  containerStyle,
  innerContainerStyle,
  descriptionStyle,
  formBodyStyle,
} from './patron-form.styles';

interface PatronFormProps {
  isCountryDropdownEnabled: boolean;
  headerTitle: string;
  headerSubTitle?: string;
  description?: string;
  patientType: 'new' | 'existing';
  onSubmit: () => void;
  showOnlyRequiredFields?: boolean;
}

const existingPatientFields: Array<keyof PatronInfoFieldStates> = [
  'firstName',
  'lastName',
  'birthdate',
  'mobilePhone',
];

const newPatientFields: Array<keyof PatronInfoFieldStates> = [
  'firstName',
  'lastName',
  'birthdate',
  'city',
  'address1',
  'address2',
  'state',
  'postalCode',
  'email',
  'mobilePhone',
];

const PatronFormComponent: FC<PatronFormProps> = ({
  headerTitle,
  headerSubTitle,
  description,
  showOnlyRequiredFields,
  patientType,
  onSubmit,
  isCountryDropdownEnabled,
}) => {
  const { validatePatronInfoFields, setPatronInfoReady, setHasUnsavedChanges } =
    useStore();
  useLeavePageConfirm();
  const { t } = useTranslation();
  const router = useRouter();
  const { companyId } = router.query as { companyId: string };
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);

  const [selectedLanguage, setLanguage] = useState(i18n.language);

  const lanaguageFieldProps = useControlledField({
    required: false,
    type: 'dropdown',
    value: selectedLanguage,
    onChange: (value) => {
      i18n.changeLanguage(value);
      setLanguage(value);
    },
  });

  const { IdleKioskRedirectionModal, idleTimerProps, redirectToKiosk } =
    useIdleKioskRedirection({ isInKioskMode: true, companyId });

  const inputFocusAndChangeHandler = useCallback(() => {
    idleTimerProps.resetCountdown();
  }, [idleTimerProps]);

  const { showField } = usePatronForm({
    onFocusInput: inputFocusAndChangeHandler,
    onChangeInput: inputFocusAndChangeHandler,
  });

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      validatePatronInfoFields(
        patientType === 'new' ? newPatientFields : existingPatientFields
      )
    ) {
      setHasUnsavedChanges(false);
      setPatronInfoReady();
      onSubmit();
    } else {
      idleTimerProps.resetCountdown();
    }
  }

  if (showWelcomeScreen) {
    return (
      <WelcomeScreen
        companyId={companyId}
        officeLogoSrc={''}
        officeName=""
        onAccept={() => setShowWelcomeScreen(false)}
      />
    );
  }

  return (
    <>
      <form
        css={containerStyle}
        noValidate={true}
        onSubmit={handleSubmit}
        onChange={() => setHasUnsavedChanges(true)}
      >
        <KioskLayout
          title={headerTitle}
          subTitle={headerSubTitle}
          buttonType="submit"
          isCountryDropdownEnabled={isCountryDropdownEnabled}
          companyId={companyId}
        >
          <div css={innerContainerStyle}>
            <Text css={descriptionStyle}>{description}</Text>

            <section css={formBodyStyle}>
              <DropdownField
                label={t('LanguageSelect')}
                {...lanaguageFieldProps}
                name="language"
              >
                {localeList.map(({ name, value }) => (
                  <DropdownField.Option key={value} value={value} searchValue={value}>
                    {name}
                  </DropdownField.Option>
                ))}
              </DropdownField>

              {showField('firstName', showOnlyRequiredFields)}
              {showField('lastName', showOnlyRequiredFields)}
              {showField('birthdate', showOnlyRequiredFields)}
              {showField('city', showOnlyRequiredFields)}
              {showField('address1', showOnlyRequiredFields)}
              {showField('address2', showOnlyRequiredFields)}
              {showField('state', showOnlyRequiredFields)}
              {showField('postalCode', showOnlyRequiredFields)}
              {showField('email', showOnlyRequiredFields)}
              {showField('mobilePhone', showOnlyRequiredFields)}
            </section>
          </div>

          {/* <FooterLinks companyId={companyId as string} /> */}
        </KioskLayout>
      </form>

      <IdleKioskRedirectionModal
        {...idleTimerProps}
        onCountDownElapse={redirectToKiosk}
      />
    </>
  );
};

export const PatronForm = withTranslation()(PatronFormComponent);
