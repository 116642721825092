import { FC } from 'react';

import {
  AlertInvertIcon,
  Heading,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@weave/design-system';

import { useCompanyLogo, useCompanyName } from '@forms-exp/hooks';
import { CompanyLogo } from '@forms-exp/components';

import { FooterLinks } from '..';

import { contentStyle, wrapperStyle } from './filling-option-screen.style';

interface FillingOptionScreenProps {
  officeLogoSrc: string;
  officeName: string;
  companyId: string;
  onAutoFill: () => void;
  onManualFill: () => void;
}
export const FillingOptionScreen: FC<FillingOptionScreenProps> = ({
  companyId,
  officeLogoSrc,
  officeName,
  onAutoFill,
  onManualFill,
}) => {
  const logo = useCompanyLogo(companyId, !officeLogoSrc);
  const companyName = useCompanyName(companyId, !officeName);

  return (
    <div css={wrapperStyle}>
      <div css={contentStyle}>
        {officeLogoSrc || logo ? (
          <CompanyLogo logoSrc={officeLogoSrc || logo} />
        ) : (
          <Heading level={1} textAlign="center">
            {officeName || companyName}
          </Heading>
        )}
        <Text textAlign="center">
          Save time by auto-filling your information from past form submissions, or fill
          out the form manually.
        </Text>

        <div className="note-wrapper">
          <AlertInvertIcon color="primary" />
          <Text color="light" size="small" className="note">
            Auto-fill requires you to verify your identity by obtaining a verification
            code at your registered email address or phone number. By selecting auto-fill,
            you consent to receiving a text message and email with your verification code.
            Message & data rates may apply.
          </Text>
        </div>
        <PrimaryButton className="auto-fill" onClick={onAutoFill}>
          Auto-fill Form
        </PrimaryButton>
        <SecondaryButton onClick={onManualFill}>Fill out Manually</SecondaryButton>

        <FooterLinks companyId={companyId} />
      </div>
    </div>
  );
};
