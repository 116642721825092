import { FC } from 'react';

import { ModalControlModalProps, Modal } from '@weave/design-system';

import { actionButtonContainerStyle } from './idle-time-modal.style';

interface IdleTimeModalProps {
  modalProps: ModalControlModalProps;
  modalTitle: string;
  modalMessage?: string;
  confirmActionLabel?: string;
  cancelActionLabel?: string;
  disableButtons?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const IdleTimeModal: FC<IdleTimeModalProps> = ({
  modalProps,
  modalTitle,
  modalMessage,
  confirmActionLabel = 'Confirm',
  cancelActionLabel = 'Cancel',
  disableButtons,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      {...modalProps}
      maxWidth={500}
      disableCloseOnEscape={true}
      disableCloseOnOverlayClick={true}
    >
      <Modal.Header>{modalTitle}</Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>{modalMessage}</Modal.Body>
      <Modal.Actions
        css={actionButtonContainerStyle}
        primaryLabel={confirmActionLabel}
        secondaryLabel={cancelActionLabel}
        onPrimaryClick={onConfirm}
        onSecondaryClick={onCancel}
        disablePrimary={disableButtons}
        disableSecondary={disableButtons}
      />
    </Modal>
  );
};

export default IdleTimeModal;
