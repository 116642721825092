import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { mobileScreenMaxWidth } from '@forms-exp/constants';

export const footerStyle = css`
  margin-top: ${theme.spacing(12)};

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      padding: ${theme.spacing(0, 2)};

      &.weave {
        color: ${theme.colors.text.subdued};
      }

      a {
        color: ${theme.colors.text.default};
      }

      &:not(:last-of-type) {
        border-right: 1px solid ${theme.colors.neutral20};
      }
    }
  }

  @media screen and (max-width: ${mobileScreenMaxWidth}) {
    margin-top: ${theme.spacing(6)};
    padding-bottom: ${theme.spacing(1)};

    ul {
      flex-direction: column;
      align-items: center;
      font-size: ${theme.font.size.small};

      li {
        &:not(:last-of-type) {
          border: none;
          margin-bottom: ${theme.spacing(0.5)};
        }
      }
    }
  }
`;

export const linkGroupStyle = css`
  display: flex;

  :not(:last-of-type) {
    margin-bottom: ${theme.spacing(1)};
  }

  li {
    padding: 0;
    margin: 0;

    &:first-of-type {
      padding-right: ${theme.spacing(1)};
    }

    &:not(:first-of-type) {
      padding-left: ${theme.spacing(1)};
      border-left: 1px solid ${theme.colors.neutral20};
    }
  }
`;

export const poweredByStyle = css`
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  .text {
    color: ${theme.colors.text.subdued};
  }

  a {
    color: ${theme.colors.text.default};
  }
`;
