import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DatePickerField,
  DropdownField,
  EmailField,
  normalizeChangeHandler,
  PhoneField,
  PostalCodeField,
  PostalCodeLocale,
  TextField,
} from '@weave/design-system';

import { useStatesList } from '@forms-exp/hooks';
import useStore from '@forms-exp/store';
import { getInputFieldStyle } from '@forms-exp/styles';
import { PatronInfoFieldStates } from '@forms-exp/types';

import { datePickerFieldStyle } from './patron-form.style';
import { getStateLabel, getZipCodeLabel } from '@/constants';

interface UsePatronFormProps {
  onBlurInput?: () => void;
  onFocusInput?: () => void;
  onChangeInput?: () => void;
}

export const usePatronForm = ({
  onBlurInput,
  onFocusInput,
  onChangeInput,
}: UsePatronFormProps = {}) => {
  const {
    patronInfoFields: fieldState,
    handlePatronFieldBlur,
    handlePatronFieldFocus,
    updatePatronInfoField,
    postalCodeLocale,
  } = useStore();
  const { states } = useStatesList(postalCodeLocale);
  const { t } = useTranslation();

  // Inspired by the design-system.
  const handlers = useMemo(
    () => ({
      onBlur: (event: FocusEvent) => {
        if (!event) {
          return;
        }

        handlePatronFieldBlur((event.target as any).name);

        if (onBlurInput) {
          onBlurInput();
        }
      },
      onFocus: (event: FocusEvent) => {
        if (!event) {
          return;
        }

        handlePatronFieldFocus((event.target as any).name);

        if (onFocusInput) {
          onFocusInput();
        }
      },
      onChange: (event: any) => {
        if (!event) {
          return;
        }

        // @ts-ignore
        normalizeChangeHandler(updatePatronInfoField)(event);

        if (onChangeInput) {
          onChangeInput();
        }
      },
    }),
    [
      handlePatronFieldBlur,
      handlePatronFieldFocus,
      updatePatronInfoField,
      onBlurInput,
      onFocusInput,
      onChangeInput,
    ]
  );

  // Inspired by the design-system.
  function getFieldProps(fieldName: keyof PatronInfoFieldStates) {
    const { type, validator, ...rest } = fieldState[fieldName] as any;

    return {
      id: fieldName,
      name: fieldName,
      ...rest,
      ...handlers,
    };
  }

  function getFieldLabel(translationKey: string, fieldProps: any) {
    return `${t(translationKey)} ${fieldProps.required ? '*' : ''}`.trim();
  }

  function showField(
    fieldName: keyof PatronInfoFieldStates,
    showOnlyRequiredFields: boolean = false
  ) {
    const fieldProps = getFieldProps(fieldName);
    const fieldStyle = getInputFieldStyle({
      hasError: fieldProps.error !== '',
      hasValue: fieldProps.value !== '',
    });
    const datePickerStyleOverride = [datePickerFieldStyle, fieldStyle];

    if (showOnlyRequiredFields && !fieldProps.required) {
      if (fieldName === 'address2') {
        // Hide "street address 2" if "street address 1" is also hidden.
        if (!getFieldProps('address1').required) {
          return null;
        }
      } else {
        return null;
      }
    }

    switch (fieldName) {
      case 'firstName':
        return (
          <TextField
            label={getFieldLabel('FirstName', fieldProps)}
            {...fieldProps}
            css={fieldStyle}
          />
        );

      case 'lastName':
        return (
          <TextField
            label={getFieldLabel('LastName', fieldProps)}
            {...fieldProps}
            css={fieldStyle}
          />
        );

      case 'birthdate':
        return (
          <DatePickerField
            css={datePickerStyleOverride}
            label={getFieldLabel('DoB', fieldProps)}
            {...fieldProps}
          />
        );

      case 'city':
        return (
          <TextField
            label={getFieldLabel('City', fieldProps)}
            {...fieldProps}
            css={fieldStyle}
          />
        );

      case 'address1':
        return (
          <TextField
            label={getFieldLabel('Street1', fieldProps)}
            {...fieldProps}
            css={fieldStyle}
          />
        );

      case 'address2':
        return (
          <TextField
            label={getFieldLabel('Street2', fieldProps)}
            {...fieldProps}
            css={fieldStyle}
          />
        );

      case 'state':
        return (
          <DropdownField
            label={getFieldLabel(getStateLabel(postalCodeLocale), fieldProps)}
            {...fieldProps}
            css={fieldStyle}
          >
            {states.map(({ label, value, abbr }) => (
              <DropdownField.Option key={abbr} value={value} searchValue={value}>
                {label}
              </DropdownField.Option>
            ))}
          </DropdownField>
        );

      case 'postalCode':
        return (
          <PostalCodeField
            label={getFieldLabel(getZipCodeLabel(postalCodeLocale), fieldProps)}
            {...fieldProps}
            css={fieldStyle}
          />
        );

      case 'email':
        return (
          <EmailField
            label={getFieldLabel('Email', fieldProps)}
            {...fieldProps}
            css={fieldStyle}
          />
        );

      case 'mobilePhone':
        return (
          <PhoneField
            label={getFieldLabel('Mobile', fieldProps)}
            {...fieldProps}
            helperText={t('PhoneHelperText')}
            css={fieldStyle}
          />
        );
    }
  }

  return { showField };
};
