import { FORM_LABEL_CHARACTER_LIMIT } from '@forms-exp/constants';

interface GetFormattedLabelParams {
  label: string;
  isRequired?: boolean;
  limit?: number;
  isPreview?: boolean;
}

/**
 * Returns the formatted label with some metadata.
 * If the label length exceeds the limit, the `outterLabel` property will be
 * populated with the formatted label, otherwise the `innerLabel` property will
 * contain that value.
 */
export function getFormattedLabel(params: GetFormattedLabelParams) {
  const {
    label,
    isRequired = false,
    limit = FORM_LABEL_CHARACTER_LIMIT.default,
    isPreview = false,
  } = params;
  let hasLongLabel = false;

  const formattedLabel = label ? `${label} ${isRequired ? '*' : ''}`.trim() : '';

  let outterLabel = '';
  let innerLabel = formattedLabel;

  if (formattedLabel.length > limit) {
    hasLongLabel = true;
    innerLabel = '';
    outterLabel = formattedLabel;
  }

  return {
    hasLongLabel,
    innerLabel,
    outterLabel,
  };
}
