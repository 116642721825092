import { FC } from 'react';
import { useRouter } from 'next/router';

import { Heading, Text, RTEditor } from '@weave/design-system';

import { LegalRecord } from '@forms-exp/types';

import { useCompanyLogo } from '@forms-exp/hooks';
import { CompanyLogo } from '@forms-exp/components';

import { legalContainerStyles, legalTextStyles } from './legal.styles';

interface LegalProps {
  records: LegalRecord[];
}

function isHTML(text: string) {
  const div = document.createElement('div');
  div.innerHTML = text;

  for (let c = div.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }

  return false;
}

// converts plain text to HTML
function getHTMLText(text: string) {
  if (!isHTML(text)) {
    const textarea = document.createElement('pre');
    textarea.innerHTML = text;

    return textarea.innerText.replace(/\n/g, '<br />');
  }

  return text;
}

export const Legal: FC<LegalProps> = ({ records }) => {
  const {
    query: { companyId },
    pathname,
  } = useRouter();
  const logo = useCompanyLogo(companyId as string);

  const isPrivacyPolicy = pathname.includes('privacy-policy');
  const headerName = isPrivacyPolicy ? 'Privacy Policy' : 'Terms & Conditions';

  const errorText = isPrivacyPolicy
    ? 'No privacy policy found'
    : 'No terms & conditions found';

  const recordIndex = isPrivacyPolicy
    ? records.findIndex((record) => record.type === 'Privacy Policy')
    : records.findIndex((record) => record.type === 'Terms and Conditions');

  return (
    <div css={legalContainerStyles}>
      {logo && <CompanyLogo logoSrc={logo} />}
      <Heading level={2} textAlign="center">
        {headerName}
      </Heading>

      <RTEditor
        css={legalTextStyles}
        key={records[recordIndex].id}
        initialHTML={getHTMLText(records[recordIndex]?.content || errorText)}
        readOnly
        uniqueId={records[recordIndex].id}
      >
        <RTEditor.Editor></RTEditor.Editor>
      </RTEditor>
    </div>
  );
};
