export const queryKeys = {
  states: 'states-list',
  kiosk: {
    forms: 'kiosk-forms',
    writebackCapabilities: 'wb-capabilities',
  },
  formsExp: {
    formData: 'form-exp-data',
  },
  legal: {
    docs: 'legal-docs',
  },
};
