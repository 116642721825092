import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const globalStyles = css`
  html,
  body {
    height: 100%;
    width: 100%;
    color: ${theme.colors.text.default};
  }

  html {
    overflow: hidden;
  }

  #__next {
    width: 100%;
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    color: ${theme.colors.text.subdued};
  }

  @media screen and (min-width: 1200px) {
    p {
      max-width: 700px;
    }
  }
`;
