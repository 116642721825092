import { FC } from 'react';
import { Text, Heading, BannerNotification } from '@weave/design-system';
import useStore from '@forms-exp/store';
import { CardOnFileFieldState } from '@forms-exp/types';
import { getCardBrandIcon, getCardBrandLabel } from '@forms-exp/utils';
import {
  containerStyle,
  imageContainerStyle,
  imageStyle,
  textStyle,
  brandTextStyle,
  bannerStyles,
} from './payment-card-preview.styles';

interface PaymentCardPreviewProps {
  fieldId: string;
  reviewMode: boolean;
}

const PaymentCardPreview: FC<PaymentCardPreviewProps> = ({ fieldId, reviewMode }) => {
  const { fields, previewMode } = useStore();
  const field = fields[fieldId] as CardOnFileFieldState;

  if (!field) {
    return <Text>Field not found!</Text>;
  }

  const { brand, lastFour, expiryYear, expiryMonth } = field.value;
  const brandIcon = getCardBrandIcon(brand);
  const heading = <Heading level={3}>{field.label}</Heading>;

  if (previewMode) {
    return (
      <>
        {heading}
        <Text>Card details will be displayed here.</Text>
      </>
    );
  }

  if (!lastFour) {
    return (
      <>
        {heading}
        <Text>No card added!</Text>
      </>
    );
  }

  return (
    <>
      {heading}
      <div css={containerStyle}>
        {brandIcon && (
          <div css={imageContainerStyle}>
            <img src={brandIcon} alt="Brand Icon" css={imageStyle} />
          </div>
        )}
        <div>
          <Text weight="bold" css={brandTextStyle}>
            {getCardBrandLabel(brand)}
          </Text>
          <Text css={textStyle}>
            <Text as="span" weight="bold">
              *{lastFour}
            </Text>{' '}
            &nbsp;
            <Text as="span">
              {expiryMonth}/{expiryYear}
            </Text>
          </Text>
        </div>
      </div>

      {!reviewMode && (
        <BannerNotification
          status="success"
          message="Your card details have been submitted successfully. Please proceed to fill and submit the rest of your form."
          css={bannerStyles}
        />
      )}
    </>
  );
};

export default PaymentCardPreview;
