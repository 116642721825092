import { FC, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Modal, ModalControlResponse } from '@weave/design-system';
import useStore from '@forms-exp/store';
import { useEnvironmentVariablesContext } from '@forms-exp/env';
import PaymentCard from './payment-card/payment-card.component';
import { stripeAppearance, modalStyle } from './payment-card.styles';

export interface PaymentCardModalCommonProps {
  fieldId: string;
  label: string;
  error: string;
}

interface PaymentCardModalProps extends PaymentCardModalCommonProps {
  modalControls: ModalControlResponse;
}

const PaymentCardModal: FC<PaymentCardModalProps> = ({
  fieldId,
  label,
  modalControls,
}) => {
  const { stripe, stripeAccountId } = useStore();
  const { environmentVariables } = useEnvironmentVariablesContext();

  const elementsOptions = useMemo<StripeElementsOptions>(() => {
    return {
      mode: 'setup',
      appearance: stripeAppearance,
      currency: 'usd',
      setupFutureUsage: 'off_session',
      paymentMethodConfiguration: environmentVariables.stripePaymentMethodConfig,
      on_behalf_of: stripeAccountId,
      paymentMethodOptions: {
        us_bank_account: { verification_method: 'instant' as const },
      },
    };
  }, [environmentVariables.stripePaymentMethodConfig]);

  return (
    <Modal
      {...modalControls.modalProps}
      css={modalStyle}
      disableCloseOnEscape
      disableCloseOnOverlayClick
    >
      <Modal.Header>{label}</Modal.Header>
      <Elements
        key={environmentVariables.stripeKey}
        stripe={stripe}
        options={elementsOptions}
      >
        <PaymentCard
          fieldId={fieldId}
          onCardSaved={modalControls.closeModal}
          onCancel={modalControls.closeModal}
        />
      </Elements>
    </Modal>
  );
};

export default PaymentCardModal;
