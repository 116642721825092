import { FC, useState, useEffect } from 'react';
import { css } from '@emotion/core';

import useStore from '@forms-exp/store';

import { containerStyle } from './progress-bar.styles';

interface FormProgressBarProps {
  steps: string[];
  currentStep: string;
}

const FormProgressBar: FC<FormProgressBarProps> = ({ steps, currentStep }) => {
  const [furthestStep, setFurthestStep] = useState(0);
  const { reviewedOnce } = useStore();

  useEffect(() => {
    const indexOfCurrentStep = steps.indexOf(currentStep);

    if (reviewedOnce && furthestStep < steps.length) {
      setFurthestStep(steps.length);
      return;
    }

    if (indexOfCurrentStep > furthestStep) {
      setFurthestStep(indexOfCurrentStep);
    }
  }, [currentStep, furthestStep, steps, reviewedOnce]);

  const progressWidth = (furthestStep / steps.length) * 100;

  return (
    <p css={containerStyle} className="progress-tracker">
      <strong>{steps.indexOf(currentStep) + 1}</strong>/{steps.length} Pages
      <span className="progress-bar">
        <span
          className="progress-value"
          css={css`
            width: ${progressWidth}% !important;
          `}
        ></span>
      </span>
    </p>
  );
};

export default FormProgressBar;
