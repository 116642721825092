export function millisToMinutesAndSeconds(millis: number) {
  const minutes = Math.floor(millis / 60000);
  const seconds = Number(((millis % 60000) / 1000).toFixed(0));

  // 2:60 should be 3:00
  if (seconds === 60) {
    return {
      minutes: minutes + 1 < 10 ? `0${minutes + 1}` : (minutes + 1).toString(),
      seconds: '00',
    };
  }

  return {
    minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
    seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
  };
}
