import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  align-items: baseline;
  margin-top: ${theme.spacing(1.5)};
  margin-bottom: ${theme.spacing(1.5)};
`;

export const imageStyle = css`
  margin-right: ${theme.spacing(1)};
  position: relative;
  top: 3px;
`;

const numberContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.neutral30};
  font-size: ${theme.fontSize(13)};
  min-width: 25px;

  .value {
    color: ${theme.colors.neutral50};
    font-size: ${theme.fontSize(20)};
  }
`;

export const minutesContainerStyle = [numberContainerStyle, css``];

export const separationStyle = css`
  color: ${theme.colors.neutral50};
  font-size: ${theme.fontSize(20)};
  margin: ${theme.spacing(0, 0.1)};
`;

export const secondsContainerStyle = [numberContainerStyle, css``];
