import { FC } from 'react';
import { useRouter } from 'next/router';

import { css } from '@emotion/core';

import { TextField, CheckboxField, Text } from '@weave/design-system';
import { theme } from '@weave/theme';

import { getFormattedLabel } from '@forms-exp/utils';
import { getInputFieldStyle } from '@forms-exp/styles';

interface SignatureFieldProps {
  inputProps: any;
  checkboxProps: any;
}

const SignatureField: FC<SignatureFieldProps> = ({ inputProps, checkboxProps }) => {
  const router = useRouter();
  const { innerLabel, outterLabel } = getFormattedLabel({
    label: inputProps.label,
    isRequired: inputProps.required,
    isPreview: router.pathname === '/form-preview',
  });

  const fieldStyle = getInputFieldStyle({
    hasError: inputProps.error !== '',
    hasValue: inputProps.value !== '',
  });

  const { innerLabel: innerLabelForCheckbox, outterLabel: outterLabelForCheckbox } =
    getFormattedLabel({
      label: checkboxProps.label,
      isRequired: checkboxProps.required,
      isPreview: router.pathname === '/form-preview',
    });

  return (
    <div>
      <Text weight="bold">{innerLabel || outterLabel}</Text>
      <TextField {...inputProps} label="" css={fieldStyle} />

      <CheckboxField
        css={css`
          margin-top: ${theme.spacing(2)};
          font-weight: ${theme.font.weight.bold};
        `}
        {...checkboxProps}
        label={innerLabelForCheckbox || outterLabelForCheckbox}
      />

      {checkboxProps.error && checkboxProps.touched && (
        <span
          css={css`
            color: ${theme.colors.status.critical};
            display: inline-block;
            font-size: 0.75rem;
            line-height: 1.33;
            padding-top: 8px;
          `}
        >
          Please agree to sign
        </span>
      )}
    </div>
  );
};

export default SignatureField;
