import axios from 'axios';

import { environmentVariables } from '@forms-exp/env';

function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const buff = Buffer.from(b64Data, 'base64');
  return new Blob([buff], { type: contentType });
}

export function getPDFObjectURL(b64Data: string) {
  const blob = b64toBlob(b64Data, 'application/pdf');
  return URL.createObjectURL(blob);
}

export const generatePDF = async (
  submissionToken: string,
  companyId: string
): Promise<{ success: boolean; url?: string }> => {
  const url = `${environmentVariables.baseApiUrl}/forms-digital/v1/pdf`;

  try {
    const { data } = await axios.get(url, {
      headers: { 'Content-Type': 'application/json' },
      params: {
        submission_id: submissionToken,
        company_id: companyId,
      },
    });

    if (!data.file) {
      return { success: false };
    }

    const fileURL = getPDFObjectURL(data.file);

    return { success: true, url: fileURL };
  } catch (err) {
    console.log('Error generating the PDF: ', err);
    return { success: false };
  }
};

export function base64ToPDFFile(base64: string, fileName: string) {
  const mimeType = 'application/pdf';
  const blob = b64toBlob(base64, mimeType);
  return new File([blob], fileName, { type: mimeType });
}
