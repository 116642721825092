export const mobileScreenMinWidth = '320px';
export const mobileScreenMaxWidth = '480px';

export const tabletScreenMinWidth = '481px';
export const tabletScreenMaxWidth = '768px';

export const laptopScreenMinWidth = '769px';
export const laptopScreenMaxWidth = '1024px';

export const desktopScreenMinWidth = '1025px';
export const desktopScreenMaxWidth = '1200px';

export const wideScreenMinWidth = '1201px';
