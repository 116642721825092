import { FC } from 'react';

import { BannerNotification, Heading, PrimaryButton, Text } from '@weave/design-system';

import { useCompanyLogo, useCompanyName } from '@forms-exp/hooks';
import { CompanyLogo } from '@forms-exp/components';

import { FooterLinks } from '..';

import { contentStyle, submitButtonStyle, wrapperStyle } from './welcome-screen.style';
import { analyticsTags } from '@/constants';

interface WelcomeScreenProps {
  officeLogoSrc: string;
  officeName: string;
  companyId: string;
  patientFirstName?: string;
  onAccept: () => void;
}

export const WelcomeScreen: FC<WelcomeScreenProps> = ({
  patientFirstName,
  companyId,
  officeLogoSrc,
  officeName,
  onAccept,
}) => {
  const logo = useCompanyLogo(companyId, !officeLogoSrc);
  const companyName = useCompanyName(companyId, !officeName);

  const onAcceptClick = () => {
    gtag('event', analyticsTags.acceptConditions);
    onAccept();
  };

  return (
    <div css={wrapperStyle}>
      <div css={contentStyle}>
        {officeLogoSrc || logo ? (
          <CompanyLogo logoSrc={officeLogoSrc || logo} />
        ) : (
          <Heading level={1} textAlign="center">
            {officeName || companyName}
          </Heading>
        )}

        <Heading level={2} textAlign="center" className="greeting">
          Hello{patientFirstName && `, ${patientFirstName}`}!
        </Heading>

        <Text textAlign="center" size="medium">
          {patientFirstName
            ? `Thank you for choosing ${
                officeName || companyName || 'us'
              }. Please take a moment to verify and fill out the required information.`
            : `Thank you for choosing ${
                officeName || companyName || 'us'
              }. Please take a moment to fill out the required information.`}
        </Text>

        <BannerNotification
          status="warn"
          message={`Please only complete this form on a trusted device. If you're using a
            public device, please use caution when entering sensitive or personal
            information. Always close your browser window and permanently delete any file
            downloads when finished.`}
        />

        <PrimaryButton
          data-testid="accept-continue"
          size="tiny"
          onClick={onAcceptClick}
          css={submitButtonStyle}
        >
          Accept &amp; Continue
        </PrimaryButton>
      </div>

      <FooterLinks companyId={companyId} />
    </div>
  );
};
