import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const canvasStyle = css`
  cursor: pointer;
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.colors.neutral20};

  &:first-of-type {
    margin-left: auto;
  }

  &:last-of-type {
    margin-right: auto;
  }

  &:not(:last-of-type) {
    margin-right: ${theme.spacing(2)};
  }

  &:hover {
    box-shadow: ${theme.shadows.floating};
  }
`;
