import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  background-color: ${theme.colors.neutral80};
  padding: ${theme.spacing(2)};
  position: sticky;
  top: 0;
  color: ${theme.colors.white};
  font-weight: ${theme.font.weight.bold};
  box-shadow: ${theme.shadows.light};
  z-index: 1;
  display: flex;
  align-items: center;
`;

export const zoomContainerStyle = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.white};
  padding-left: ${theme.spacing(1)};
  margin-left: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
`;

export const iconButtonStyle = css`
  color: ${theme.colors.white};

  &:hover {
    color: initial;
  }
`;
