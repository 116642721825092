export interface PacketForm {
  id: string;
  dateTime: string;
  companyId: string;
  name: string;
}

export interface Packet {
  dateTime: string;
  companyId: string;
  id: string;
  name: string;
  status: string;
  formCount: number;
  forms: PacketForm[];
}

export enum FormVisibility {
  GLOBAL = 'Global',
  TRANSIENT = 'Transient',
}

export interface Form {
  id: string;
  name: string;
  dateTime: string;
  visibility: FormVisibility;
  formPmsId?: string;
}
