import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { mobileScreenMaxWidth, wideScreenMinWidth } from '@forms-exp/constants';

export const containerStyle = css`
  padding: ${theme.spacing(2, 4)};

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(1)};

    .logo {
      img {
        max-height: 85px;
        cursor: pointer;
      }
    }

    .text {
      .title,
      .sub-title {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: ${mobileScreenMaxWidth}) {
    padding: ${theme.spacing(2, 2, 2)};

    .wrapper {
      .logo {
        max-height: 85px;
      }
    }
  }

  @media screen and (min-width: ${wideScreenMinWidth}) {
    .wrapper {
      max-width: ${wideScreenMinWidth};
      margin: auto;
    }
  }
`;
