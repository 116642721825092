import { FC, useRef, useEffect } from 'react';

import { Text } from '@weave/design-system';

import { redraw } from '@forms-exp/utils';
import { SaveSignatureResults } from '@forms-exp/types';

import { containerStyle, canvasStyle } from './signature-preview.styles';

interface SignaturePreviewProps {
  signatureResults: SaveSignatureResults;
  onSelectSignature: (signatureResults: SaveSignatureResults) => void;
}

const SignaturePreview: FC<SignaturePreviewProps> = ({
  signatureResults,
  onSelectSignature,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const canvasContext = canvas.getContext('2d')!;

    if (!canvasContext || !canvas) {
      return;
    }

    canvas.width = signatureResults.dimensions.width;
    canvas.height = signatureResults.dimensions.height;
    redraw(canvasContext, canvas, signatureResults.trimmedPaths);
  }, [signatureResults]);

  function selectSignatureHandler() {
    onSelectSignature(signatureResults);
  }

  return (
    <div css={containerStyle}>
      <canvas ref={canvasRef} onClick={selectSignatureHandler} css={canvasStyle}></canvas>

      <Text size="small">{signatureResults.signedBy}</Text>
    </div>
  );
};

export default SignaturePreview;
