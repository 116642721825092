import { FC, useEffect, useCallback, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import {
  Text,
  SpinningLoader,
  SecondaryButton,
  PrimaryButton,
  useAlert,
  Heading,
} from '@weave/design-system';

import { FooterLinks } from '@forms-exp/components';
import { generatePDF } from '@forms-exp/helpers';
import { useCountdownTimer } from '@forms-exp/hooks';

import {
  containerStyle,
  centerStyle,
  successImageStyle,
  downloadPDFTextStyle,
  PDFSecurityTextStyle,
  pdfExpiredImageStyle,
  expiredTextStyle,
} from './submission-confirmation.styles';
import { analyticsTags } from '@/constants';

export type SubmissionTypes = 'kiosk' | 'preview' | undefined;

interface SubmissionConfirmationProps {
  mode: SubmissionTypes;
  submissionId?: string;
  companyId: string;
}

const SubmissionConfirmation: FC<SubmissionConfirmationProps> = ({
  mode,
  submissionId,
  companyId,
}) => {
  const kioskTimeout = useRef<NodeJS.Timeout | undefined>();
  const { CountdownTimer, isCountdownComplete, minutesCountdown, secondsCountdown } =
    useCountdownTimer({
      timeToElapse: 120,
    });
  const [pdfGenerationInProgress, setPDFGenerationInProgress] = useState(false);
  const alert = useAlert();
  const router = useRouter();
  const {
    query: { kiosk },
  } = router;

  let content = <></>;

  const successImage = (
    <img
      src="/images/submission/success.svg"
      alt="Successful submission"
      css={successImageStyle}
    />
  );

  const successContent = (
    <>
      {successImage}

      <Heading level={2} textAlign="center">
        You&apos;ve submitted all the forms successfully
      </Heading>

      <Text css={downloadPDFTextStyle}>
        You can download a PDF of the forms that you submitted just now.
      </Text>

      <Text css={PDFSecurityTextStyle}>
        To protect your privacy, this download option will time out in 2 minutes.
      </Text>
    </>
  );

  const closeKioskHandler = useCallback(() => {
    if (kioskTimeout.current) {
      clearTimeout(kioskTimeout.current);
    }

    if (kiosk) {
      router.replace(`/kiosk/${kiosk}`);
    }
  }, [kiosk, router]);

  useEffect(() => {
    if (kiosk) {
      kioskTimeout.current = setTimeout(closeKioskHandler, 2000);
    }
  }, [kiosk, closeKioskHandler]);

  async function generatePDFHandler() {
    gtag('event', analyticsTags.downloadPdf);
    if (pdfGenerationInProgress) {
      return;
    }

    if (submissionId) {
      setPDFGenerationInProgress(true);
      const { success, url } = await generatePDF(submissionId, companyId);
      setPDFGenerationInProgress(false);

      if (!success) {
        alert.error('Failed to generate the PDF!');
        return;
      }

      const pdfWindow = window.open(url, '_self');

      if (pdfWindow) {
        const interval = setInterval(() => {
          if (pdfWindow.closed) {
            URL.revokeObjectURL(url as string);
            clearInterval(interval);
          }
        }, 500);
      }
    }
  }

  switch (mode) {
    case 'kiosk':
      content = (
        <>
          {successImage}
          <Heading level={2} textAlign="center">
            Your form has been submitted successfully!
          </Heading>
          <Text css={centerStyle}>
            Please contact the business if you have any questions.
          </Text>
          <SecondaryButton onClick={closeKioskHandler} css={centerStyle} size="tiny">
            Close
          </SecondaryButton>
        </>
      );
      break;

    case 'preview':
      content = successContent;
      break;

    default:
      if (isCountdownComplete) {
        content = (
          <>
            <img
              src="/images/submission/pdf-expired.svg"
              alt="PDF expired"
              css={pdfExpiredImageStyle}
            />

            <Heading level={2} textAlign="center" css={expiredTextStyle}>
              Download time for your submission has expired{' '}
            </Heading>

            <Text css={expiredTextStyle}>
              Unfortunately, we cannot allow downloads beyond the specified time limit to
              protect your privacy
            </Text>
          </>
        );
      } else {
        content = (
          <>
            {successContent}

            <CountdownTimer minutes={minutesCountdown} seconds={secondsCountdown} />

            {pdfGenerationInProgress ? (
              <div style={{ textAlign: 'center' }}>
                <SpinningLoader />
              </div>
            ) : (
              <PrimaryButton
                data-testid="download-pdf"
                css={centerStyle}
                onClick={generatePDFHandler}
                size="tiny"
              >
                Download Now
              </PrimaryButton>
            )}
          </>
        );
      }
      break;
  }

  return (
    <section css={containerStyle}>
      {content}
      <FooterLinks companyId={companyId} />
    </section>
  );
};

export default SubmissionConfirmation;
