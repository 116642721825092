/**
 * Function to convert the date from "MM/DD/yyyy" format
 * to "yyyy-MM-DD hh:mm:ss" format.
 */
export function getISODate(mmddyyyy: string) {
  if (!mmddyyyy || mmddyyyy.trim() === '') {
    return '';
  }

  const splits = mmddyyyy.split('/');
  return `${splits[2]}-${splits[0]}-${splits[1]} 00:00:00`;
}

export function getTodayISODate() {
  const today = new Date();
  const isoDateFormat = today.toISOString().split('T')[0];
  return isoDateFormat;
}
