import { tabletScreenMaxWidth } from '@/constants';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const containerStyle = css`
  height: 100%;
`;

export const innerContainerStyle = css`
  padding-top: ${theme.spacing(1)};
  max-width: 600px;
  margin: auto;
`;

export const descriptionStyle = css`
  color: ${theme.colors.text.subdued};
`;

export const formBodyStyle = css`
  margin-top: ${theme.spacing(4)};

  & > *:not(:last-of-type) {
    margin-bottom: ${theme.spacing(5)};
  }
`;
