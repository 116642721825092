import { FC, useEffect, useRef } from 'react';

import { UseIdleTimerResults } from '@forms-exp/hooks';

interface IdleKioskRedirectionModalProps extends UseIdleTimerResults {
  onCountDownElapse: () => void;
}

const IdleKioskRedirectionModal: FC<IdleKioskRedirectionModalProps> = ({
  onCountDownElapse,
  ...idleTimerProps
}) => {
  const originalPageTitleRef = useRef('');
  const {
    thresholdReached,
    IdleTimeModal,
    modalProps,
    thresholdCountdown,
    resetCountdown,
    openModal,
  } = idleTimerProps;

  useEffect(() => {
    originalPageTitleRef.current = document.title;
  }, []);

  useEffect(() => {
    if (thresholdReached) {
      openModal();
    }
  }, [thresholdReached, openModal]);

  useEffect(() => {
    if (thresholdReached) {
      document.title = `Redirecting in ${thresholdCountdown} seconds`;
    } else {
      document.title = originalPageTitleRef.current;
    }
  }, [thresholdReached, thresholdCountdown]);

  return (
    <IdleTimeModal
      modalProps={modalProps}
      modalTitle={`Redirecting to Kiosk in ${thresholdCountdown} seconds`}
      modalMessage="Do you want to extend this session or return to Kiosk?"
      confirmActionLabel="Yes: extend my session"
      cancelActionLabel="No: return to Kiosk"
      onConfirm={resetCountdown}
      onCancel={onCountDownElapse}
      disableButtons={thresholdCountdown < 1}
    />
  );
};

export default IdleKioskRedirectionModal;
