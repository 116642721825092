import { useRouter } from 'next/router';

import { useIdleTimer, UseIdleTimerResults } from '@forms-exp/hooks';
import useStore from '@forms-exp/store';

import IdleKioskRedirectionModal from './idle-kiosk-redirection-modal.component';

interface UseIdleKioskRedirectionProps {
  isInKioskMode: boolean;
  companyId?: string;
}

interface UseIdleKioskRedirectionResults {
  redirectToKiosk: () => void;
  IdleKioskRedirectionModal: typeof IdleKioskRedirectionModal;
  idleTimerProps: UseIdleTimerResults;
}

export const useIdleKioskRedirection = ({
  isInKioskMode,
  companyId,
}: UseIdleKioskRedirectionProps): UseIdleKioskRedirectionResults => {
  const router = useRouter();
  const { setHasUnsavedChanges } = useStore();
  const idleTimerProps = useIdleTimer({
    ignoreTimer: !isInKioskMode,
    onCountdownElapse: redirectToKiosk,
  });

  function redirectToKiosk() {
    if (companyId) {
      setHasUnsavedChanges(false);
      router.replace(`/kiosk/${companyId}`);
    }
  }

  return {
    redirectToKiosk,
    IdleKioskRedirectionModal,
    idleTimerProps,
  };
};
