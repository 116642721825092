import { ChangeEvent, FC, MutableRefObject, useRef, useState } from 'react';
import Image from 'next/image';

import { format } from 'date-fns';

import {
  PrimaryButton,
  QuickActionIconSpark,
  Text,
  TextButton,
  useAlert,
} from '@weave/design-system';

import useStore from '@forms-exp/store';

import { SignatureHistoryCarouselComponent } from './signature-history-carousel.component';

import {
  clearSignaturesButtonWrapper,
  imageUploadWrapperStyle,
  wetSignaturesButtonsStyle,
} from './e-signature-field.style';
import { checkFileSize, isAllowedFileType } from '@/utils';

interface UploadSignatureProps {
  id: string;
  onDone: () => void;
}

const UploadSignature: FC<UploadSignatureProps> = ({ id, onDone }) => {
  const fileUploaderRef = useRef() as MutableRefObject<HTMLInputElement>;
  const uploadedImage = useRef<File | undefined>();
  const [uploadedImagePreview, setUploadedImagePreview] = useState<string | undefined>();
  const { updateEsignFieldValue } = useStore();
  const alert = useAlert();

  const onImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      isAllowedFileType(file).then((isAllowedType) => {
        if (!isAllowedType.success) {
          alert.error(`${isAllowedType.errorMessage}`);
          removeObject();
          return;
        }
        if (!checkFileSize(file).success) {
          alert.error(`${checkFileSize(file).errorMessage}`);
          removeObject();
          return;
        }

        uploadedImage.current = file;
        setUploadedImagePreview(URL.createObjectURL(file));
      });
    }
  };

  const removeObject = () => {
    setUploadedImagePreview(undefined);
    uploadedImage.current = undefined;
    fileUploaderRef.current.value = '';
  };

  const openFileUploader = () => {
    fileUploaderRef.current.click();
  };

  const onImageSelected = () => {
    if (uploadedImage.current) {
      updateEsignFieldValue({
        name: id,
        value: {
          type: 'image',
          data: uploadedImage.current,
          font_type: '',
          timestamp: format(new Date(), 'MMMM d, yyyy hh:mm:ss'),
        },
        isNewSignature: true,
      });
      onDone();
    }
  };

  return (
    <>
      <input
        ref={fileUploaderRef}
        accept="image/*"
        type="file"
        id={id}
        hidden
        onChange={onImageUpload}
      />
      {!uploadedImagePreview ? (
        <div css={imageUploadWrapperStyle} onClick={openFileUploader}>
          <QuickActionIconSpark className="add-signature-image" size={40} />
          <Text color="light">Upload an image of your signature</Text>
        </div>
      ) : (
        <div>
          <Image
            src={uploadedImagePreview}
            alt="Uploaded image preview"
            width="150"
            height="100"
          />
        </div>
      )}
      <SignatureHistoryCarouselComponent id={id} onDone={onDone} />
      {uploadedImagePreview && (
        <div css={clearSignaturesButtonWrapper}>
          <TextButton className="clear-wet-sign" onClick={openFileUploader}>
            Replace Image
          </TextButton>
        </div>
      )}
      <div css={wetSignaturesButtonsStyle}>
        <PrimaryButton onClick={onImageSelected} size="tiny">
          eSign
        </PrimaryButton>
      </div>
    </>
  );
};

export default UploadSignature;
