import { FC, useEffect, useState } from 'react';
import Image from 'next/image';

import { Text } from '@weave/design-system';

import { ElectronicSignatureValue } from '@forms-exp/types';

import { fontStyles } from './e-signature-field.style';

export const DisplaySignature: FC<{
  value: ElectronicSignatureValue;
  reviewMode?: boolean;
  usage?: 'reuse' | 'display';
}> = ({ value, reviewMode = false, usage = 'display' }) => {
  const [imageFile, setImageFile] = useState('');

  useEffect(() => {
    if (value.type === 'image' || value.type === 'wet') {
      const reader = new FileReader();
      reader.readAsDataURL(value.data as Blob);
      reader.onloadend = () => {
        setImageFile(reader.result as string);
      };
    } else if (imageFile !== '') {
      setImageFile('');
    }
  }, [value.type, value.data, imageFile]);

  return (
    <>
      {imageFile !== '' && (
        <Image
          src={imageFile}
          alt="Signature preview"
          width={usage === 'display' ? '150' : '80'}
          height={usage === 'display' ? '100' : '40'}
        />
      )}
      {value.font_type !== '' && (
        <Text css={fontStyles(value.font_type)}>{value.data as String}</Text>
      )}

      {value.data === '' && reviewMode && <Text>No Signatures Added</Text>}
    </>
  );
};
