import { FC } from 'react';
import { useRouter } from 'next/router';

import { useHasScroll } from '@forms-exp/hooks';
import { FooterLinks } from '@forms-exp/components';

import KioskHeader, { KioskHeaderProps } from '../kiosk-header/kiosk-header.component';
import KioskFooter, { KioskFooterProps } from '../kiosk-footer/kiosk-footer.component';

import {
  containerStyle,
  scrollContainerStyle,
  contentContainerStyle,
  footerStyleOverride,
  countrycontainerStyle,
} from './kiosk-layout.styles';
import CountryField from '@/components/form-experience/form-elements/country-field/country-field.component';

interface KioskLayoutProps extends KioskHeaderProps, KioskFooterProps {
  children: React.ReactNode;
  isCountryDropdownEnabled: boolean;
  companyId: string;
}

export const KioskLayout: FC<KioskLayoutProps> = ({
  children,
  title,
  subTitle,
  onContinue,
  buttonType,
  companyId,
  isCountryDropdownEnabled,
}) => {
  const { elRef, hasScroll, ScrollCue, scrollCueProps } = useHasScroll();

  const router = useRouter();
  const { formId, packetId } = router.query;

  return (
    <div css={containerStyle}>
      {/* Only redener for pages which has forms or packet in it */}
      {isCountryDropdownEnabled && (formId || packetId) && (
        <div css={countrycontainerStyle}>
          <CountryField />
        </div>
      )}
      <main ref={elRef} css={scrollContainerStyle}>
        <KioskHeader title={title} subTitle={subTitle} />
        <div css={contentContainerStyle}>{children}</div>
        <FooterLinks companyId={companyId as string} />
        <ScrollCue {...scrollCueProps} />
      </main>

      <KioskFooter
        onContinue={onContinue}
        buttonType={buttonType}
        css={hasScroll && footerStyleOverride}
      />
    </div>
  );
};
