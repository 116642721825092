import { LocaleStrings } from './strings.types';

export const EnglishTranslation: LocaleStrings = {
  FirstName: 'First Name',
  LastName: 'Last Name',
  DoB: 'Date of Birth',
  City: 'City',
  Street1: 'Street Address 1',
  Street2: 'Street Address 2',
  State: 'State',
  ZIP: 'ZIP Code',
  Email: 'Email',
  Mobile: 'Mobile phone',
  PreAdmissionTitle: 'Pre-Admission Registration Form',
  PreAdmissionSubTitle: 'Complete the following fields (required by HIPAA)',
  Begin: 'Begin',
  PhoneHelperText: 'Provide the same number where you receive our reminders',
  VerifyIdentity: 'Verify Identity',
  VerifyIdentityNote:
    'Complete the following fields (required by HIPAA) to securely verify your identity',
  LanguageSelect: 'Select Language',
  NewPatient: 'New Patient',
  NewPatientNote: 'Complete the following fields (required by HIPAA)',
};
