import { useQuery } from 'react-query';
import axios from 'axios';

import { queryKeys } from '@forms-exp/constants';
import { environmentVariables } from '@forms-exp/env';

export const useStatesList = (postalCodeLocale: string) => {
  const { data, isLoading, ...otherUseQueryProps } = useQuery(
    [queryKeys.states, postalCodeLocale],
    () => fetchStatesList(postalCodeLocale),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return {
    states: data?.states || [],
    isLoading,
    ...otherUseQueryProps,
  };
};

interface StateData {
  abbr: string;
  label: string;
  value: string;
}

interface StatesListResponse {
  states: StateData[];
}

async function fetchStatesList(postalCodeLocale: string) {
  const API_BASE = `${environmentVariables.baseApiUrl}/forms-digital/v2/states`;
  const response = await axios.get<StatesListResponse>(API_BASE, {
    params: {
      country_code: postalCodeLocale,
    },
  });
  return response.data;
}
