import { FC, useEffect, useRef, useCallback, useState } from 'react';

import { environmentVariables } from '@forms-exp/env';
import useStore from '@forms-exp/store';
import { PDFFieldState } from '@forms-exp/types';

import { fullScreenContainerStyle } from './pdf-static.styles';

const ADOBE_PDF_FULLSCREEN_CONFIG = {
  defaultViewMode: 'FIT_WIDTH',
  embedMode: 'FULL_WINDOW',
  showAnnotationTools: false,
  showDownloadPDF: true,
  enableAnnotationAPIs: false,
  enableFormFilling: false,
  showBookmarks: false,
};

interface PDFStaticProps {
  fieldId: string;
  pdfUrl: string;
  show: boolean;
}

/**
 * Component to render the PDF for view only mode
 * using the Adobe PDF Embed API.
 */
const PDFStatic: FC<PDFStaticProps> = ({ fieldId, pdfUrl, show }) => {
  const adobeFullscreenPDFViewRef = useRef<Window['AdobeDC']>(null);
  const [isViewReady, setIsViewReady] = useState(false);
  const { fields } = useStore();
  const pdfField = fields[fieldId] as PDFFieldState;

  const fullscreenPDFViewID = `adobe-dc-view-fullscreen-${fieldId}}`;
  const ADOBE_EMBEDED_VIEW_API_KEY = environmentVariables.adobeJSApiKey;

  const initFullscreenPDFView = useCallback(() => {
    adobeFullscreenPDFViewRef.current = new window.AdobeDC.View({
      clientId: ADOBE_EMBEDED_VIEW_API_KEY,
      divId: fullscreenPDFViewID,
    });
  }, [ADOBE_EMBEDED_VIEW_API_KEY, fullscreenPDFViewID]);

  const renderFullscreenPDFViewer = useCallback(
    (pdfURL: string) => {
      adobeFullscreenPDFViewRef.current.previewFile(
        {
          content: { location: { url: pdfURL } },
          metaData: { fileName: pdfField.label },
        },
        ADOBE_PDF_FULLSCREEN_CONFIG
      );
    },
    [pdfField]
  );

  useEffect(() => {
    if (!pdfUrl || !show || !isViewReady) {
      return;
    }

    if (window.AdobeDC) {
      initFullscreenPDFView();
      renderFullscreenPDFViewer(pdfUrl);
      return;
    }
  }, [pdfUrl, show, initFullscreenPDFView, renderFullscreenPDFViewer, isViewReady]);

  useEffect(() => {
    if (!isViewReady) {
      setIsViewReady(true);
    }
  }, [isViewReady]);

  return (
    <div css={fullScreenContainerStyle}>
      <div id={fullscreenPDFViewID}></div>
    </div>
  );
};

export default PDFStatic;
