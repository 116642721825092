import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const fileSelectorContainerStyle = css`
  position: relative;

  button {
    text-transform: none;
  }

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const imagePreviewContainerStyle = css`
  display: flex;
  flex-direction: column;

  img {
    border: 1px dashed ${theme.colors.neutral90} !important;
    padding: ${theme.spacing(0.5)} !important;
    border-radius: ${theme.borderRadius.medium};
  }

  .remove-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacing(1)};
    color: ${theme.colors.status.critical};
    font-size: ${theme.font.size.medium};
  }
`;

export const emptyAttachmentStyle = css`
  border: 1px dashed ${theme.colors.neutral90};
  padding: ${theme.spacing(0, 1)};
  border-radius: ${theme.borderRadius.medium};
`;
