import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const inlineContainerStyle = css`
  height: 40vh;
  overflow: hidden;
  margin-top: ${theme.spacing(2)};
  box-shadow: ${theme.shadows.light};

  position: relative;

  .container-mask {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
`;
