import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { mobileScreenMaxWidth } from '@forms-exp/constants';

export const actionButtonContainerStyle = css`
  @media (max-width: ${mobileScreenMaxWidth}) {
    flex-direction: column;

    button {
      width: 100%;
    }

    button:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: ${theme.spacing(2)};
    }

    button:last-of-type {
      margin-left: 0;
    }
  }
`;
