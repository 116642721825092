import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { mobileScreenMaxWidth, tabletScreenMaxWidth } from '@forms-exp/constants';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 400px;
  }

  .intro-message {
    text-align: center;
    max-width: 600px;
    margin: ${theme.spacing(1)} auto ${theme.spacing(1)};
  }

  .buttons {
    & > * :not(:last-of-type) {
      margin-right: ${theme.spacing(2)};
    }
  }

  @media screen and (max-width: ${mobileScreenMaxWidth}) {
    img {
      max-width: 150px;
    }
  }

  @media screen and (max-width: ${tabletScreenMaxWidth}) {
    img {
      max-width: 250px;
    }
  }
`;
