import { FC, useEffect, useState, useRef, useCallback } from 'react';

import { Text, ModalControlResponse } from '@weave/design-system';

import { environmentVariables } from '@forms-exp/env';
import { CenteredSpinningLoader } from '@forms-exp/components';
import useStore from '@forms-exp/store';
import { PDFFieldState } from '@forms-exp/types';

import PDFFullscreen from '../pdf-fullscreen/pdf-fullscreen.component';

import { inlineContainerStyle } from './pdf-viewer.styles';

interface PDFViewerProps {
  fieldId: string;
  pdfUrl: string;
  modalControl: ModalControlResponse;
  isEditable?: boolean;
}

const ADOBE_PDF_INLINE_CONFIG = {
  embedMode: 'IN_LINE',
  showZoomControl: false,
  showAnnotationTools: false,
  enableFormFilling: false,
  showThumbnails: false,
  showBookmarks: false,
};

/**
 * Component to render the inline PDF preview,
 * and the fullscreen PDF viewer component.
 */
const PDFViewer: FC<PDFViewerProps> = ({
  fieldId,
  pdfUrl,
  modalControl,
  isEditable = false,
}) => {
  const ADOBE_EMBEDED_VIEW_API_KEY = environmentVariables.adobeJSApiKey;
  const [isAdobePDFReady, setIsAdobePDFReady] = useState(false);
  const adobeInlinePDFViewRef = useRef<Window['AdobeDC']>(null);
  const { fields } = useStore();
  const pdfField = fields[fieldId] as PDFFieldState;

  const inlinePDFVIewId = `adobe-dc-view-inline--${fieldId}`;

  const initInlinePDFView = useCallback(() => {
    adobeInlinePDFViewRef.current = new window.AdobeDC.View({
      clientId: ADOBE_EMBEDED_VIEW_API_KEY,
      divId: inlinePDFVIewId,
    });
  }, [ADOBE_EMBEDED_VIEW_API_KEY, inlinePDFVIewId]);

  const renderInlinePDFViewer = useCallback(
    (pdfURL: string) => {
      adobeInlinePDFViewRef.current.previewFile(
        {
          content: { location: { url: pdfURL } },
          metaData: { fileName: pdfField.label },
        },
        ADOBE_PDF_INLINE_CONFIG
      );
    },
    [pdfField]
  );

  useEffect(() => {
    if (!pdfUrl) {
      return;
    }

    if (window.AdobeDC) {
      initInlinePDFView();
      renderInlinePDFViewer(pdfUrl);
      return;
    }
  }, [pdfUrl, initInlinePDFView, renderInlinePDFViewer]);

  useEffect(() => {
    if (window.AdobeDC) {
      setIsAdobePDFReady(true);
      return;
    }

    document.addEventListener('adobe_dc_view_sdk.ready', adobeDCViewReadyHandler);

    return () => {
      document.removeEventListener('adoe_dc_view_sdk.ready', adobeDCViewReadyHandler);
    };
  }, []);

  function adobeDCViewReadyHandler() {
    setIsAdobePDFReady(true);
  }

  function fullscreenHandler() {
    modalControl.openModal();
  }

  return (
    <div>
      <Text>
        {isEditable
          ? 'Please read the attached document carefully and sign it.'
          : 'Please read the attached document carefully.'}
      </Text>
      <Text>(Click on the document to view it in fullscreen mode)</Text>

      {isAdobePDFReady ? (
        <div css={inlineContainerStyle}>
          <div id={inlinePDFVIewId}></div>
          <div className="container-mask" onClick={fullscreenHandler}></div>
        </div>
      ) : (
        <CenteredSpinningLoader />
      )}

      {pdfField.error && (
        <Text color="error">Please sign this document to proceed further.</Text>
      )}

      <PDFFullscreen
        fieldId={fieldId}
        modalControlProps={modalControl}
        pdfUrl={pdfUrl}
        isEditable={isEditable}
      />
    </div>
  );
};

export default PDFViewer;
