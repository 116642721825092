import { FC, useRef, useEffect, useCallback } from 'react';

import {
  XIconSmall,
  IconButton,
  PlusIconSmall,
  MinusIconSmall,
  Text,
} from '@weave/design-system';

import { redraw } from '@forms-exp/utils';

import { usePDFEditorContext } from '../context';
import { Signature } from '../pdf-editor.types';

import {
  getCanvasContainerStyle,
  helperTextStyle,
  buttonsContainerStyle,
} from './pdf-signature.styles';

interface PDFSignatureProps {
  signature: Signature;
}

const PDFSignature: FC<PDFSignatureProps> = ({ signature }) => {
  const mouseDownRef = useRef<boolean>(false);
  const containerRef = useRef<HTMLSpanElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const {
    updateSignaturePosition,
    startSignaturePlacement,
    removeSignature,
    increaseSignatureSize,
    decreaseSignatureSize,
    scrollPosition,
    setIsTouchActive,
    offsetMargin,
    pdfDimensions,
  } = usePDFEditorContext();

  const updatePositionHandler = useCallback(() => {
    const containerEl = containerRef.current;

    if (!containerEl) {
      return;
    }

    const top = parseInt(containerEl.style.top.split('px')[0]);
    const left = parseInt(containerEl.style.left.split('px')[0]);
    const { x, y } = signature.position;

    if ((top === y && left === x) || isNaN(top) || isNaN(left)) {
      return;
    }

    updateSignaturePosition({
      id: signature.id,
      position: { x: left, y: top },
    });
  }, [updateSignaturePosition, signature.id, signature.position]);

  const mouseDownEventHandler = useCallback(() => {
    mouseDownRef.current = true;
  }, []);

  const mouseUpOrLeaveEventHandler = useCallback(() => {
    if (mouseDownRef.current === false) {
      return;
    }

    mouseDownRef.current = false;
    updatePositionHandler();
  }, [updatePositionHandler]);

  const updateMovePosition = useCallback(
    (pageX: number, pageY: number) => {
      const containerEl = containerRef.current;
      const canvas = canvasRef.current;

      if (!containerEl || !canvas) {
        return;
      }

      const left = pageX + scrollPosition.x - canvas.width / 2 - offsetMargin;
      const top = pageY + scrollPosition.y - canvas.height;

      const leftMin = 0;
      const leftMax = pdfDimensions.width - canvas.width;

      const topMin = 0;
      const topMax = pdfDimensions.height - canvas.height;

      if (left >= leftMin && left <= leftMax) {
        containerEl.style.left = `${left}px`;
      }

      if (top >= topMin && top <= topMax) {
        containerEl.style.top = `${top}px`;
      }
    },
    [scrollPosition.x, scrollPosition.y, offsetMargin, pdfDimensions]
  );

  const mouseMoveEventHandler = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!signature.isDraggable) {
        return;
      }

      if (mouseDownRef.current === false) {
        return;
      }

      updateMovePosition(e.pageX, e.pageY);
    },
    [signature.isDraggable, updateMovePosition]
  );

  const touchStartEventHandler = useCallback(() => {
    setIsTouchActive(true);
  }, [setIsTouchActive]);

  const touchMoveEventHandler = useCallback(
    (e: TouchEvent) => {
      if (!signature.isDraggable) {
        return;
      }

      const touchLocation = e.targetTouches[0];
      updateMovePosition(touchLocation.pageX, touchLocation.pageY);
    },
    [signature.isDraggable, updateMovePosition]
  );

  const touchEndEventHandler = useCallback(() => {
    setIsTouchActive(false);
    updatePositionHandler();
  }, [updatePositionHandler, setIsTouchActive]);

  useEffect(() => {
    const conatinerEl = containerRef.current;
    const canvas = canvasRef.current;

    if (!canvas || !conatinerEl) {
      return;
    }

    conatinerEl.style.top = `${signature.position.y}px`;
    conatinerEl.style.left = `${signature.position.x}px`;

    const { width, height } = signature.scaledDimensions;
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    redraw(ctx, canvas, signature.scaledPaths);
    conatinerEl.style.display = 'block';
  }, [
    signature.scaledDimensions,
    signature.scaledPaths,
    signature.position.x,
    signature.position.y,
  ]);

  useEffect(() => {
    const canvasEl = canvasRef.current;

    if (!canvasEl) {
      return;
    }

    canvasEl.addEventListener('mousedown', mouseDownEventHandler);
    canvasEl.addEventListener('mouseup', mouseUpOrLeaveEventHandler);
    canvasEl.addEventListener('mouseleave', mouseUpOrLeaveEventHandler);
    canvasEl.addEventListener('mousemove', mouseMoveEventHandler);
    canvasEl.addEventListener('touchstart', touchStartEventHandler);
    canvasEl.addEventListener('touchmove', touchMoveEventHandler);
    canvasEl.addEventListener('touchend', touchEndEventHandler);

    return () => {
      canvasEl.removeEventListener('mousedown', mouseDownEventHandler);
      canvasEl.removeEventListener('mouseup', mouseUpOrLeaveEventHandler);
      canvasEl.removeEventListener('mouseleave', mouseUpOrLeaveEventHandler);
      canvasEl.removeEventListener('mousemove', mouseMoveEventHandler);
      canvasEl.removeEventListener('touchstart', touchStartEventHandler);
      canvasEl.removeEventListener('touchmove', touchMoveEventHandler);
      canvasEl.removeEventListener('touchend', touchEndEventHandler);
    };
  }, [
    mouseDownEventHandler,
    mouseMoveEventHandler,
    mouseUpOrLeaveEventHandler,
    touchStartEventHandler,
    touchMoveEventHandler,
    touchEndEventHandler,
  ]);

  function signatureClickHandler() {
    if (signature.isDraggable) {
      return;
    }

    startSignaturePlacement(signature.id);
  }

  function removeSignatureHandler() {
    removeSignature(signature.id);
  }

  function zoomInHandler() {
    increaseSignatureSize(signature.id);
  }

  function zoomOutHandler() {
    decreaseSignatureSize(signature.id);
  }

  return (
    <span
      ref={containerRef}
      css={getCanvasContainerStyle({ isDraggable: signature.isDraggable })}
      onClick={signatureClickHandler}
    >
      {signature.isDraggable && (
        <Text css={helperTextStyle}>Drag it to the signature field</Text>
      )}

      <canvas ref={canvasRef}></canvas>

      {signature.isDraggable && (
        <span css={buttonsContainerStyle}>
          <IconButton
            label=""
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeSignatureHandler();
            }}
          >
            <XIconSmall color="primary" />
          </IconButton>
          <IconButton
            label=""
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              zoomInHandler();
            }}
          >
            <PlusIconSmall color="primary" />
          </IconButton>
          <IconButton
            label=""
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              zoomOutHandler();
            }}
          >
            <MinusIconSmall color="primary" />
          </IconButton>
        </span>
      )}
    </span>
  );
};

export default PDFSignature;
