import { SearchField, Text, useDebouncedValue, useFormField } from '@weave/design-system';
import {
  listSearchStyle,
  orderItemStyle,
  orderListStyle,
} from './kiosk-listing-page-content.styles';
import { Packet } from '@/types';
import { FC } from 'react';

interface KioskPacketsProps {
  packets: Packet[];
  onSelect: (id: string) => void;
}

export const KioskPackets: FC<KioskPacketsProps> = ({ packets, onSelect }) => {
  const searchFieldProps = useFormField({
    type: 'text',
  });

  const searchValue = useDebouncedValue(searchFieldProps.value);

  return (
    <section>
      <SearchField
        {...searchFieldProps}
        name="search"
        css={listSearchStyle}
        autoComplete={'off'}
      />
      <ul css={orderListStyle}>
        {packets.map((item) => {
          if (!item.name.toLowerCase().includes(searchValue.toLowerCase())) return null;

          return (
            <li css={orderItemStyle} key={`${item.id}`} onClick={() => onSelect(item.id)}>
              <Text as="span" weight="bold">
                {item.name}
              </Text>
              <Text color="primary" as="span" className="forms-count">
                {item.formCount} Form{item.formCount > 1 && 's'}
              </Text>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
