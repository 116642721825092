import { FC } from 'react';

import { css } from '@emotion/core';

import { Text } from '@weave/design-system';
import { theme } from '@weave/theme';

import useStore from '@forms-exp/store';
import { ElectronicSignatureValue } from '@forms-exp/types';

import { DisplaySignature } from './display-signature.component';

const carouselCss = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;

  .carousel-item {
    padding: ${theme.spacing(1)};
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      background-color: ${theme.colors.neutral5};
    }
  }
`;

export const SignatureHistoryCarouselComponent: FC<{
  id: string;
  onDone: () => void;
}> = ({ id, onDone }) => {
  const { eSignHistory, updateEsignFieldValue } = useStore();

  const onReuseSign = (sign: ElectronicSignatureValue) => {
    updateEsignFieldValue({
      name: id,
      value: sign,
    });
    onDone();
  };

  if (eSignHistory.length === 0) return null;

  return (
    <div>
      <div css={carouselCss}>
        {eSignHistory.map((sign) => {
          return (
            <div
              className="carousel-item"
              key={sign.timestamp}
              onClick={() => onReuseSign(sign)}
            >
              <DisplaySignature usage="reuse" value={sign} />
            </div>
          );
        })}
      </div>
      <Text size="small" color="light">
        Select a previous e-signature to auto-fill.
      </Text>
    </div>
  );
};
