import { FC, useEffect, useCallback, useState, useRef } from 'react';
import * as pdfJS from 'pdfjs-dist';

import { ContentLoader } from '@weave/design-system';

import { CenteredSpinningLoader } from '@forms-exp/components';

import { usePDFEditorContext } from './context';
import { SignaturePadProvider } from './signature-pad/context';
import PDFCanvas from './pdf-canvas/pdf-canvas.component';
import PDFHeader from './pdf-header/pdf-header.component';
import SignaturePad from './signature-pad/signature-pad.component';
import PDFSignature from './pdf-signature/pdf-signature.component';

import {
  containerStyle,
  getCanvasContainerStyle,
  canvasInnerContainerStyle,
} from './pdf-editor.styles';

interface PDFEditorProps {
  pdfUrl: string;
}

/**
 * Component to render the PDF editor.
 */
const PDFEditor: FC<PDFEditorProps> = ({ pdfUrl }) => {
  const [pdfPageNumbers, setPageNumbers] = useState<number[]>([]);
  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasInnerContainerRef = useRef<HTMLDivElement>(null);

  // This is used to load the PDF document for saving.
  const [pdfDocProxyForSave, setPDFDocProxyForSave] = useState<
    pdfJS.PDFDocumentProxy | undefined
  >();

  const containerDivRef = useRef<HTMLDivElement>(null);
  const resizeTimeoutRef = useRef<number>();
  const scrollTimeoutRef = useRef<number>();

  // This is used to load the PDF document for editing.
  const {
    updateTotalPages,
    updateCurrentPage,
    initialisePDFProxy,
    pdfDocProxy,
    isReadyForPDF,
    updateScrollPosition,
    signatures,
    isTouchActive,
    setOffsetMargin,
  } = usePDFEditorContext();

  /**
   * Function used to load the PDF document on the canvas for editing.
   */
  const loadPdf = useCallback(() => {
    pdfJS.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';

    pdfJS.getDocument(pdfUrl).promise.then((pdf) => {
      if (!pdf) {
        return;
      }

      initialisePDFProxy(pdf);
      updateTotalPages(pdf.numPages);
      updateCurrentPage(1);

      const pageNumbers = [];
      for (let i = 1; i <= pdf.numPages; i++) {
        pageNumbers.push(i);
      }
      setPageNumbers(pageNumbers);
    });
  }, [pdfUrl, updateTotalPages, updateCurrentPage, initialisePDFProxy]);

  /**
   * Function used to load the PDF document on the canvas for saving.
   */
  const loadPDFProxyForSave = useCallback(() => {
    if (pdfDocProxyForSave) {
      return;
    }

    pdfJS.getDocument(pdfUrl).promise.then((pdf) => {
      if (!pdf) {
        return;
      }

      setPDFDocProxyForSave(pdf);
    });
  }, [pdfUrl, pdfDocProxyForSave]);

  const calculateOffsetMargin = useCallback(() => {
    const canvasInnerContainerEl = canvasInnerContainerRef.current;

    if (!canvasInnerContainerEl) {
      return;
    }

    const marginLeft = canvasInnerContainerEl.offsetLeft;
    setOffsetMargin(marginLeft);
  }, [setOffsetMargin]);

  const resizeEventHandler = useCallback(
    (e: UIEvent) => {
      if (!e.target) {
        return;
      }

      if (resizeTimeoutRef) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = window.setTimeout(() => {
        const target = e.target as Window;
        setCanvasWidth(target.innerWidth);
        calculateOffsetMargin();
      }, 100);
    },
    [setCanvasWidth, calculateOffsetMargin]
  );

  const scrollEventHandler = useCallback(
    (e: Event) => {
      const target = e.target as HTMLDivElement;

      if (!target) {
        return;
      }

      if (scrollTimeoutRef) {
        clearTimeout(scrollTimeoutRef.current);
      }

      scrollTimeoutRef.current = window.setTimeout(() => {
        updateScrollPosition({ x: target.scrollLeft, y: target.scrollTop });
      }, 100);
    },
    [updateScrollPosition]
  );

  useEffect(() => {
    const containerEl = canvasContainerRef.current;

    if (!containerEl) {
      return;
    }

    containerEl.addEventListener('scroll', scrollEventHandler);

    return () => {
      containerEl.removeEventListener('scroll', scrollEventHandler);
    };
  }, [scrollEventHandler]);

  useEffect(() => {
    window.addEventListener('resize', resizeEventHandler);

    return () => {
      window.removeEventListener('resize', resizeEventHandler);
    };
  }, [resizeEventHandler]);

  useEffect(() => {
    loadPdf();
  }, [loadPdf]);

  // This hook helps create the canvas for saving the PDF.
  useEffect(() => {
    if (!isReadyForPDF) {
      return;
    }

    loadPDFProxyForSave();
  }, [isReadyForPDF, loadPDFProxyForSave]);

  useEffect(() => {
    if (!pdfDocProxy) {
      return;
    }

    setTimeout(() => {
      calculateOffsetMargin();
    }, 500);
  }, [calculateOffsetMargin, pdfDocProxy]);

  /**
   * Function to show the PDF pages for editing.
   */
  function showPDFPages() {
    if (!pdfDocProxy) {
      return <CenteredSpinningLoader />;
    }

    return pdfPageNumbers.map((pageNumber) => (
      <PDFCanvas
        key={`pdf-page-${pageNumber}`}
        pdf={pdfDocProxy}
        pageNumber={pageNumber}
        desiredWidth={canvasWidth}
      />
    ));
  }

  /**
   * Function to show the PDF pages for saving.
   */
  function showPDFPagesForSave() {
    if (!pdfDocProxyForSave) {
      return;
    }

    return pdfPageNumbers.map((pageNumber) => (
      <PDFCanvas
        key={`save-pdf-page-${pageNumber}`}
        pdf={pdfDocProxyForSave}
        pageNumber={pageNumber}
        desiredWidth={canvasWidth}
        saveMode={true}
      />
    ));
  }

  function showSignatures() {
    return signatures.map((signature) => {
      return <PDFSignature key={signature.id} signature={signature} />;
    });
  }

  return (
    <div css={containerStyle} ref={containerDivRef}>
      <PDFHeader />

      <div css={getCanvasContainerStyle({ isTouchActive })} ref={canvasContainerRef}>
        <div css={canvasInnerContainerStyle} ref={canvasInnerContainerRef}>
          {showPDFPages()}
          {showSignatures()}
        </div>
      </div>

      {pdfDocProxyForSave && <div>{showPDFPagesForSave()}</div>}

      <SignaturePadProvider>
        <SignaturePad />
      </SignaturePadProvider>

      <ContentLoader show={isReadyForPDF} message="Saving the document..." />
    </div>
  );
};

export default PDFEditor;
