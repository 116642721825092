import { useEffect, useRef, useState } from 'react';

import { millisToMinutesAndSeconds } from '@forms-exp/helpers';

import CountdownTimer from './countdown-timer.component';

interface UseCountdownTimerProps {
  timeToElapse: number; // Time in seconds
  initiate?: boolean; // Whether to start the countdown immediately
}

interface TimeDetails {
  minutesCountdown: string;
  secondsCountdown: string;
}

interface UseCountdownTimerResults extends TimeDetails {
  CountdownTimer: typeof CountdownTimer;
  isCountdownComplete: boolean;
  restartCountdown: () => void;
}

const getExpectedTimeToElapse = (timeToElapse: number) =>
  Date.now() + timeToElapse * 1000;

export const useCountdownTimer = ({
  timeToElapse,
  initiate = true,
}: UseCountdownTimerProps): UseCountdownTimerResults => {
  const countdownIntervalRef = useRef<NodeJS.Timeout>();
  const [countdown, setCountdown] = useState(timeToElapse); // time in seconds
  const [timeDetails, setTimeDetails] = useState<TimeDetails>({
    minutesCountdown: '00',
    secondsCountdown: '00',
  });
  const expectedTimeToElapse = useRef(0);

  const startCountdown = () => {
    expectedTimeToElapse.current = getExpectedTimeToElapse(timeToElapse);

    countdownIntervalRef.current = setInterval(() => {
      const timeDiff = Date.now() - expectedTimeToElapse.current;

      const isTimeExceeded = timeDiff >= 0;

      if (isTimeExceeded && countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);

        setCountdown(0);
        setTimeDetails({
          minutesCountdown: '00',
          secondsCountdown: '00',
        });
        return;
      }

      const { minutes, seconds } = millisToMinutesAndSeconds(Math.abs(timeDiff));

      setTimeDetails({
        minutesCountdown: minutes,
        secondsCountdown: seconds,
      });

      if (minutes === '00' && seconds === '00' && countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
        setCountdown(0);
      }
    }, 1000);
  };

  useEffect(() => {
    if (initiate) {
      startCountdown();
    }
  }, [initiate]);

  const restartCountdown = () => {
    if (countdownIntervalRef.current) clearInterval(countdownIntervalRef.current);

    setCountdown(timeToElapse);
    startCountdown();
  };

  return {
    CountdownTimer,
    isCountdownComplete: countdown === 0,
    ...timeDetails,
    restartCountdown,
  };
};
