import { FC, MutableRefObject, useRef } from 'react';
import { format } from 'date-fns';
import SignatureCanvas from 'react-signature-canvas';

import { PrimaryButton, TextButton } from '@weave/design-system';

import { useMobile } from '@forms-exp/hooks';
import useStore from '@forms-exp/store';

import {
  clearSignaturesButtonWrapper,
  footerButtonContainerStyle,
  wetSignaturesButtonsStyle,
} from './e-signature-field.style';
import { SignatureHistoryCarouselComponent } from './signature-history-carousel.component';

interface WetSignatureProps {
  id: string;
  onDone: () => void;
}

const WetSignature: FC<WetSignatureProps> = ({ id, onDone }) => {
  const { isMobileScreen } = useMobile();
  const signatureCanvasRef = useRef() as MutableRefObject<SignatureCanvas>;
  const { updateEsignFieldValue } = useStore();

  const onClear = () => {
    signatureCanvasRef.current.clear();
  };

  const onWetSignatureDone = () => {
    const dataPoints = signatureCanvasRef.current.toData();
    const isEmpty = dataPoints.length === 0;

    if (!isEmpty) {
      // source: https://stackoverflow.com/a/7261048/4339943
      const dataURL = signatureCanvasRef.current.toDataURL();
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      const byteString = window.atob(dataURL.split(',')[1]);

      // separate out the mime component
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const file = new Blob([ab], { type: mimeString });
      updateEsignFieldValue({
        name: id,
        value: {
          type: 'wet',
          data: file,
          font_type: '',
          timestamp: format(new Date(), 'MMMM d, yyyy hh:mm:ss'),
        },
        isNewSignature: true,
      });
    }

    onDone();
  };

  return (
    <>
      <SignatureCanvas
        ref={signatureCanvasRef}
        penColor={'black'}
        canvasProps={{
          width: isMobileScreen ? '250px' : '550px',
          height: isMobileScreen ? '150px' : '200px',
          className: 'form-signature-canvas',
        }}
      />

      <SignatureHistoryCarouselComponent id={id} onDone={onDone} />

      <div css={footerButtonContainerStyle}>
        <div css={clearSignaturesButtonWrapper}>
          <TextButton className="clear-wet-sign" onClick={onClear}>
            Clear Signature
          </TextButton>
        </div>

        <div css={wetSignaturesButtonsStyle}>
          <PrimaryButton onClick={onWetSignatureDone} size="tiny">
            eSign
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default WetSignature;
